import React, { useState } from 'react';
import DateTimePickerComponent from '../../../presentational/DateTimePicker';
import { useStoreActions, useStoreState } from 'easy-peasy';

export default function ReservationDatePicker(visible) {
  const { reservationCalendarVisible } = useStoreState((state) => state.tools);

  const { setReservationCalendarVisible, setReservationInputAction } =
    useStoreActions((actions) => actions);

  const minDate = new Date(); // Today
  const maxDate = new Date(2017, 6, 3);

  return (
    <DateTimePickerComponent
      startFromMonday={true}
      minDate={minDate}
      maxDate={maxDate}
      todayBackgroundColor={'#0088CE'}
      selectedDayColor="#0088CE"
      selectedDayTextColor="#0088CE"
      selectedRangeStartStyle={{ backgroundColor: '#0088CE' }}
      selectedRangeEndStyle={{ backgroundColor: '#0088CE' }}
      allowRangeSelection={true}
      onChange={(date, type) => {
        if (type === 'END_DATE') {
          setReservationInputAction({
            reservationendDate: date,
          });
        } else {
          setReservationInputAction({
            reservationstartDate: date,
          });
        }
      }}
      visible={reservationCalendarVisible}
      onBackdropPress={() => setReservationCalendarVisible(false)}
    />
  );
}
