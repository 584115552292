import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import Card from '../../presentational/Card';
import { remToDp } from '../../../utilities/general';
import { Platform } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import Icon from '../../presentational/Icon';

const ColSwitch = styled(Col)`
  padding-left: ${remToDp(1.5)}px;
`;

export default function StorageLocationForm({ buttons, navigation, ...props }) {
  const { input, isEditable } = useStoreState((state) => state.storageLocation);
  const {
    location: { searchResults, locationMap },
  } = useStoreState((state) => state);

  const { setStorageLocationInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} lg={12}>
          <Col>
            <Input
              placeholder='Type in Location name'
              label='Location Name'
              value={input.name}
              onChangeText={(name) => setStorageLocationInputAction({ name })}
              required
              editable={isEditable}
            />
          </Col>

          <Col>
            <Input
              placeholder='Type in Location Description'
              label='Location Description'
              value={input.description}
              onChangeText={(description) =>
                setStorageLocationInputAction({ description })
              }
              editable={isEditable}
            />
          </Col>
          <Col>
            <Input
              required
              placeholder='Facility Location'
              label='Facility Location'
              value={locationMap[input.tenantlocationid]?.name || ''}
              onSelect={(location) => {
                setStorageLocationInputAction({
                  tenantlocationid: location.id,
                });
              }}
              editable={isEditable}
              selectedItem={(item) => item.name}
              rowTextForSelection={(item) => item.name}
              dropdown
              dropdownChoices={searchResults
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter((item) => item.active)}
              search={true}
              searchInputStyle={{
                width: '100%',
                alignSelf: 'center',
                backgroundColor: '#F7F7F7',
                border: 0,

                boxShadow: '0px 0px 10px grey',
                borderColor: '#F7F7F7',
              }}
              searchInputTxtStyle={{
                fontFamily: 'Barlow_400Regular',
                fontSize: 16,
              }}
              renderSearchInputRightIcon={() => (
                <Icon Component={Entypo} name='magnifying-glass' />
              )}
              searchInputPlaceholder='Search for Location'
            />
          </Col>
        </Col>
      </Row>
      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
