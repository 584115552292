const defaultInputState = {
  id: null,
  componentId: null,
  controlId: null,
  value: '',
  sortOrder: '',
};

const state = {
  dropdown: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
  },
};

export { state as default, defaultInputState };
