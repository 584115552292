import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

export default function InspectionItemsSearchBar() {
  const { searchInput } = useStoreState((state) => state.inspectionItems);
  const { setInspectionItemsSearchInputAction } = useStoreActions(
    (actions) => actions
  );

  function clearSearchInput() {
    return () => setInspectionItemsSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setInspectionItemsSearchInputAction(newVal)}
      placeholder="Search inspection items by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}
