import { thunk } from 'easy-peasy';
import {
  getEngineFamilyAPICall,
  removeEngineVariantAPICall,
  upsertEngineFamilyAPICall,
} from '../../api/engine';

const thunks = {
  getEnginesThunk: thunk(async (actions, item, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getEngineFamilyAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setEngineListAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  upsertEnginesThunk: thunk(async (actions, item, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await upsertEngineFamilyAPICall({
        ...helpers.getState().engines.selectedEngine,
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setAlertThunk({
        type: 'success',
        message: 'Engine Family Saved!',
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  removeVariantThunk: thunk(async (actions, item, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await removeEngineVariantAPICall(item);
      actions.setAlertThunk({
        type: 'success',
        message: 'Engine Variant Removed!',
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
};

export default thunks;
