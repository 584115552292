import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

function useOnPress(navigation, getIndex, setIndex) {
  const { documentsToAdd, tenant, tools } = useStoreState((state) => state);
  const {
    setAreYouSureDeleteDocumentModalVisibleAction,
    setRemoveDocumentInputAction,
    resetDocumentAction,
    setDocumentToAddListAction,
  } = useStoreActions((actions) => actions);

  return () => {
    if (documentsToAdd.length > 0) {
      const index = getIndex();
      const document = documentsToAdd[index || 0];
      if (document?.id) {
        setRemoveDocumentInputAction({
          id: document?.id,
          tenantId: tenant.input.id,
          toolid: document?.toolid ?? tools.input.id,
        });
        setAreYouSureDeleteDocumentModalVisibleAction(true);
        resetDocumentAction();
        setIndex({ index: 0 });
      } else {
        documentsToAdd?.splice(index, 1);
        setIndex({ count: -1 });
        setDocumentToAddListAction([...documentsToAdd]);
      }
    }
  };
}

export default function RemoveToolImageButton({
  navigation,
  getIndex,
  setIndex,
  isEdit,
  children = 'Remove Image',
  ...props
}) {
  const onPress = useOnPress(navigation, getIndex, setIndex);
  return (
    <Button
      onPress={onPress}
      disabled={isEdit}
      fullWidth
      {...props}
      color={'#ab2328'}
    >
      {children}
    </Button>
  );
}
