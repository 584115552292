import { action } from "easy-peasy";
import { defaultInputState } from "./state";

const actions = {
  setDropdownSearchResultsAction: action((state, searchResults) => {
    state.dropdown.searchResults = searchResults;
  }),
  setDropdownSearchInputAction: action((state, searchInput) => {
    state.dropdown.searchInput = searchInput;
  }),
  setIsEditableDropdownAction: action((state, isEditable) => {
    state.dropdown.isEditable = isEditable;
  }),
  setDropdownInputAction: action((state, input) => {
    state.dropdown.input = {
      ...state.dropdown.input,
      ...input,
    };
  }),
  resetDropdownInputAction: action((state, input) => {
    state.dropdown.input = defaultInputState;
  }),
};

export default actions;
