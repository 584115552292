const defaultInputState = {
  workpackageId: null,
  workpackageName: '',
  toolpartNumber: '',
};

const state = {
  workPackageTools: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
    wpToolAreYouSureModalVisible: false,
    workPackageToolsLocationReport: [],
  },
};

export { state as default, defaultInputState };
