import { useStoreActions, useStoreState } from 'easy-peasy';
import Image from '../../presentational/Image';
import Button from '../Button';
import Input from '../../presentational/Input';
import Switch from '../../presentational/Switch';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import Card from '../../presentational/Card';
import { pxToDp, remToDp, vhToDp } from '../../../utilities/general';

const ColSwitch = styled(Col)`
  padding-left: ${remToDp(1.5)}px;
`;

export default function UserForm({ buttons, ...props }) {
  const {
    user: { input, isEditable, confirmPassword },
    location: { searchResults },
    role,
    document,
    status: { placeholder },
  } = useStoreState((state) => state);

  const { setUserInputAction, setUserConfirmPasswordAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} lg={5}>
          <Col>
            <Input
              placeholder='Type in username'
              label='Username'
              value={input.userName}
              onChangeText={(userName) => setUserInputAction({ userName })}
              required
              editable={isEditable}
            />
          </Col>
          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in First Name'
                label='First Name'
                value={input.firstName}
                onChangeText={(firstName) => setUserInputAction({ firstName })}
                editable={isEditable}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in Last Name'
                label='Last Name'
                value={input.lastName}
                onChangeText={(lastName) => setUserInputAction({ lastName })}
                editable={isEditable}
              />
            </Col>
          </Row>
          <Col>
            <Input
              placeholder='Type in email'
              label='Email'
              value={input.email}
              onChangeText={(email) => setUserInputAction({ email })}
              required
              editable={isEditable}
            />
          </Col>
        </Col>
        <Col xs={12} lg={5}>
          <Col>
            <Input
              selectedItem={(item) => item.name}
              rowTextForSelection={(item) => item.name}
              dropdown
              dropdownChoices={role && role.searchResults}
              placeholder='Type in role'
              label='Role'
              editable={isEditable}
              value={input.role}
              onSelect={(role) => setUserInputAction({ role: role.name })}
            />
          </Col>

          <Col>
            <Input
              selectedItem={(item) => item.name}
              rowTextForSelection={(item) => item.name}
              dropdown
              dropdownChoices={searchResults?.filter((item) => {
                return item.active !== false;
              })}
              placeholder='Type in location'
              label='Location'
              value={
                searchResults.find(
                  (location) => location.id === input.locationId
                )?.name || ''
              }
              onSelect={(item) => setUserInputAction({ locationId: item.id })}
              editable={isEditable}
            />
          </Col>
          <Row>
            <Col xs={12} lg={6}>
              <Input
                placeholder='Type in password'
                label='Password'
                value={confirmPassword}
                onChangeText={(confirmPassword) =>
                  setUserConfirmPasswordAction(confirmPassword)
                }
                secureTextEntry={true}
                editable={isEditable}
              />
            </Col>

            <Col xs={12} lg={6}>
              <Input
                placeholder='Type in confirm password'
                label='Confirm Password'
                value={input.password}
                onChangeText={(password) => setUserInputAction({ password })}
                secureTextEntry={true}
                editable={isEditable}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={2}>
          <Image
            style={{
              marginBottom: pxToDp(15),

              border: '1px solid black',
            }}
            width='100%'
            height={vhToDp(25)}
            uri={document.body !== '' ? document.body : placeholder.body}
          />
          <Button.UploadUserPhoto />
        </Col>

        <ColSwitch>
          <Switch
            disabled={!isEditable}
            label='Active'
            value={input.active}
            onValueChange={(active) => setUserInputAction({ active })}
          />
        </ColSwitch>
      </Row>

      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
