import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function ManageConfigPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const { name } = useStoreState((state) => state.user.input);
  const { resetUserInputAction } = useStoreActions((actions) => actions);

  useEffect(() => {
    isFocused && resetUserInputAction();
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.ManageConfig
          buttons={[
            <Button.CancelEditTenant navigation={navigation} />,
            <Button.SaveTenant
              navigation={navigation}
              message="Added tenant"
              disabled={!name}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
