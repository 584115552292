import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Entypo } from '@expo/vector-icons';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import Icon from '../../presentational/Icon';
import { Platform } from 'react-native';

export default function CopyWorkPackageToTenantModal({ ...props }) {
  const {
    tenant: { searchResults },
    workPackage: { copyWPToTenantInput, copyWorkPackageToTenantModalVisible },
  } = useStoreState((state) => state);

  const {
    setCopyWorkPackageToTenantModalVisibleAction,
    setCopyWorkPackageInputAction,
    precheckCopyWorkPackageThunk,
  } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      onClose={() => {
        setCopyWorkPackageToTenantModalVisibleAction(false);
      }}
      isVisible={copyWorkPackageToTenantModalVisible}
      title="Copy Work Package"
      style={{ width: 500 }}
      width="50%"
      height="50%"
    >
      <Col>
        <Input
          placeholder="Type in Tenant"
          label="Tenant"
          value={
            searchResults.find(
              (tenant) => tenant.id == copyWPToTenantInput.totenantId
            )?.name
          }
          onSelect={(name) =>
            setCopyWorkPackageInputAction({
              totenantId: name.id,
            })
          }
          editable={true}
          selectedItem={(item) => item.name}
          rowTextForSelection={(item) => item.name}
          dropdown
          dropdownChoices={searchResults}
          width={Platform.OS == 'web' ? '95%' : '98%'}
          search={true}
          searchInputStyle={{
            width: '100%',
            alignSelf: 'center',
            backgroundColor: '#F7F7F7',
            border: 0,

            boxShadow: '0px 0px 10px grey',
            borderColor: '#F7F7F7',
          }}
          searchInputTxtStyle={{
            fontFamily: 'Barlow_400Regular',
            fontSize: 16,
          }}
          renderSearchInputRightIcon={() => (
            <Icon Component={Entypo} name="magnifying-glass" />
          )}
          searchInputPlaceholder="Search for Tool Part Number"
        />
      </Col>
      <Col>
        <Input
          placeholder="Type in Work Package Name"
          label="Work Package Name"
          value={copyWPToTenantInput.name}
          onChangeText={(name) => setCopyWorkPackageInputAction({ name })}
        />
      </Col>

      <Col>
        <Input
          placeholder="Type in Work Package Description"
          label="Work Package Description"
          value={copyWPToTenantInput.description}
          onChangeText={(description) =>
            setCopyWorkPackageInputAction({ description })
          }
        />
      </Col>
      <Button
        fullWidth
        onPress={() => {
          precheckCopyWorkPackageThunk();
        }}
      >
        {'Copy Work Package'}
      </Button>
    </CustomModal>
  );
}
