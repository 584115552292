import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function LocationsList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.location,
    searchResults: state.location.searchResults.filter((user) =>
      formatStringForSearch(user.name).includes(
        formatStringForSearch(state.location.searchInput)
      )
    ),
  }));

  const { setLocationInputAction } = useStoreActions((actions) => actions);

  return (
    <List
      tableHead={['Name', 'Address 1', 'State', 'Active', 'Edit']}
      tableRows={searchResults.map((user) => [
        user.name,
        user.address1,
        user.stateProvince,
        user.active ? 'Active' : 'Inactive',
        <TouchableIcon
          Component={FontAwesome5}
          name="edit"
          size={20}
          onPress={() => {
            setLocationInputAction(user);
            navigation.navigate('Edit Location', { id: user.id });
          }}
        />,
      ])}
    />
  );
}
