import { useStoreActions, useStoreState } from "easy-peasy";
import Page from "../containers/Page";
import { useEffect } from "react";
import SearchBar from "../containers/SearchBar";
import List from "../containers/List";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import Card from "../presentational/Card";
import Gap from "../presentational/Gap";
import Row from "../presentational/Row";
import Button from "../presentational/Button";
import Col from "../presentational/Col";
import Switch from "../presentational/Switch";
import { vhToDp, vwToDp } from "../../utilities/general";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
	align-items: center;
`;

export default function WorkPackagesPage({ navigation, ...props }) {
	const isFocused = useIsFocused();
	const {
		tenant: { input },
		workPackage: { searchResults, filter },
	} = useStoreState((state) => state);
	const { getWorkPackageThunk, setFilterPackagesAction } = useStoreActions(
		(actions) => actions
	);

	useEffect(() => {
		if (isFocused) {
			getWorkPackageThunk({ id: null, tenantId: input.id });
		}
	}, [isFocused]);

	return (
		<Page.Protected {...props}>
			<Container>
				<SRow>
					<Col xs={12} sm={2}>
						<Button
							fullWidth
							onPress={() => navigation.navigate("Add Work Package")}
						>
							Add Work Package
						</Button>
					</Col>
					<Col xs={12} sm={8.7}>
						<SearchBar.WorkPackage />
					</Col>
					<Col xs={12} sm={1.3}>
						<Switch
							label="Show Inactive"
							value={filter}
							onValueChange={(active) => {
								setFilterPackagesAction(active);
							}}
						/>
					</Col>
				</SRow>

				<Gap />
				<Card
					toolTipText={"How to use the Work Package Screen"}
					toolUrl={"https://www.screencast.com/t/xji37b3YV"}
				>
					<List.WorkPackage navigation={navigation} />
				</Card>
			</Container>
		</Page.Protected>
	);
}
