import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setLabelSearchResultsAction: action((state, searchResults) => {
    state.labels.searchResults = searchResults;
  }),
  setLabelSearchInputAction: action((state, searchInput) => {
    state.labels.searchInput = searchInput;
  }),
  setIsEditableLabelAction: action((state, isEditable) => {
    state.labels.isEditable = isEditable;
  }),
  setLabelInputAction: action((state, input) => {
    state.labels.input = {
      ...state.labels.input,
      ...input,
    };
  }),
  resetLabelInputAction: action((state, input) => {
    state.labels.input = defaultInputState;
  }),

};

export default actions;
