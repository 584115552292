import { axios } from '.';

const engineAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
});

function getEngineFamilyAPICall({ tenantId }) {
  return engineAxios({
    method: 'get',
    url: `/engineFamily?tenantId=${tenantId}`,
  });
}

function upsertEngineFamilyAPICall(data) {
  return engineAxios({
    method: 'put',
    url: `/engineFamily`,
    data,
  });
}

function removeEngineVariantAPICall(data) {
  return engineAxios({
    method: 'delete',
    url: `/engineFamily`,
    data,
  });
}

export {
  engineAxios,
  getEngineFamilyAPICall,
  upsertEngineFamilyAPICall,
  removeEngineVariantAPICall,
};
