import List from '../../presentational/List';
import { useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function WorkPackageToolList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.workPackageTools,
    searchResults: state.workPackageTools.searchResults.filter((user) =>
      formatStringForSearch(user.workpackageName).includes(
        formatStringForSearch(state.workPackageTools.searchInput)
      )
    ),
  }));

  return (
    <List
      tableHead={['Work Package Name', 'Tool Part Number', 'Edit']}
      tableRows={searchResults.map((user) => [
        user.workpackageName,
        user.toolpartNumber,
        <TouchableIcon
          Component={FontAwesome5}
          name="edit"
          size={20}
          onPress={() => {
            navigation.navigate('Edit Work Package Tool', { id: user.id });
          }}
        />,
      ])}
    />
  );
}
