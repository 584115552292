import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

function WorkPackageList({ navigation }) {
  const { searchResults, filter } = useStoreState((state) => ({
    ...state.workPackage,
    searchResults: state.workPackage.searchResults.filter((user) =>
      formatStringForSearch(user.name || user.description).includes(
        formatStringForSearch(state.workPackage.searchInput)
      )
    ),
    filter: state.workPackage.filter,
  }));

  const { setWorkPackageInputAction } = useStoreActions((actions) => actions);

  return (
    <List
      tableHead={['Name', 'Description', 'Engine', 'Status', 'Edit']}
      tableRows={
        !filter
          ? searchResults
              .filter((item) => item.activeFlag == 'Y')
              .map((user) => [
                user.name,
                user.description,
                user.engine,
                user.statusDescription,
                <TouchableIcon
                  Component={FontAwesome5}
                  name="edit"
                  size={20}
                  onPress={() => {
                    setWorkPackageInputAction(user);

                    navigation.navigate('Edit Work Package', { id: user.id });
                  }}
                />,
              ])
          : searchResults
              .filter((item) => item.activeFlag == 'N')
              .map((user) => [
                user.name,
                user.description,
                user.engine,
                user.statusDescription,
                <TouchableIcon
                  Component={FontAwesome5}
                  name="edit"
                  size={20}
                  onPress={() => {
                    setWorkPackageInputAction(user);
                    navigation.navigate('Edit Work Package', { id: user.id });
                  }}
                />,
              ])
      }
    />
  );
}

export default WorkPackageList;
