import Page from '../containers/Page';
import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import Form from '../containers/Form';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditUserPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    getUserThunk,
    setIsEditableUserAction,
    getRolesThunk,
    getLocationsThunk,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      getUserThunk(route.params.id);
      getLocationsThunk();
      getRolesThunk();
      setIsEditableUserAction(false);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.User
          navigation={navigation}
          buttons={[
            <Button.EditUser />,
            <Button.GoToUsers navigation={navigation}>Cancel</Button.GoToUsers>,
            <Button.SaveUser navigation={navigation} message="Updated user" />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
