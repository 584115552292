import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function AddInspectionTypesPage({
  route,
  navigation,
  ...props
}) {
  const isFocused = useIsFocused();

  const {
    resetInspectionItemsInputAction,
    resetDocumentAction,
    setIsEditableInspectionAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      setIsEditableInspectionAction(true);
      resetInspectionItemsInputAction();
      resetDocumentAction();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.InspectionType
          navigation={navigation}
          buttons={[
            <Button.GoToInspection navigation={navigation}>
              Cancel
            </Button.GoToInspection>,
            <Button.SaveInspectionType
              navigation={navigation}
              message="Added Inspection Type"
              disabled={false}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
