import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';
import { vhToDp, vwToDp } from '../../utilities/general';

import ExcelJS from 'exceljs';
import moment from 'moment';
import download from 'downloadjs';
import { View } from 'react-native';

const exportToCSV = async (data) => {
  const now = new Date();
  const fileName = `Users_${moment().format('MM/DD/YYYY')}.xlsx`;

  return new Promise((resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Me';
    workbook.created = now;
    workbook.modified = now;

    const worksheet = workbook.addWorksheet('My Sheet', {});

    worksheet.columns = Object.keys(data[0]).map((key) => ({
      header: key,
      key,
    }));

    data.forEach((user) => {
      worksheet.addRow(user);
    });

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });

    worksheet.columns.forEach((column) => {
      let dataMax = column.values.reduce((max, rowVal) => {
        if (rowVal !== null && rowVal.toString().length > max) {
          return rowVal.toString().length;
        }
        return max;
      }, 0);
      column.width = dataMax < 10 ? 10 : dataMax;
    });

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        download(
          blob,
          fileName,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function UsersPage({ navigation, ...props }) {
  const isFocused = useIsFocused();
  const { user } = useStoreState((state) => state);
  const { getUsersThunk, setUserConfirmPasswordAction, resetUserInputAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      getUsersThunk();
      setUserConfirmPasswordAction('');
      resetUserInputAction();
    }
  }, [isFocused]);

  return (
    <>
      <Page.Protected {...props}>
        <Container>
          <SRow>
            <Col xs={12} sm={2}>
              <Button fullWidth onPress={() => navigation.navigate('Add User')}>
                Add User
              </Button>
            </Col>
            <Col xs={12} sm={10}>
              <SearchBar.Users />
            </Col>
          </SRow>

          <Gap />

          <Card
            toolTipText={'How to use the User Screen'}
            toolUrl={'https://www.screencast.com/t/murU9uVlJkR2'}
          >
            <List.Users navigation={navigation} />
          </Card>
        </Container>
      </Page.Protected>
      <View
        style={{
          position: 'absolute',
          right: 20,
          bottom: 20,
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Button
          fullWidth
          onPress={() => {
            exportToCSV(user.searchResults);
          }}
        >
          Generate User CSV
        </Button>
      </View>
    </>
  );
}

export default UsersPage;
