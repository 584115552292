const defaultInputState = {
  id: null,
  inspectionTypeId: null,
  name: '',
  description: '',
  responseType: 1,
};

const state = {
  inspectionItems: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
    inspectionItemAreYouSureModalVisible: false,
  },
};

export { state as default, defaultInputState };
