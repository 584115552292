import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function ReserveWorkPackageButton({
  navigation,
  children = 'Reserve Work Package',
  ...props
}) {
  const { setReservationModalAction, setWPReservationModalAction } =
    useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() => {
        setWPReservationModalAction(true);
        setReservationModalAction(true);
      }}
      fullWidth
      {...props}
      color={'#00205B'}
    >
      {children}
    </Button>
  );
}
