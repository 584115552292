import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function AddUserPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const { user } = useStoreState((state) => state);
  const { input } = user;
  const {
    resetUserInputAction,
    resetDocumentAction,
    setIsEditableUserAction,
    getLocationsThunk,
    getRolesThunk,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      resetUserInputAction();
      resetDocumentAction();
      getLocationsThunk();
      getRolesThunk();
      setIsEditableUserAction(true);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.User
          navigation={navigation}
          buttons={[
            <Button.GoToUsers navigation={navigation}>Cancel</Button.GoToUsers>,
            <Button.SaveUser
              navigation={navigation}
              message="Added user"
              disabled={
                !input.userName || input.password !== user.confirmPassword
              }
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}

export default AddUserPage;
