import { Platform, TouchableOpacity } from 'react-native';

function TouchableIcon({ Component, ...props }) {
  return (
    <TouchableOpacity {...props}>
      <Component
        size={Platform.OS == 'web' ? '1.5rem' : '1%'}
        color={props.disabled ? '#99a1a8' : '#00205B'}
        {...props}
      />
    </TouchableOpacity>
  );
}

export default TouchableIcon;
