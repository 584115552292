import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setWorkPackageToolsSearchResultsAction: action((state, searchResults) => {
    state.workPackageTools.searchResults = searchResults;
  }),
  setWorkPackageToolsSearchInputAction: action((state, searchInput) => {
    state.workPackageTools.searchInput = searchInput;
  }),
  setIsEditableWorkPackageToolsAction: action((state, isEditable) => {
    state.workPackageTools.isEditable = isEditable;
  }),
  setWorkPackageToolsInputAction: action((state, input) => {
    state.workPackageTools.input = {
      ...state.workPackageTools.input,
      ...input,
    };
  }),
  resetWorkPackageToolsInputAction: action((state, input) => {
    state.workPackageTools.input = defaultInputState;
  }),
  setWPToolAreYouSureModalVisibleAction: action(
    (state, wpToolAreYouSureModalVisible) => {
      state.workPackageTools.wpToolAreYouSureModalVisible =
        wpToolAreYouSureModalVisible;
    }
  ),
  setWorkPackageToolsLocationReportAction: action(
    (state, workPackageToolsLocationReport) => {
      state.workPackageTools.workPackageToolsLocationReport =
        workPackageToolsLocationReport;
    }
  ),
};

export default actions;
