import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setInspectionSearchResultsAction: action((state, searchResults) => {
    state.inspectionTypes.map = searchResults.reduce((map, item) => {
      if (!map[item.id]) {
        map[item.id] = item;
      }
      return map;
    }, {});
    state.inspectionTypes.searchResults = searchResults;
  }),

  setInspectionDropdownOptionsAction: action((state, dropdownOptions) => {
    state.inspectionTypes.dropdownOptions = dropdownOptions.map((item) => {
      return { item: item.name, id: item.id };
    });
  }),

  setInspectionSearchInputAction: action((state, searchInput) => {
    state.inspectionTypes.searchInput = searchInput;
  }),
  setIsEditableInspectionAction: action((state, isEditable) => {
    state.inspectionTypes.isEditable = isEditable;
  }),
  setInspectionInputAction: action((state, input) => {
    state.inspectionTypes.input = {
      ...state.inspectionTypes.input,
      ...input,
    };
  }),
  resetInspectionTypeInputAction: action((state, input) => {
    state.inspectionTypes.input = {
      ...defaultInputState,
    };
  }),
  setInspectionTypeAreYouSureModalVisibleAction: action(
    (state, inspectionTypeAreYouSureModalVisible) => {
      state.inspectionTypes.inspectionTypeAreYouSureModalVisible =
        inspectionTypeAreYouSureModalVisible;
    }
  ),
};

export default actions;
