import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import StepForm from '../StepForm';

export default function InspectionQuestionsModal() {
  const {
    tools: { myToolFilter, showInspectionQuestionsModal },
  } = useStoreState((state) => state);

  const { setShowInspectionQuestionsModalAction } = useStoreActions(
    (actions) => actions
  );
  return (
    <CustomModal
      style={{ width: 1200 }}
      width='50%'
      height='50%'
      isVisible={showInspectionQuestionsModal}
      title={!myToolFilter ? `Inspection` : 'Check-Out Work Packages'}
      onClose={() => setShowInspectionQuestionsModalAction(false)}
    >
      <StepForm.InspectionStep />
    </CustomModal>
  );
}
