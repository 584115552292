import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

function EditBrokenToolButton({ children = 'Edit', ...props }) {
  const { brokenToolEditable } = useStoreState((state) => state.tools);

  const { setIsEditableBrokenToolsAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={() => setIsEditableBrokenToolsAction(true)}
      disabled={brokenToolEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}

export default EditBrokenToolButton;
