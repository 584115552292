import { action } from 'easy-peasy';
import { defaultState } from './state';

const actions = {
  setLoginInputAction: action((state, input) => {
    state.auth.loginInput = {
      ...state.auth.loginInput,
      ...input,
    };
  }),
  resetLoginInputAction: action((state) => {
    state.auth.loginInput = defaultState.loginInput;
  }),
  setUserAction: action((state, user) => {
    state.auth.user = {
      ...state.auth.user,
      ...user,
    };
  }),
  resetUserAction: action((state) => {
    state.auth.user = defaultState.user;
  }),
  setPasswordChangeInputAction: action((state, input) => {
    state.auth.passwordChangeInput = {
      ...state.auth.passwordChangeInput,
      ...input,
    };
  }),

  setPasswordChangeModalAction: action((state, payload) => {
    state.auth.passwordChangeModal = payload;
  }),
  setUserTenantId: action((state, tenantId) => {
    state.auth.user.tenantId = tenantId;
  }),
  setUserId: action((state, id) => {
    state.auth.user.id = id;
  }),
};

export default actions;
