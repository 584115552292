import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function SaveWorkPackageButton({
  navigation,
  children = 'Save',
  message,
  ...props
}) {
  const {
    workPackage: { isEditable, input },
    tenant,
  } = useStoreState((state) => state);

  const { upsertWorkPackageThunk } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() =>
        upsertWorkPackageThunk({
          navigation,
          input: {
            ...input,
          },
          message,
        })
      }
      disabled={!isEditable || !input.name}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
