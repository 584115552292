import { action } from 'easy-peasy';

const actions = {
  setLoadingAction: action((state, loading) => {
    state.status.loading = loading;
  }),
  addAlertAction: action((state, alert) => {
    state.status.alerts = [...state.status.alerts, alert];
  }),
  removeAlertAction: action((state, alertToRemoveId) => {
    state.status.alerts = state.status.alerts.filter(
      (alert) => alert.id !== alertToRemoveId
    );
  }),
  setSortColAction: action((state, sortCol) => {
    state.status.sortCol = sortCol;
  }),
  setSortDirAction: action((state, sortDir) => {
    state.status.sortDir = sortDir;
  }),

  saveAlertAction: action((state, alert) => {
    state.status.savedAlerts = [...state.status.savedAlerts, alert];
  }),

  setImageLoading: action((state, imageLoading) => {
    state.status.imageLoading = imageLoading;
  }),

  setLoadingAction1: action((state, loading) => {
    state.status.loading1 = loading;
  }),
  setAreYouSureModalVisibleAction: action((state, areYouSureModalVisible) => {
    state.status.areYouSureModalVisible = areYouSureModalVisible;
  }),
};

export default actions;
