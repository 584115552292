import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';

function useOnPress(navigation) {
  const { duplicateWorkPackageThunk } = useStoreActions((actions) => actions);

  return () => {
    duplicateWorkPackageThunk({ navigation });
  };
}

export default function SaveDuplicateWorkPackageButton({
  navigation,
  children = 'Save',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}
