import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

function EngineFamilySearchBar() {
  const { searchInput } = useStoreState((state) => state.engines);
  const { setEngineFamilySearchInputAction } = useStoreActions(
    (actions) => actions
  );

  function clearSearchInput() {
    return () => setEngineFamilySearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setEngineFamilySearchInputAction(newVal)}
      placeholder='Search'
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}

export default EngineFamilySearchBar;
