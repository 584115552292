import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setInspectionItemsSearchResultsAction: action((state, searchResults) => {
    state.inspectionItems.searchResults = searchResults;
  }),

  setInspectionItemsSearchInputAction: action((state, searchInput) => {
    state.inspectionItems.searchInput = searchInput;
  }),
  setIsEditableInspectionItemsAction: action((state, isEditable) => {
    state.inspectionItems.isEditable = isEditable;
  }),
  setInspectionItemsInputAction: action((state, input) => {
    state.inspectionItems.input = {
      ...state.inspectionItems.input,
      ...input,
    };
  }),
  resetInspectionItemsInputAction: action((state, input) => {
    state.inspectionItems.input = defaultInputState;
  }),
  setInspectionItemAreYouSureModalVisibleAction: action(
    (state, inspectionItemAreYouSureModalVisible) => {
      state.inspectionItems.inspectionItemAreYouSureModalVisible =
        inspectionItemAreYouSureModalVisible;
    }
  ),
};

export default actions;
