import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import Card from '../../presentational/Card';

export default function DropdownForm({ buttons, ...props }) {
  const {
    dropdown: { input, isEditable },
    document,
  } = useStoreState((state) => state);

  const { setDropdownInputAction } = useStoreActions((actions) => actions);

  return (
    <Card>
      <Row {...props}>
        <Col>
          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in Component Id"
                label="Component Id"
                value={input.component}
                onChangeText={(componentId) =>
                  setDropdownInputAction({ componentId })
                }
                required
                editable={isEditable}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in Control Id"
                label="Control Id"
                value={input.control}
                onChangeText={(controlId) =>
                  setDropdownInputAction({ controlId })
                }
                editable={isEditable}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in Value"
                label="Value"
                value={input.value}
                onChangeText={(value) => setDropdownInputAction({ value })}
                editable={isEditable}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in Sort Order"
                label="Sort Order"
                value={input.sortOrder}
                onChangeText={(sortOrder) =>
                  setDropdownInputAction({ sortOrder })
                }
                editable={isEditable}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
