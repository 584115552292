import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function Home({ navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    auth: { user },
  } = useStoreState((state) => state);

  const { getTenantsThunk, resetTenantInputAction } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    if (isFocused) {
      resetTenantInputAction();
      getTenantsThunk();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <SRow>
          {user.tenantId == 1 && user.role == 'admin' ? (
            <Col xs={12} sm={2}>
              <Button
                fullWidth
                onPress={() => navigation.navigate('Add Tenant')}
              >
                Add Tenant
              </Button>
            </Col>
          ) : user.role == 'customer admin' ? (
            <Col xs={12} sm={2}>
              <Button
                fullWidth
                onPress={() => navigation.navigate('Add Tenant')}
              >
                Add Tenant
              </Button>
            </Col>
          ) : user.role == 'Customer Admin' ? (
            <Col xs={12} sm={2}>
              <Button
                fullWidth
                onPress={() => navigation.navigate('Add Tenant')}
              >
                Add Tenant
              </Button>
            </Col>
          ) : null}
          <Col
            xs={12}
            sm={
              user.tenantId == 1 && user.role == 'admin'
                ? 10
                : user.role == 'customer admin'
                ? 10
                : user.role == 'Customer Admin'
                ? 10
                : user.role !== 'admin' || user.role !== 'customer admin'
                ? 12
                : 12
            }
          >
            <SearchBar.Tenant />
          </Col>
        </SRow>

        <Gap />

        <Card
          toolTipText={'How to use the Tenant Screen'}
          toolUrl={
            'https://app.screencast.com/s5bgDZEZ0HZWw?conversation=Xl1dmB7v4ZOVjHplYVk1Jb'
          }
        >
          <List.Tenants navigation={navigation} />
        </Card>
      </Container>
    </Page.Protected>
  );
}

export default Home;
