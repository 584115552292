import { Dimensions, Platform, View } from 'react-native';
import Col from '../../presentational/Col';
import Row from '../../presentational/Row';
import Text from '../../presentational/Text';
import Image from '../../presentational/Image';
import styled from 'rn-css';
import { useStoreState } from 'easy-peasy';

const Container = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;

const SText = styled(Text)`
  text-align: right;

  @media (max-width: 576px) {
    display: none;
  }
`;

const SText2 = styled(Text)`
  text-align: right;
  color: green;
  @media (max-width: 576px) {
    display: none;
  }
`;

const width = Dimensions.get('window').width;
console.log('width', width);

function DrawerHeader() {
  const { firstName, lastName, role } = useStoreState(
    (state) => state.auth.user
  );
  const { input } = useStoreState((state) => state.tenant);

  return (
    <Container>
      <Col xs={0} sm={3} />

      {Platform.OS === 'web' && (
        <Col xs={0} sm={4}>
          <Image
            name={require('../../../../assets/favicon.png')}
            height={`${Platform.OS === 'web' ? '70px' : '0px'} `}
            width='85%'
            style={{
              margin: 10,
            }}
          />
        </Col>
      )}
      <Col xs={0} sm={5}>
        <View
          style={{
            marginTop: 10,
            marginRight: 11,
            width: '100%',
          }}
        >
          <SText>Welcome to the Rhinestahl Readiness Hub</SText>
          <SText>
            {firstName} {lastName} - {role}
          </SText>
          {input.id != null && input.name && (
            <SText>
              Acting as Tenant: <SText2>{input.name}</SText2>
            </SText>
          )}
        </View>
      </Col>
    </Container>
  );
}

export default DrawerHeader;
