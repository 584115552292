import { axios } from ".";

const roleAxios = axios.create({
	// baseURL: `https://tmpbackenduat.azurewebsites.net/role`,
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/role`,
});

function getRolesAPICall(payload) {
	const { id, tenantId } = payload;
	return roleAxios({
		method: "get",
		url: `/get?id=${id}&tenantId=${tenantId}`,
	});
}

export { roleAxios, getRolesAPICall };
