import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

export default function ToolStatusSearchBar() {
  const { searchInput } = useStoreState((state) => state.toolStatus);
  const { setToolStatusSearchInputAction } = useStoreActions(
    (actions) => actions
  );

  function clearSearchInput() {
    return () => setToolStatusSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setToolStatusSearchInputAction(newVal)}
      placeholder="Search tool status by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}
