import React from 'react';
import { StyleSheet, View, ScrollView, TouchableOpacity } from 'react-native';
import { Divider } from '@rneui/themed';
import Button from './Button';
import Text from './Text';
import Gap from './Gap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from './Input';

export default function UniversalStepForm({
  steps,
  handleSubmit,
  handleNext,
  ...props
}) {
  const {
    tools: { currentStep, currentSubStep, inspectionHistory, inspectionInput },
    inspectionTypes,
  } = useStoreState((state) => state);
  const {
    setCurrentStep,
    setCurrentSubStep,
    setToolInspectionResultInputAction,
    setShowInspectionQuestionsModalAction,
  } = useStoreActions((actions) => actions);

  console.log(steps, props);

  const handleNextStep = () => {
    if (
      currentSubStep == props.items[currentStep - 1].length &&
      currentStep < steps.length
    ) {
      handleNext();

      setCurrentStep(currentStep + 1);
      setCurrentSubStep(1);
    } else if (
      currentSubStep == props.items[currentStep - 1].length &&
      currentStep == steps.length
    ) {
      handleNext();
      setShowInspectionQuestionsModalAction(false);
      setCurrentStep(1);
      setCurrentSubStep(1);
    } else {
      handleNext();
      setCurrentSubStep(currentSubStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderFormStep = () => {
    if (
      props &&
      props.items &&
      props.items[currentStep - 1] &&
      steps &&
      steps.length &&
      currentSubStep == props.items[currentStep - 1].length &&
      currentStep < steps.length
    ) {
      return (
        <>
          <Button
            title='Previous'
            fullWidth
            onPress={handlePreviousStep}
            disabled={currentStep === 0 && currentSubStep === 0}
          />
          <Gap />

          <Button title='Complete' fullWidth onPress={handleNextStep} />
        </>
      );
    } else if (
      props &&
      props.items &&
      props.items[currentStep - 1] &&
      steps &&
      steps.length &&
      currentSubStep == props.items[currentStep - 1].length &&
      currentStep == steps.length
    ) {
      return (
        <>
          <Button title='Submit' fullWidth onPress={handleSubmit} />
        </>
      );
    } else {
      return (
        <>
          {currentSubStep !== 1 && (
            <Button
              title='Previous'
              fullWidth
              onPress={handlePreviousStep}
              disabled={currentStep === 1}
            />
          )}
          <Gap />

          <Button title='Next Step' fullWidth onPress={handleNextStep} />
        </>
      );
    }
  };

  const renderStepIndicators = () => {
    return steps.map((step, index) => {
      return (
        <TouchableOpacity
          key={index}
          // onPress={() => setCurrentStep(index + 1)}
          style={[
            styles.stepIndicator,
            currentStep === index + 1 && styles.currentStepIndicator,
          ]}
        >
          <Text
            key={index}
            style={[
              styles.stepIndicator,
              currentStep === index + 1 && styles.currentStepIndicator,
            ]}
          >
            {
              props.types.find(
                (inspectionType) =>
                  inspectionType.id == props.items[index][0].inspectionTypeId
              ).name
            }
          </Text>
        </TouchableOpacity>
      );
    });
  };

  const renderSubStepIndicators = () => {
    if (props.items[currentStep - 1]) {
      return props.items[currentStep - 1].map((substep, index) => {
        return (
          <TouchableOpacity
            key={index}
            // onPress={() => setCurrentSubStep(index + 1)}
            style={[
              styles.stepIndicator,
              currentSubStep === index + 1 && styles.currentStepIndicator,
            ]}
          >
            <Text
              key={index}
              style={[
                styles.stepIndicator,
                currentSubStep === index + 1 && styles.currentStepIndicator,
              ]}
            >
              {substep.name}
            </Text>
          </TouchableOpacity>
        );
      });
    } else {
      return null;
    }
  };
  const renderSubStepForm = () => {
    if (
      props.items[currentStep - 1] &&
      props.items[currentStep - 1][currentSubStep - 1]
    ) {
      let item = props.items[currentStep - 1][currentSubStep - 1];
      if (item && item.questionType) {
        const questionType = JSON.parse(item.questionType);
        let dArr = Object.values(questionType);

        return (
          <Input
            onSelect={(choice) => {
              setToolInspectionResultInputAction({
                inspectiontypeId: item.inspectionTypeId,
                inspectionTypeName: inspectionTypes.searchResults.find(
                  (inspectionType) => inspectionType.id == item.inspectionTypeId
                ).name,

                inspectionitemId: item.id,
                inspectionItemName: item.name,
                inspectionItemDesc: item.description,
                inspectedBy: 'test',
                inspectionResponse: choice,
                inspectionNotes: '',
                inspectionDate: new Date(),
              });
            }}
            value={
              inspectionHistory.find(
                (inspection) => inspection.inspectionitemId == item.id
              ) == null
                ? ''
                : inspectionHistory.find(
                    (inspection) => inspection.inspectionitemId == item.id
                  ).inspectionAnswer || ''
            }
            required
            placeholder='Answer'
            label={item.description}
            dropdown={item.questionType == null ? false : true}
            dropdownChoices={item.questionType == null ? [] : dArr.slice(1)}
            editable
            rowTextForSelection={(item) => item}
            selectedItem={(item) => item}
            disabled={false}
          />
        );
      } else {
        return (
          <Input
            required
            placeholder='Answer'
            label={item.description}
            onChangeText={(choice) => {
              setToolInspectionResultInputAction({
                inspectiontypeId: item.inspectionTypeId,
                inspectionTypeName: inspectionTypes.searchResults.find(
                  (inspectionType) => inspectionType.id == item.inspectionTypeId
                ).name,

                inspectionitemId: item.id,
                inspectionItemName: item.name,
                inspectionItemDesc: item.description,
                inspectedBy: 'test',
                inspectionResponse: choice,
                inspectionNotes: '',
                inspectionDate: new Date(),
              });
            }}
            value={inspectionInput.inspectionResponse}
            disabled={false}
          />
        );
      }
    } else {
      return null;
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView
        horizontal
        contentContainerStyle={styles.stepIndicatorsContainer}
      >
        {renderStepIndicators()}
      </ScrollView>
      <ScrollView
        horizontal
        contentContainerStyle={styles.stepIndicatorsContainer}
      >
        {renderSubStepIndicators()}
      </ScrollView>

      <Divider />
      <View style={styles.formContainer}>{renderSubStepForm()}</View>
      <View style={styles.formContainer}>{renderFormStep()}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  stepIndicatorsContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: 16,
    width: '100%',
  },
  stepIndicator: {
    width: 'auto',
    fontFamily: 'Barlow_600SemiBold',
    fontSize: 18,
    textAlign: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 8,
    marginRight: 8,
  },
  currentStepIndicator: {
    backgroundColor: '#e8e9ea',
  },
  formContainer: {
    marginTop: 16,
  },
});
