import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

export default function MessagesSearchBar() {
  const { searchInput } = useStoreState((state) => state.messages);
  const { setMessagesSearchInputAction } = useStoreActions(
    (actions) => actions
  );

  function clearSearchInput() {
    return () => setMessagesSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setMessagesSearchInputAction(newVal)}
      placeholder='Search Messages'
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}
