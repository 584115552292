import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function DropdownList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.dropdown,
    searchResults: state.dropdown.searchResults.filter((user) =>
      formatStringForSearch(user.value).includes(
        formatStringForSearch(state.dropdown.searchInput)
      )
    ),
  }));
  const { setDropdownInputAction } = useStoreActions((actions) => actions);

  return (
    <List
      tableHead={['Component', 'Control', 'Value', 'Sort Order', 'Edit']}
      tableRows={searchResults.map((user) => [
        user.component,
        user.control,
        user.value,
        user.sortOrder,
        <TouchableIcon
          Component={FontAwesome5}
          name="edit"
          size={20}
          onPress={() => {
            setDropdownInputAction(user);
            navigation.navigate('Edit Dropdown', { id: user.id });
          }}
        />,
      ])}
    />
  );
}
