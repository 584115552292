import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function EngineFamilyList({ navigation }) {
  const hasSearchText = (value, searchInput) =>
    value?.toString().toLowerCase().trim().includes(searchInput.toLowerCase());

  const { filteredList } = useStoreState((state) => ({
    ...state.engines,
    filteredList: state.engines.list.filter(
      (engine) =>
        hasSearchText(engine.EngineFamily, state.engines.searchInput) ||
        hasSearchText(engine.EngineFamilyDescription, state.engines.searchInput)
    ),
  }));

  const { setSelectedEngineAction } = useStoreActions((actions) => actions);

  return (
    <List
      tableHead={['Name', 'Description', 'Edit']}
      tableRows={filteredList.map((engine) => [
        engine.EngineFamily,
        engine.EngineFamilyDescription,
        <TouchableIcon
          Component={FontAwesome5}
          name='edit'
          size={20}
          onPress={() => {
            setSelectedEngineAction(engine);
            navigation.navigate('Edit Engine Family', { id: engine.id });
          }}
        />,
      ])}
    />
  );
}
