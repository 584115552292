import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function AddWorkPackageToolPage({
  route,
  navigation,
  ...props
}) {
  const isFocused = useIsFocused();

  const {
    workPackage: { input },
    workPackageTools,
  } = useStoreState((state) => state);
  const {
    setIsEditableWorkPackageToolsAction,
    setWorkPackageToolsInputAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      setWorkPackageToolsInputAction({
        workpackageId: input.id,
        workpackageName: input.name,
        toolpartNumber: '',
      });
      setIsEditableWorkPackageToolsAction(true);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.WorkPackageTool
          navigation={navigation}
          buttons={[
            <Button.GoToWorkPackages navigation={navigation}>
              Cancel
            </Button.GoToWorkPackages>,
            <Button.SaveWorkPackageTool
              navigation={navigation}
              message="Added Tool"
              disabled={!workPackageTools.input.toolpartNumber}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
