import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';

function useOnPress(navigation) {
  // const { setIsEditableToolsAction } = useStoreActions((actions) => actions);

  return () => {
    // setIsEditableToolsAction(false);
    navigation.navigate('Breakage Category');
  };
}

export default function GoToToolCategoryButton({
  navigation,
  children = 'Breakage Categories',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}
