import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import Text from "./Text";
import Row from "./Row";
import moment from "moment";

const CollapsibleList = ({ inspectionHistory, searchResults }) => {
	const [selectedId, setSelectedId] = useState(null);

	const Item = ({ type, items }) => {
		const isExpanded = type === selectedId;
		return (
			<View>
				<TouchableOpacity
					onPress={() => setSelectedId(isExpanded ? null : type)}
					style={{
						width: "70vh",
						padding: 10,
						borderWidth: 0.1,
						borderColor: "lightgray",
						flexDirection: "row",
						alignItems: "center",
						backgroundColor: isExpanded ? "lightgray" : "white",
					}}
				>
					<MaterialIcons
						name={isExpanded ? "keyboard-arrow-down" : "keyboard-arrow-right"}
						size={24}
						color="black"
					/>
					<Text style={{ fontSize: 20, color: "#ab2328", marginLeft: 10 }}>
						{type}
					</Text>
					<Text style={{ fontSize: 20, color: "gray", marginLeft: "auto" }}>
						{moment(inspectionHistory[type][0].inspectionDate).format(
							"MM-DD-YYYY"
						)}
					</Text>
				</TouchableOpacity>
				{isExpanded &&
					items.map((item, index) => (
						<View key={item.id}>
							<Text
								style={{
									fontSize: 16,
									margin: 5,
									fontFamily: "Barlow_600SemiBold",
								}}
							>
								{item.inspectionitemDescription}
							</Text>
							<Row>
								<Text
									style={{
										fontSize: 14,
										marginLeft: 15,
									}}
								>
									Answer:
								</Text>

								<Text
									style={{
										fontSize: 17,
										marginLeft: 15,
										fontFamily: "Barlow_500Medium_Italic",
									}}
								>
									{" " + item.inspectionAnswer}
								</Text>
							</Row>
						</View>
					))}
			</View>
		);
	};

	return (
		<View style={{ width: "70%" }}>
			{Object.keys(inspectionHistory).map((type) => (
				<Item key={type} type={type} items={inspectionHistory[type]} />
			))}
		</View>
	);
};

export default CollapsibleList;
