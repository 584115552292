import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Entypo } from '@expo/vector-icons';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import Icon from '../../presentational/Icon';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import Gap from '../../presentational/Gap';
import {
  formatStringForSearch,
  pxToDp,
  vhToDp,
} from '../../../utilities/general';
import Image from '../../presentational/Image';
import Row from '../../presentational/Row';
import { ActionSheetIOS, Alert, Platform } from 'react-native';

export default function AddBrokenToolModal() {
  const {
    tools: { brokenToolInput, brokenToolModalVisible, toolCategoryList, input },
    tenant,
    document,
    status: { placeholder },
  } = useStoreState((state) => state);
  const { searchResults } = useStoreState((state) => ({
    ...state.tools,
    searchResults: state.tools.searchResults.filter((tool) =>
      formatStringForSearch(tool.partNumber || tool.serialNumber).includes(
        formatStringForSearch(state.tools.brokenToolSearchInput)
      )
    ),
  }));
  const {
    setBrokenToolInputAction,
    setBrokenToolModalVisibleAction,
    reportBrokenToolThunk,
    getToolsThunk,
    setDocumentAction,
    setToolsInputAction,
  } = useStoreActions((actions) => actions);

  const handleButtonPress = () => {
    ActionSheetIOS.showActionSheetWithOptions(
      {
        options: ['Cancel', 'Take Photo', 'Pick from Library'],
        cancelButtonIndex: 0,
      },
      (buttonIndex) => {
        if (buttonIndex === 1) {
          takePhoto();
        } else if (buttonIndex === 2) {
          pickImage();
        }
      }
    );
  };

  const takePhoto = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();

    if (status !== 'granted') {
      Alert.alert('Sorry, we need camera roll permissions to make this work!');
      return;
    }

    let result = await ImagePicker.launchCameraAsync({
      base64: true,
    });
    if (!result.canceled) {
      setDocumentAction({
        notes: result.assets[0].fileName,
        tenantId: tenant.input.id,
        mimeType: result.assets[0].type,
        type: 'broken',
        body: 'data:image/jpeg;base64,' + result.assets[0].base64,
        toolid: brokenToolInput.toolId,
      });
    }
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      base64: true,
    });
    if (!result.canceled) {
      setDocumentAction({
        notes: result.assets[0].fileName,
        tenantId: tenant.input.id,
        mimeType: result.assets[0].type,
        type: 'broken',
        body: 'data:image/jpeg;base64,' + result.assets[0].base64,
        toolid: brokenToolInput.toolId,
      });
    }
  };

  return (
    <CustomModal
      onClose={() => {
        setBrokenToolModalVisibleAction(false);
      }}
      isVisible={brokenToolModalVisible}
      title='Report Unserviceable Tool'
      style={{ width: 800 }}
      width='80%'
      height={Platform.OS == 'web' ? '50%' : '64%'}
    >
      <Row>
        <Col>
          <Input
            placeholder='Type in Tool Part Number'
            label='Tool Part Number'
            value={input.partNumber || brokenToolInput.toolId}
            onSelect={(toolpartNumber, index) => {
              setToolsInputAction(
                searchResults.find((tool) => tool.id == toolpartNumber.id)
              );

              setBrokenToolInputAction({
                toolId: toolpartNumber.id,
              });
            }}
            editable
            selectedItem={(item) => item.partNumber}
            rowTextForSelection={(item) => item.partNumber}
            dropdown
            dropdownChoices={searchResults}
            width={Platform.OS == 'web' ? '95%' : '98%'}
            search={true}
            searchInputStyle={{
              width: '100%',
              alignSelf: 'center',
              backgroundColor: '#F7F7F7',
              border: 0,

              boxShadow: '0px 0px 10px grey',
              borderColor: '#F7F7F7',
            }}
            searchInputTxtStyle={{
              fontFamily: 'Barlow_400Regular',
              fontSize: 16,
            }}
            renderSearchInputRightIcon={() => (
              <Icon Component={Entypo} name='magnifying-glass' />
            )}
            searchInputPlaceholder='Search for Tool Part Number'
          />
        </Col>
        <Col>
          <Input
            placeholder='Type in Serial Num'
            label='Serial Number'
            value={input.serialNumber}
            onSelect={(serialNumber) =>
              setBrokenToolInputAction({ serialNumber })
            }
            editable
            selectedItem={(item) => item.serialNumber}
            rowTextForSelection={(item) => item.serialNumber}
            dropdown
            dropdownChoices={
              // Sometimes tools will have the same name but different serial numbers so we need to filter out the tools with the same name
              searchResults.filter(
                (tool) => tool.partNumber == input.partNumber
              ) || []
            }
            width={Platform.OS == 'web' ? '95%' : '98%'}
            search={true}
            searchInputStyle={{
              width: '100%',
              alignSelf: 'center',
              backgroundColor: '#F7F7F7',
              border: 0,

              boxShadow: '0px 0px 10px grey',
              borderColor: '#F7F7F7',
            }}
            searchInputTxtStyle={{
              fontFamily: 'Barlow_400Regular',
              fontSize: 16,
            }}
            renderSearchInputRightIcon={() => (
              <Icon Component={Entypo} name='magnifying-glass' />
            )}
            searchInputPlaceholder='Search for Tool Part Number'
          />
        </Col>
        <Col>
          <Input
            placeholder='Type in Breakage Category'
            label='Breakage Category'
            value={brokenToolInput.brokentoolcategoryDescription}
            onSelect={(categoryDescription) =>
              setBrokenToolInputAction({
                toolbrokenCategoryCode: categoryDescription.categoryCode,
                brokentoolcategoryDescription:
                  categoryDescription.categoryDescription,
              })
            }
            selectedItem={(item) => item.categoryDescription}
            rowTextForSelection={(item) => item.categoryDescription}
            dropdown
            dropdownChoices={toolCategoryList}
            width={Platform.OS == 'web' ? '95%' : '98%'}
            search={true}
            searchInputStyle={{
              width: '100%',
              alignSelf: 'center',
              backgroundColor: '#F7F7F7',
              border: 0,

              boxShadow: '0px 0px 10px grey',
              borderColor: '#F7F7F7',
            }}
            searchInputTxtStyle={{
              fontFamily: 'Barlow_400Regular',
              fontSize: 16,
            }}
            editable={true}
            renderSearchInputRightIcon={() => (
              <Icon Component={Entypo} name='magnifying-glass' />
            )}
            searchInputPlaceholder='Search for Tool Part Number'
          />
        </Col>
        <Col>
          <Input
            placeholder='Type in Description of Breakage'
            label='Description of Breakage'
            value={brokenToolInput.toolbrokendescription}
            onChangeText={(toolbrokendescription) =>
              setBrokenToolInputAction({ toolbrokendescription })
            }
            multiline
            style={{ height: Platform.OS == 'web' ? 150 : 25 }}
          />
        </Col>

        <Col>
          <Image
            style={{
              marginBottom: pxToDp(15),
              border: '1px solid black',
            }}
            height={vhToDp(25)}
            uri={document.body !== '' ? document.body : placeholder.body}
          />
        </Col>
      </Row>

      <Button
        fullWidth
        onPress={async () => {
          if (Platform.OS == 'web') {
            const document = await DocumentPicker.getDocumentAsync({
              type: 'image/*',
            });

            setDocumentAction({
              notes: document.name,
              tenantId: tenant.input.id,
              mimeType: document.mimeType,
              type: 'broken',
              body: document.uri,
              toolid: brokenToolInput.toolId,
            });
          } else {
            handleButtonPress();
          }
        }}
      >
        {'Upload Tool Image'}
      </Button>
      <Gap />
      <Button
        color={'#ab2328'}
        fullWidth
        onPress={async () => {
          await reportBrokenToolThunk();

          await getToolsThunk(0);
        }}
      >
        {'Report Unserviceable Tool'}
      </Button>
    </CustomModal>
  );
}
