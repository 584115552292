import { action } from 'easy-peasy';

const actions = {
  setEngineListAction: action((state, engines) => {
    state.engines.list = engines;
  }),
  setEngineFamilySearchInputAction: action((state, input) => {
    state.engines.searchInput = input;
  }),
  setSelectedEngineAction: action((state, input) => {
    state.engines.selectedEngine = {
      ...state.engines.selectedEngine,
      ...input,
    };
  }),
  setShowVariantModalAction: action((state, show) => {
    state.engines.showVariantModal = show;
  }),
  setVariantInputAction: action((state, { input, index }) => {
    state.engines.variantInput = { ...input };
    state.engines.editIndex = index;
  }),
  addVariant: action((state) => {
    if (state.engines.editIndex) {
      state.engines.selectedEngine.variants[state.engines.editIndex] =
        state.engines.variantInput;
    } else {
      state.engines.selectedEngine.variants.push(state.engines.variantInput);
    }

    state.engines.showVariantModal = false;
  }),
};

export default actions;
