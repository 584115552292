import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";
import Row from "../../presentational/Row";

export default function ToolCategorySearchBar() {
  const { toolCategorySearchInput } = useStoreState((state) => state.tools);
  const { setToolCategorySearchInputAction } = useStoreActions(
    (actions) => actions
  );

  function clearSearchInput() {
    return () => setToolCategorySearchInputAction('');
  }

  return (
    <SearchBar
      value={toolCategorySearchInput}
      onChangeText={(newVal) => setToolCategorySearchInputAction(newVal)}
      placeholder="Search tools by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}

