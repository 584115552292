import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function AddBrokenToolPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    setIsEditableBrokenToolsAction,
    resetDocumentAction,
    resetBrokenToolInputAction,
    resetToolsInputAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      resetBrokenToolInputAction();
      resetToolsInputAction();
      setIsEditableBrokenToolsAction(true);
      resetDocumentAction();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.BrokenTool
          uploadButton={true}
          editButton={true}
          navigation={navigation}
          buttons={[
            <Button.CancelBrokenTool navigation={navigation}>
              Cancel
            </Button.CancelBrokenTool>,
            <Button.SaveBrokenTool
              navigation={navigation}
              message="Added Unserviceable Tool"
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
