import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../Button";
import Text from "../../presentational/Text";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";

export default function AreYouSureWorkWPReservation({ navigation }) {
	const { cancelWorkPackageReservationModalVisible } = useStoreState(
		(state) => state.workPackage
	);

	const {
		setCancelWorkPackageReservationModalVisibleAction,
		cancelWorkPackageReservationThunk,
	} = useStoreActions((actions) => actions);

	const handleOnSubmit = () => {
		cancelWorkPackageReservationThunk({ navigation });

		setCancelWorkPackageReservationModalVisibleAction(false);
	};

	return (
		<CustomModal
			style={{ width: 800 }}
			width="50%"
			height="50%"
			title="Confirmation"
			onClose={() => setCancelWorkPackageReservationModalVisibleAction(false)}
			isVisible={cancelWorkPackageReservationModalVisible}
		>
			<Text style={{ fontSize: 20, fontWeight: "bold", alignSelf: "center" }}>
				{"Are you sure you want to cancel this Work Package Reservation?"}
			</Text>
			<Row>
				<Col xs={12} sm={6}>
					<Button
						fullWidth
						style={{ marginTop: 20 }}
						onPress={() => {
							setCancelWorkPackageReservationModalVisibleAction(false);
						}}
					>
						Cancel
					</Button>
				</Col>
				<Col xs={12} sm={6}>
					<Button
						color="#ab2328"
						fullWidth
						style={{ marginTop: 20 }}
						onPress={handleOnSubmit}
					>
						Confirm
					</Button>
				</Col>
			</Row>
		</CustomModal>
	);
}
