import React, { useState } from 'react';
import { Alert, ActionSheetIOS, Platform } from 'react-native';
import Button from '../../presentational/Button';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { useStoreActions, useStoreState } from 'easy-peasy';

const UploadToolImageButton = ({
  isEdit,
  children = 'Upload Image',
  setIndex,
  ...props
}) => {
  const {
    tools: { isNewToolPhoto },
    tenant,
    documentsToAdd,
  } = useStoreState((state) => state);
  const {
    setDocumentAction,
    setIsNewToolPhotoAction,
    addDocumentToAddListAction,
  } = useStoreActions((actions) => actions);
  const [currentPhotoBase64, setCurrentPhotoBase64] = useState('');
  const [isNewPhoto, setIsNewPhoto] = useState(false);

  const handleUpload = async (image) => {
    const resizedImage = await ImageManipulator.manipulateAsync(
      image.uri,
      [{ resize: { width: 768 } }],
      { compress: 0.7, base64: true, format: ImageManipulator.SaveFormat.PNG }
    );

    if (resizedImage.base64 !== currentPhotoBase64) {
      console.log('NEW PHOTO');
      setIsNewToolPhotoAction(true);
      setCurrentPhotoBase64(resizedImage.base64);
      setDocumentAction({
        notes: image.fileName || image.name,
        tenantId: tenant.input.id,
        mimeType: image.mimeType,
        type: 'tool',
        body: `data:image/jpeg;base64,${resizedImage.base64}`,
      });
      await addDocumentToAddListAction({
        notes: image.fileName || image.name,
        tenantId: tenant.input.id,
        mimeType: image.mimeType,
        type: 'tool',
        body: `data:image/jpeg;base64,${resizedImage.base64}`,
      });

      setIndex({ index: 0 });
    }
  };

  const takePhoto = async () => {
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
    if (permissionResult.status !== 'granted') {
      Alert.alert(
        'Permission required',
        'Camera permission is required to continue'
      );
      return;
    }

    const result = await ImagePicker.launchCameraAsync({
      base64: true,
      quality: 0.5,
    });

    if (!result.cancelled) {
      handleUpload(result.assets[0]);
    }
  };

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      base64: true,
      quality: 0.5,
    });

    if (!result.cancelled) {
      handleUpload(result.assets[0]);
    }
  };

  const handleButtonPress = () => {
    if (Platform.OS === 'web') {
      DocumentPicker.getDocumentAsync({ type: 'image/*' }).then(handleUpload);
    } else {
      ActionSheetIOS.showActionSheetWithOptions(
        {
          options: ['Cancel', 'Take Photo', 'Pick from Library'],
          cancelButtonIndex: 0,
        },
        (buttonIndex) => {
          if (buttonIndex === 1) takePhoto();
          else if (buttonIndex === 2) pickImage();
        }
      );
    }
  };

  return (
    <Button onPress={handleButtonPress} disabled={isEdit} fullWidth {...props}>
      {children}
    </Button>
  );
};

export default UploadToolImageButton;
