import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect, useState } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';
import CustomModal from '../containers/Modal';
import { Dimensions, View } from 'react-native';
import DoubleSidedSwitch from '../presentational/DoubleSidedSwitch';
import { Ionicons } from '@expo/vector-icons';
import SelectDropdown from 'react-native-select-dropdown';

import { vhToDp, vwToDp } from '../../utilities/general';
import Text from '../presentational/Text';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

export default function NonAdminHomePage({ navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    auth: { user },
    workPackage: { checkoutPackageList, checkinPackageList },
    workPackageTools: { searchResults },
    tools: { myToolFilter, checkoutToolList, checkinToolList, reserveToolList },
  } = useStoreState((state) => state);

  const {
    setMyToolFilterAction,
    getWorkPackageThunk,
    setToolCheckOutModalAction,
    setToolCheckInModalAction,
    getInspectionThunk,
    filterToolsThunk,
    getReservedToolsThunk,
    getToolPrecheckThunk,
    clearValidListAction,
    precheckoutWorkPackageThunk,
    getToolCategoryListThunk,
    getWorkPackageToolsBeforeCheckInThunk,
    getToolUsageThunk,
  } = useStoreActions((actions) => actions);

  const [selectedItem, setSelectedItem] = useState({
    label: 'All',
    value: 'All',
  });

  const calculateSearchBarColSize = () => {
    // Initialize the size
    let size = 7;

    // Various conditions to update the size
    if (
      !myToolFilter &&
      (checkoutToolList.length > 0 || checkinToolList.length > 0)
    ) {
      size = 5;
    } else if (
      !myToolFilter &&
      (checkoutPackageList.length > 0 || checkinPackageList.length > 0)
    ) {
      size = 7;
    } else if (
      myToolFilter &&
      checkoutPackageList.length === 0 &&
      checkinPackageList.length === 0
    ) {
      size = 9;
    } else if (
      myToolFilter &&
      (checkoutPackageList.length !== 0 || checkinPackageList.length !== 0)
    ) {
      size = 7;
    }

    return size;
  };

  useEffect(() => {
    if (
      isFocused &&
      user &&
      user.role &&
      user.role !== 'admin' &&
      user.role !== 'customer admin' &&
      user.role !== 'Customer Admin'
    ) {
      filterToolsThunk('');
      getWorkPackageThunk({ id: null, tenantId: user.tenantId });
      getInspectionThunk(user.tenantId);
      getToolCategoryListThunk();
    }
  }, [isFocused, user, myToolFilter]);

  return (
    <Page.Protected {...props}>
      <Container>
        <CustomModal.ToolInformation />
        <CustomModal.Reservation />
        <CustomModal.InspectionQuestions />
        <CustomModal.CheckinTool navigation={navigation} />
        <CustomModal.CheckoutTool />
        <CustomModal.WorkOrderSerial navigation={navigation} />
        <CustomModal.WorkPackageWOSerial navigation={navigation} />
        <CustomModal.PrecheckWorkPackage />
        <CustomModal.WorkPackageReservation navigation={navigation} />
        <CustomModal.WPPrecheckInformationModal />
        <CustomModal.PreCheckWarning />
        <CustomModal.BrokenTool />
        <CustomModal.Document />
        <SRow>
          {!myToolFilter && (
            <Col xs={12} sm={2}>
              <Button.ReportBrokenTool navigation={navigation} />
            </Col>
          )}
          {checkoutToolList.length > 0 && (
            <Col xs={12} sm={2}>
              <Button
                fullWidth
                onPress={() => {
                  if (!myToolFilter) {
                    getToolPrecheckThunk();
                    setToolCheckOutModalAction(true);
                    clearValidListAction();
                  } else {
                    precheckoutWorkPackageThunk();
                  }
                }}
              >
                {!myToolFilter ? 'Review Tools' : 'Review Work Packages'}
              </Button>
            </Col>
          )}
          {reserveToolList.length > 0 && (
            <Col xs={12} sm={2}>
              <Button
                fullWidth
                onPress={() => setToolCheckOutModalAction(true)}
              >
                {!myToolFilter ? 'Reserve Tools' : 'Reserve Work Packages'}
              </Button>
            </Col>
          )}
          {checkinToolList.length > 0 && (
            <Col xs={12} sm={2}>
              <Button
                fullWidth
                onPress={() => {
                  getToolUsageThunk({
                    toolId: checkinToolList,
                    allrecordsflag: false,
                  });
                  getToolPrecheckThunk();
                  setToolCheckInModalAction(true);
                }}
              >
                Check-In Tool(s)
              </Button>
            </Col>
          )}
          {checkoutPackageList.length > 0 && (
            <Col xs={12} sm={2}>
              <Button
                fullWidth
                onPress={() => {
                  precheckoutWorkPackageThunk({ navigation });
                }}
              >
                Review Package
              </Button>
            </Col>
          )}
          {checkinPackageList.length > 0 && myToolFilter && (
            <Col xs={12} sm={2}>
              <Button
                fullWidth
                onPress={async () => {
                  await getWorkPackageToolsBeforeCheckInThunk();
                  await getToolUsageThunk({
                    toolId: null,
                    allrecordsflag: false,
                  });
                  setToolCheckInModalAction(true);
                }}
              >
                Check-In Package
              </Button>
            </Col>
          )}
          <Col xs={12} sm={calculateSearchBarColSize()}>
            {!myToolFilter ? <SearchBar.Tools /> : <SearchBar.WorkPackage />}
          </Col>
          {!myToolFilter && (
            <Col xs={12} sm={1}>
              <SelectDropdown
                selectedItem={(item) => item.label}
                value={selectedItem.label}
                data={[
                  { label: 'All', value: 'All' },
                  { label: 'Available', value: 'AV' },
                  { label: 'Checked Out', value: 'CO' },
                  { label: 'Reserved', value: 'RSVD' },
                  { label: 'Inspection', value: 'INSP' },
                ]}
                onSelect={(selectedItem, index) => {
                  if (!myToolFilter) {
                    if (selectedItem.value == 'RSVD') {
                      getReservedToolsThunk();
                      setSelectedItem(selectedItem);
                    } else if (selectedItem.value == 'All') {
                      filterToolsThunk('');
                      setSelectedItem(selectedItem);
                    } else {
                      setSelectedItem(selectedItem);
                      filterToolsThunk(selectedItem.value);
                    }
                  }
                }}
                renderButton={(item, isOpened) => {
                  return (
                    <View
                      style={{
                        backgroundColor: 'none',
                        borderRadius: 5,
                        flexDirection: 'row',
                        alignSelf: 'center',
                        alignContent: 'flex-start',
                        alignText: 'flex-start',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingLeft: '1rem',
                        width: 155,
                      }}
                    >
                      <Ionicons name='filter' size={24} color='#00205b' />
                      <Text
                        style={{
                          fontFamily: 'Barlow_600SemiBold',
                          paddingLeft: '1rem',
                          fontSize: 18,
                          color: selectedItem.label == '' ? 'gray' : '#00205b',
                          flex: 1,
                          flexDirection: 'row',
                          textAlign: 'left',
                          alignText: 'left',
                          alignSelf: 'center',
                        }}
                      >
                        {selectedItem.label || 'Select'}
                      </Text>
                    </View>
                  );
                }}
                renderItem={(item, index, isSelected) => {
                  return (
                    <View
                      style={{
                        backgroundColor: '#e8e9ea',
                        borderBottomColor: '#ccc',
                        borderBottomWidth: 1,
                        padding: 10,
                      }}
                    >
                      <Text
                        style={{
                          color: '#00205b',
                          fontSize: 18,
                          fontFamily: 'Barlow_400Regular',
                        }}
                      >
                        {item.label}
                      </Text>
                    </View>
                  );
                }}
              />
            </Col>
          )}

          <Col xs={12} sm={2}>
            <DoubleSidedSwitch
              rightLabel='Work Packages'
              leftLabel='Tools'
              value={myToolFilter}
              onValueChange={(active) => {
                setMyToolFilterAction(active);
              }}
            />
          </Col>
        </SRow>

        <Gap />

        <Card>
          {!myToolFilter && selectedItem.value !== 'RSVD' ? (
            <List.MyTools navigation={navigation} />
          ) : selectedItem.value == 'RSVD' ? (
            <List.Reservation navigation={navigation} />
          ) : (
            <List.MyWorkPackages navigation={navigation} />
          )}
        </Card>
      </Container>
    </Page.Protected>
  );
}
