import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Ionicons } from '@expo/vector-icons';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import DatePicker from '../DatePicker';
import moment from 'moment';

export default function WorkPackageReservationModal() {
  const {
    tools: { reservationCalendarVisible },
    workPackage: { workPackageReservationInput, reservationModal },
  } = useStoreState((state) => state);

  const {
    setReservationCalendarVisible,
    setWorkPackageReservationInputAction,
    reserveWorkPackageThunk,
    setWPReservationModalAction,
  } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      onClose={() => {
        setWPReservationModalAction(false);
      }}
      isVisible={reservationModal}
      title="Reservation"
      style={{ width: 500 }}
      width="50%"
      height="50%"
    >
      {reservationCalendarVisible && <DatePicker.WorkPackageReservation />}
      <Col>
        <Input
          placeholder="Type in Reservation Date"
          label="Reservation Date"
          value={
            workPackageReservationInput.WPreservationstartDate &&
            workPackageReservationInput.WPreservationendDate
              ? moment(
                  workPackageReservationInput.WPreservationstartDate
                ).format('MM/DD/YYYY') +
                ' - ' +
                moment(workPackageReservationInput.WPreservationendDate).format(
                  'MM/DD/YYYY'
                )
              : ''
          }
          onChangeText={(nextPMIDate) =>
            setWorkPackageReservationInputAction({
              reservationDate: nextPMIDate,
            })
          }
          onClick={() => {
            setReservationCalendarVisible(!reservationCalendarVisible);
          }}
          rightIcon={<Ionicons name="calendar" size={20} color="#00205b" />}
        />
      </Col>
      <Col>
        <Input
          placeholder="Type in Reservation Notes"
          label="Reservation Notes"
          value={workPackageReservationInput.WPreservationNotes}
          onChangeText={(WPreservationNotes) =>
            setWorkPackageReservationInputAction({ WPreservationNotes })
          }
        />
      </Col>

      <Col>
        <Input
          placeholder="Type in Reservation Work Order"
          label="Reservation Work Order"
          value={workPackageReservationInput.WPreservationworkOrder}
          onChangeText={(WPreservationworkOrder) =>
            setWorkPackageReservationInputAction({ WPreservationworkOrder })
          }
        />
      </Col>

      <Col>
        <Input
          placeholder="Type in Reservation Engine Number"
          label="Reservation Engine Number"
          value={workPackageReservationInput.WPreservationengineNumber}
          onChangeText={(WPreservationengineNumber) =>
            setWorkPackageReservationInputAction({ WPreservationengineNumber })
          }
        />
      </Col>

      <Button
        color="#00205b"
        fullWidth
        onPress={() => {
          reserveWorkPackageThunk({
            navigation,
            input: workPackageReservationInput,
          });
        }}
      >
        {'Reserve Work Package'}
      </Button>
    </CustomModal>
  );
}
