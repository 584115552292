const state = {
  readiness: {
    scoreCardInputs: {
      Safety: {
        id: null,
        type: 'Safety',
        totalscore: 0,
        categories: [
          {
            label: 'Tools in inventory have been verified not cancelled',
            value: 0,
          },
          { label: 'Cancelled tools in inventory', value: 0 },
          { label: 'Tools observed in good working condition', value: 0 },
          { label: 'Tools stored in a safe and ergonomic manner', value: 0 },
          { label: 'Tools used in proper manner', value: 0 },
          {
            label: 'No recordable injuries due to the above criteria',
            value: 0,
          },
          {
            label:
              'Are you aware of the process for opening an internal safety case? How do you do it?',
            value: 0,
          },
        ],
      },
      People: {
        id: null,
        type: 'People',
        totalscore: 0,
        categories: [
          {
            label: 'Training matrix completion for tooling',
            value: 0,
          },
          { label: 'Tooling familiarization training', value: 0 },
          { label: 'Simple tools', value: 0 },
          { label: 'Source controlled/electronic tools', value: 0 },
          { label: 'OEM designed tools', value: 0 },
          { label: 'Labor efficiency', value: 0 },
          {
            label: 'Are all TDS available for use on the shop floor?',
            value: 0,
          },
          {
            label: 'Do you know how to access your Technical Data Sheets?',
            value: 0,
          },
        ],
      },
      Quality: {
        id: null,
        type: 'Quality',
        totalscore: 0,
        categories: [
          {
            label: 'Status of tools in quarantine',
            value: 0,
          },
          { label: 'Paperwork storage', value: 0 },
          { label: 'Audit paperwork compliance', value: 0 },
          { label: 'Tools are in date for calibration & load test ', value: 0 },
          { label: 'Preventative maintenance program completion', value: 0 },
          { label: 'Preventative maintenance program effectiveness', value: 0 },
          {
            label: 'All tools returned in working order',
            value: 0,
          },
          {
            label:
              'How often do you experience work stopages due to routine tooling maintenance?',
            value: 0,
          },
        ],
      },
      Delivery: {
        id: null,
        type: 'Delivery',
        totalscore: 0,
        categories: [
          {
            label: 'Turn time - Gate 1 (YTD average)',
            value: 0,
          },
          { label: 'Turn time - Gate 3 (YTD average)', value: 0 },
          { label: 'Time to wait for tooling (Monthly average)', value: 0 },
          { label: 'Hours lost due to routine tool maintenance', value: 0 },
          { label: 'Hours lost due to non-routine tool maintenance', value: 0 },
          {
            label: 'Tooling support for workscope escalations and WIP',
            value: 0,
          },
          {
            label:
              'How often do you come across tools that you need or do not routinely have that would increase your turn time efficiency?',
            value: 0,
          },
        ],
      },
      Cost: {
        id: null,
        type: 'Cost',
        totalscore: 0,
        categories: [
          {
            label: 'Annual budget spend for tools',
            value: 0,
          },
          { label: 'AOG situations due to tooling - per quarter', value: 0 },
          { label: 'New tool  (Capital expenditures)', value: 0 },
          { label: 'Routine & non-routine maintenance (Shop spend)', value: 0 },
          { label: 'Excess storage and logistics costs', value: 0 },
          {
            label:
              'How often do you come across tools that need to be re-purchased due to disrepair or are lost?',
            value: 0,
          },
        ],
      },
    },
    scorecards: {},
    currentScorecards: {},
    scorecardHistory: {},
    loading: false,
    selectedType: 'Safety',
    showScoreCardModal: false,
    scorecardModalType: '',
  },
};

export { state as default };
