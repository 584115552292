import Page from '../containers/Page';
import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import Form from '../containers/Form';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Button from '../containers/Button';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function EditTenantPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    getTenantThunk,
    setIsEditableTenantAction,
    getMessagesThunk,
    setLoadingAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    const loadData = async () => {
      await setLoadingAction(true);
      await getTenantThunk(route.params.id);
      await setIsEditableTenantAction(false);
      await getMessagesThunk();
      await setLoadingAction(false);
    };
    if (isFocused) {
      loadData();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.EditTenant
          navigation={navigation}
          buttons={[
            <Button.EditTenant />,
            <Button.GoToTenants navigation={navigation}>
              Cancel
            </Button.GoToTenants>,
            <Button.SaveTenant
              navigation={navigation}
              message='Updated tenant'
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}

export default EditTenantPage;
