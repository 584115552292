import { axios } from ".";
import { convertJsonToQueryString } from "../utilities/general";
import { queryString } from "querystring";

const workPackageAxios = axios.create({
	// baseURL: `https://tmpbackenduat.azurewebsites.net/workpackage`,
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/workpackage`,
});

function getWorkPackageAPICall(queryString) {
	return workPackageAxios({
		method: "get",
		url: `/get?${convertJsonToQueryString(queryString)}`,
	});
}

function upsertWorkPackageAPICall(data) {
	return workPackageAxios({ method: "put", url: `/upsert`, data });
}

function getWorkPackageToolsAPICall(queryString) {
	return workPackageAxios({
		method: "get",
		url: `/tool/list/get?${convertJsonToQueryString(queryString)}`,
	});
}

function duplicateWorkPackageAPICall(data) {
	return workPackageAxios({ method: "post", url: `/duplicate`, data });
}
function upsertWorkPackageToolAPICall(data) {
	return workPackageAxios({ method: "put", url: `tool/list/upsert`, data });
}

function deleteWorkPackageAPICall(queryString) {
	return workPackageAxios({
		method: "delete",
		url: `/delete?${convertJsonToQueryString(queryString)}`,
	});
}

function checkoutWorkPackageAPICall(data) {
	return workPackageAxios({ method: "put", url: `/checkout`, data });
}
function checkinWorkPackageAPICall(data) {
	return workPackageAxios({ method: "put", url: `/checkin`, data });
}

function reserveWorkPackageAPICall(data) {
	return workPackageAxios({ method: "put", url: `/reservation/upsert`, data });
}

function precheckWorkPackageAPICall(queryString) {
	return workPackageAxios({
		method: "get",
		url: `/precheckout?${convertJsonToQueryString(queryString)}`,
	});
}
function copyWorkPackageToTenantAPICall(data) {
	return workPackageAxios({ method: "post", url: `/copytotenant`, data });
}

function copyPrecheckAPICall(queryString) {
	return workPackageAxios({
		method: "get",
		url: `/prechecktoolsexist?${convertJsonToQueryString(queryString)}`,
	});
}

function getWorkPackageToolsLocationReport(id) {
	return workPackageAxios({
		method: "get",
		url: `/toolslocation/${id}`,
	});
}

function getWorkPackageReservationsAPICall(queryString) {
	return workPackageAxios({
		method: "get",
		url: `/reservations?${convertJsonToQueryString(queryString)}`,
	});
}

function cancelWorkPackageReservationAPICall(data) {
	return workPackageAxios({ method: "put", url: `/reservation/cancel`, data });
}

export {
	workPackageAxios,
	getWorkPackageAPICall,
	upsertWorkPackageAPICall,
	getWorkPackageToolsAPICall,
	upsertWorkPackageToolAPICall,
	duplicateWorkPackageAPICall,
	deleteWorkPackageAPICall,
	checkoutWorkPackageAPICall,
	checkinWorkPackageAPICall,
	reserveWorkPackageAPICall,
	precheckWorkPackageAPICall,
	copyWorkPackageToTenantAPICall,
	copyPrecheckAPICall,
	getWorkPackageToolsLocationReport,
	getWorkPackageReservationsAPICall,
	cancelWorkPackageReservationAPICall,
};
