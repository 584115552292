import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import Card from '../../presentational/Card';
// import EntypoIcon from 'react-native-vector-icons/Entypo';
import Icon from '../../presentational/Icon';
import { Entypo } from '@expo/vector-icons';
import { remToDp } from '../../../utilities/general';
import Button from '../Button';
import SelectBox from 'react-native-multi-selectbox';
import { xorBy } from 'lodash';
import React from 'react';
import { View } from 'react-native';

export default function EditWorkPackageToolForm({ buttons, ...props }) {
  const {
    workPackageTools: { input, isEditable },
    workPackage,
    tools,
    document,
  } = useStoreState((state) => state);

  const { setWorkPackageToolsInputAction } = useStoreActions(
    (actions) => actions
  );

  const [packages, setPackages] = React.useState('');

  // function onSelectedItemsChange(item) {

  //   return (item) => {
  //     setWorkPackageToolsInputAction({
  //       toolpartNumber: xorBy(input.toolpartNumber, [item], 'id'),
  //     });
  //   };
  // }
  function onSelectedItemsChange(item) {
    return (item) => {
      if (!item) {
        console.warn('onSelectedItemsChange called with undefined item');
        return;
      }
      let currentIds = input.toolpartNumber
        ? input.toolpartNumber.split(',')
        : [];

      if (currentIds.includes(item.id.toString())) {
        currentIds = currentIds.filter((id) => id !== item.id.toString());
      } else {
        currentIds.push(item.id.toString());
      }
      let newToolPartNumber = currentIds.join(',');

      // Update the state
      setWorkPackageToolsInputAction({
        toolpartNumber: newToolPartNumber,
      });
      // setPackages(newToolPartNumber);
    };
  }

  // function getObjectsByIds(ids, objectList) {
  //   if (!ids || !objectList) return [];
  //   return objectList.filter((obj) => ids.includes(obj.id.toString()));
  // }

  function getObjectsByIds(ids, objectList) {
    if (!ids || !objectList) return [];

    // Ensure ids is an array of strings
    if (typeof ids === 'string') {
      ids = ids.split(',');
    }

    return objectList.filter((obj) => ids.includes(obj.id.toString()));
  }

  return (
    <Card>
      <Row {...props}>
        <Col>
          <Row>
            <Col xs={12} md={props.sideButtons ? 5 : 6}>
              <Input
                placeholder="Type in Work Package Name"
                label="Work Package Name"
                value={workPackage.input.name}
                onChangeText={(workpackageName) =>
                  setWorkPackageToolsInputAction({
                    workpackageName: workPackage.input.name,
                  })
                }
                required
                editable={false}
              />
            </Col>
            {/* <Col xs={12} md={props.sideButtons ? 5 : 6}>
              <Input
                placeholder="Type in Tool Part Number"
                label="Tool Part Number"
                value={input.toolpartNumber}
                onSelect={(toolpartNumber) =>
                  setWorkPackageToolsInputAction({
                    toolpartNumber: toolpartNumber.partNumber,
                  })
                }
                editable={isEditable}
                selectedItem={(item) => item.partNumber}
                rowTextForSelection={(item) => item.partNumber}
                dropdown
                dropdownChoices={tools.searchResults}
                width={'95%'}
                search={true}
                searchInputStyle={{
                  width: '100%',
                  alignSelf: 'center',
                  backgroundColor: '#F7F7F7',
                  border: 0,

                  boxShadow: '0px 0px 10px grey',
                  borderColor: '#F7F7F7',
                }}
                searchInputTxtStyle={{
                  fontFamily: 'Barlow_400Regular',
                  fontSize: 16,
                }}
                renderSearchInputRightIcon={() => (
                  <Icon Component={Entypo} name="magnifying-glass" />
                )}
                searchInputPlaceholder="Search for Tool Part Number"
              />
            </Col> */}
            <Col xs={12} md={props.sideButtons ? 5 : 6}>
              <View style={{ width: '100%' }}>
                <SelectBox
                  containerStyle={{
                    overflow: 'auto',
                    width: '100%',
                    top: 10,
                    left: 10,
                  }}
                  disabled={true}
                  placeholder="Type in Tool Part Number"
                  label="Tool Part Number"
                  labelStyle={{
                    fontFamily: 'Barlow_400Regular',
                    color: '#00205b',
                    fontSize: 16,
                  }}
                  options={tools.searchResults.map((item) => {
                    return { item: item.partNumber, id: item.id };
                  })}
                  selectedValues={tools.searchResults
                    .filter(
                      (item) =>
                        item.partNumber.toString() === input.toolpartNumber
                    )
                    .map((item) => {
                      return { item: item.partNumber, id: item.id };
                    })}
                  onMultiSelect={onSelectedItemsChange()}
                  multiOptionContainerStyle={{
                    backgroundColor: '#AB2328',
                    color: '#fff',
                  }}
                  multiOptionsLabelStyle={{
                    backgroundColor: '#AB2328',
                    fontFamily: 'Barlow_400Regular',
                    fontSize: 15,
                    color: '#fff',
                  }}
                  optionsLabelStyle={{
                    fontFamily: 'Barlow_400Regular',
                    color: '#AB2328',
                  }}
                  onTapClose={onSelectedItemsChange()}
                  isMulti
                />
                <View
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'transparent',
                    opacity: 0.5, // You can adjust this value to make it more or less transparent
                  }}
                  pointerEvents="box-only"
                />
              </View>
            </Col>
            {props.sideButtons && (
              <Col xs={12} md={2}>
                <Button.RemoveWorkPackageTool navigation={props.navigation} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
