import { axios } from '.';

const documentAxios = axios.create({
  // baseURL: `https://tmpbackenduat.azurewebsites.net/document`,
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/document`,
});

function getDocumentAPICall({ id, tenantId, toolid }) {
  return documentAxios({
    method: 'get',
    url: `/get?id=${id}&tenantId=${tenantId}&toolid=${toolid || null}`,
  });
}

function loadDocumentAPICall({ id, tenantId, toolid, type }) {
  let url = `/load?tenantId=${tenantId}`;
  if (id) url += `&id=${id}`;
  if (toolid) url += `&toolid=${toolid}`;
  if (type) url += `&type=${type}`;
  return documentAxios({
    method: 'get',
    url,
  });
}

function upsertDocumentAPICall(data) {
  return documentAxios({ method: 'put', url: '/upsert', data });
}

function deleteDocumentAPICall(data) {
  return documentAxios({ method: 'delete', url: '/delete', data });
}

export {
  documentAxios,
  getDocumentAPICall,
  loadDocumentAPICall,
  upsertDocumentAPICall,
  deleteDocumentAPICall,
};
