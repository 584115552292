import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { ActionSheetIOS, Platform } from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';

export default function UploadBrokenToolImageButton({
  children = 'Upload Image',
  ...props
}) {
  const { tools, tenant } = useStoreState((state) => state);

  const { setDocumentAction } = useStoreActions((actions) => actions);

  const handleButtonPress = () => {
    if (Platform.OS == 'ios') {
      ActionSheetIOS.showActionSheetWithOptions(
        {
          options: ['Cancel', 'Take Photo', 'Pick from Library'],
          cancelButtonIndex: 0,
        },
        (buttonIndex) => {
          if (buttonIndex === 1) {
            takePhoto();
          } else if (buttonIndex === 2) {
            pickImage();
          }
        }
      );
    }
  };

  const takePhoto = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();

    if (status !== 'granted') {
      Alert.alert('Sorry, we need camera roll permissions to make this work!');
      return;
    }

    let result = await ImagePicker.launchCameraAsync({
      base64: true,
    });

    if (!result.canceled) {
      const resizedPhoto = await ImageManipulator.manipulateAsync(
        result.assets[0].uri,
        [{ resize: { width: 1024, height: 768 } }],
        { base64: true, format: ImageManipulator.SaveFormat.JPEG }
      );

      console.log(result);

      setDocumentAction({
        notes: result.assets[0].fileName,
        tenantId: tenant.input.id,
        mimeType: result.assets[0].type,
        type: 'broken',
        body: 'data:image/jpeg;base64,' + resizedPhoto.base64,
      });
    }
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      base64: true,
    });

    if (!result.canceled) {
      const resizedImage = await ImageManipulator.manipulateAsync(
        result.assets[0].uri,
        [{ resize: { width: 1024, height: 768 } }],
        { base64: true, format: ImageManipulator.SaveFormat.JPEG }
      );

      setDocumentAction({
        notes: result.assets[0].fileName,
        tenantId: tenant.input.id,
        mimeType: result.assets[0].type,
        type: 'broken',
        body: 'data:image/jpeg;base64,' + resizedImage.base64,
      });
    }
  };

  return (
    <Button
      onPress={async () => {
        if (Platform.OS == 'web') {
          const document = await DocumentPicker.getDocumentAsync({
            type: 'image/*',
          });
          setDocumentAction({
            notes: document.name,
            tenantId: tenant.input.id,
            mimeType: document.mimeType,
            type: 'broken',
            body: document.uri,
          });
        } else {
          handleButtonPress();
        }
      }}
      // disabled={!tools.isEditable}
      disabled={false}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
