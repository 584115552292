import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function SaveInspectionTypeButton({
  navigation,
  children = 'Save',
  message,
  ...props
}) {
  const {
    inspectionTypes: { isEditable, input },
  } = useStoreState((state) => state);

  const { upsertInspectionThunk } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() =>
        upsertInspectionThunk({
          navigation,
          input: {
            ...input,
          },
          message,
        })
      }
      disabled={!isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
