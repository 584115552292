import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function ToolStatusList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.toolStatus,
    searchResults: state.toolStatus.searchResults.filter((tool) =>
      formatStringForSearch(tool.statusCode || tool.statusDescription).includes(
        formatStringForSearch(state.toolStatus.searchInput)
      )
    ),
  }));

  const { setToolStatusInputAction } = useStoreActions((actions) => actions);
  return (
    <List
      tableHead={['Status Code', 'Status Description', 'Edit']}
      tableRows={searchResults.map((tool) => [
        tool.statusCode,
        tool.statusDescription,
        <TouchableIcon
          Component={FontAwesome5}
          name="edit"
          size={20}
          onPress={() => {
            setToolStatusInputAction(tool);
            navigation.navigate('Edit Tool Status');
          }}
        />,
      ])}
    />
  );
}
