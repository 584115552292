import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import * as Network from 'expo-network';

function LoginButton({ children, ...props }) {
  const loginInput = useStoreState((state) => state.auth.loginInput);
  const { loginThunk } = useStoreActions((actions) => actions);
  return (
    <Button fullWidth onPress={async () => loginThunk(loginInput)} {...props}>
      {children}
    </Button>
  );
}

export default LoginButton;
