import React from 'react';
import SelectDropdown from 'react-native-select-dropdown';
import styled from 'styled-components/native';
import Text from './Text';
import { Input as RNInput } from '@rneui/themed';
import { MaterialIcons } from '@expo/vector-icons';
import { View, StyleSheet, Platform } from 'react-native';

const SInput = styled(RNInput)`
  background-color: ${(props) => (props.editable ? 'none' : 'lightgray')};
  padding: 0 10px;
  font-family: Barlow_400Regular;
  width: 100%;
  color: #00205b;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  background-color: ${(props) => (props.editable ? 'none' : 'lightgray')};

  padding: 0 10px;
  font-family: Barlow_400Regular;
  width: 100%;
  color: #00205b;
`;

const Label = styled(Text)`
  color: #00205b;
`;

const RequiredLabel = styled(Label)`
  color: #ab2328;
`;

const SearchIcon = styled(MaterialIcons).attrs({
  name: 'search',
  size: 20,
  color: '#00205b',
  alignSelf: 'center',
})`
  margin-left: 10px;
`;

const styles = StyleSheet.create({
  dropdownButtonStyle: {
    fontFamily: 'Barlow_400Regular',
    flexDirection: 'row',
    alignSelf: 'center',
    alignContent: 'flex-start',
    alignText: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#00205b',
    borderBottomWidth: Platform.OS == 'web' ? 0.1 : 0.3,
    paddingLeft: 0,
    borderColor: '#00205b',
    marginBottom: Platform.OS == 'web' ? 10 : 25,
    height: 41,
  },
  dropdownButtonTxtStyle: {
    fontFamily: 'Barlow_400Regular',
    fontSize: 17,
    flex: 1,
    flexDirection: 'row',
    textAlign: 'left',
    alignText: 'left',
    padding: Platform.OS == 'web' ? 10 : 0,
    alignSelf: 'center',
  },
  dropdownMenuStyle: {
    backgroundColor: '#d3d3d3',
  },
  dropdownItemStyle: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
    borderBottomWidth: Platform.OS == 'web' ? 0.1 : 0.3,
    borderBottomColor: '#c5c5c5',
  },
  dropdownItemTxtStyle: {
    flex: 1,
    color: '#00205b',
    fontFamily: 'Barlow_400Regular',
  },
  dropdownSearchInputStyle: {
    backgroundColor: '#d3d3d3',
    borderRadius: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#B1BDC8',
  },
});

function Input({
  value,
  label,
  required = false,
  dropdown = false,
  dropdownChoices,
  rowTextForSelection,
  selectedItem,
  onSelect,
  width = '98%',
  filter = false,
  ...props
}) {
  return (
    <>
      {label && (
        <Label>
          {label} {required && <RequiredLabel>*</RequiredLabel>}
        </Label>
      )}
      {dropdown ? (
        <StyledSelectDropdown
          key={value}
          {...props}
          disabled={!props.editable}
          value={value}
          selectedItem={value}
          data={dropdownChoices}
          onSelect={(value) => onSelect(value)}
          dropdownStyle={styles.dropdownMenuStyle}
          search
          searchInputStyle={styles.dropdownSearchInputStyle}
          searchInputTxtColor={'#00205b'}
          searchPlaceHolder={'Search here'}
          searchPlaceHolderColor={'#72808D'}
          renderSearchInputLeftIcon={() => {
            return <SearchIcon />;
          }}
          renderButton={(item, isOpened) => {
            return (
              <View
                style={{
                  ...styles.dropdownButtonStyle,
                  width: width,
                  backgroundColor:
                    props.editable && props.editable == true
                      ? 'none'
                      : 'lightgray',
                }}
              >
                <Text
                  style={{
                    ...styles.dropdownButtonTxtStyle,
                    color: value == '' ? 'gray' : '#00205b',
                  }}
                >
                  {value || 'Select'}
                </Text>
                <SearchIcon />
              </View>
            );
          }}
          renderItem={(item, index, isSelected) => {
            return (
              <View
                style={{
                  ...styles.dropdownItemStyle,
                  ...(isSelected && { backgroundColor: '#D2D9DF' }),
                }}
              >
                <Text style={styles.dropdownItemTxtStyle}>
                  {rowTextForSelection(item)}
                </Text>
              </View>
            );
          }}
        />
      ) : (
        <SInput
          value={value || ''}
          placeholderTextColor='#76787A'
          editable={true}
          {...props}
          rightIconContainerStyle={{ position: 'absolute', right: 0 }}
          rightIcon={props.rightIcon ? props.rightIcon : null}
        />
      )}
    </>
  );
}

export default Input;
