import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function SaveLabelButton({
  navigation,
  children = 'Save',
  message,
  ...props
}) {
  const {
    labels: { isEditable, input },
    tenant,
  } = useStoreState((state) => state);

  const { upsertLabelThunk } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() =>
        upsertLabelThunk({
          navigation,
          input: {
            ...input,
          },
          message,
        })
      }
      disabled={!isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
