const defaultInputState = {
	id: null,
	name: "",
	billingReference: "",
	address1: "",
	address2: "",
	address3: "",
	city: "",
	stateProvince: "",
	country: "",
	rhinestahlEmailContact: "",
	qualitycontrolEmailContact: "",
	zipPostal: "",
	active: true,
};

const state = {
	tenant: {
		searchResults: [],
		searchInput: "",
		isEditable: false,
		input: defaultInputState,
		labels: [],
		labelSearchInput: "",
	},
};

export { state as default, defaultInputState };
