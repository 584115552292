import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

export default function OEMStatusSearchBar() {
  const { oemSearchInput } = useStoreState((state) => state.tools);
  const { setOEMSearchInputAction } = useStoreActions((actions) => actions);

  function clearSearchInput() {
    return () => setOEMSearchInputAction('');
  }

  return (
    <SearchBar
      value={oemSearchInput}
      onChangeText={(newVal) => setOEMSearchInputAction(newVal)}
      placeholder="Search tool status by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}
