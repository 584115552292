import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import Gap from '../../presentational/Gap';

import Row from '../../presentational/Row';
import { Platform } from 'react-native';
import Switch from '../../presentational/Switch';

export default function UpsertVariantModal() {
  const {
    engines: { showVariantModal, variantInput, editIndex },
  } = useStoreState((state) => state);

  const { setShowVariantModalAction, setVariantInputAction, addVariant } =
    useStoreActions((actions) => actions);

  return (
    <CustomModal
      onClose={() => {
        setShowVariantModalAction(false);
      }}
      isVisible={showVariantModal}
      title='Add Variant'
      style={{ width: 800 }}
      width='80%'
      height={Platform.OS == 'web' ? '50%' : '64%'}
    >
      <Row>
        <Col>
          <Input
            placeholder='Engine Variant'
            label='Engine Variant'
            value={variantInput?.EngineVariant}
            onChangeText={(name) =>
              setVariantInputAction({
                input: { ...variantInput, EngineVariant: name },
                index: editIndex,
              })
            }
            required
          />
        </Col>
        <Col>
          <Switch
            label='Is Military'
            value={variantInput.ismilitaryFlag}
            onValueChange={(active) =>
              setVariantInputAction({
                input: { ...variantInput, ismilitaryFlag: active },
                index: editIndex,
              })
            }
            color='#2089dc'
          />
        </Col>
      </Row>
      <Gap />
      <Button
        fullWidth
        disabled={!variantInput.EngineVariant}
        onPress={async () => {
          addVariant();
        }}
      >
        Add
      </Button>
    </CustomModal>
  );
}
