import { axios } from ".";

const tenantAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/tenant`,
});

function getTenantsAPICall(id) {
	return tenantAxios({ method: "get", url: `/list/get/${id}` });
}

function getTenantAPICall(id) {
	return tenantAxios({ method: "get", url: `/get/${id}` });
}

function upsertTenantAPICall(data) {
	return tenantAxios({ method: "put", url: `/upsert`, data });
}

export {
	tenantAxios,
	getTenantsAPICall,
	getTenantAPICall,
	upsertTenantAPICall,
};
