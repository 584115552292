import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

function RolesSearchBar() {
  const { searchInput } = useStoreState((state) => state.role);
  const { setRoleSearchInputAction } = useStoreActions((actions) => actions);

  function clearSearchInput() {
    return () => setRoleSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setRoleSearchInputAction(newVal)}
      placeholder="Search roles by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}

export default RolesSearchBar;
