import styled from 'rn-css';
import Text from '../Text';
import { remToDp } from '../../../utilities/general';

const DrawerTab = styled(Text)`
  font-family: Barlow_600SemiBold;
  font-size: ${remToDp(1)};
  padding: 12px;

  &:hover {
    background-color: #ededed;
  }
`;

export default DrawerTab;
