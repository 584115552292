import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useIsFocused } from '@react-navigation/native';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';
import EditableJsonTree from '../containers/EditableJSONTree';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditLabelPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();
  const { tenant } = useStoreState((state) => state);
  const { getLabelThunk, setIsEditableLabelAction } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    if (isFocused) {
      getLabelThunk(tenant.input.id);
      setIsEditableLabelAction(false);
    }
  }, [isFocused]);
  return (
    <Page.Protected {...props}>
      <Container>
        <Form.EditLabel
          navigation={navigation}
          buttons={[
            <Button.GoToLabels navigation={navigation}>
              Cancel
            </Button.GoToLabels>,
            <Button.EditLabel navigation={navigation} />,
            <Button.SaveLabel
              navigation={navigation}
              message="Added Label"
              disabled={false}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
