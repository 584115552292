import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";

function LocationSearchBar() {
  const { searchInput } = useStoreState((state) => state.location);
  const { setLocationSearchInputAction } = useStoreActions((actions) => actions);

  function clearSearchInput() {
    return () => setLocationSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setLocationSearchInputAction(newVal)}
      placeholder="Search locations by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}

export default LocationSearchBar;
