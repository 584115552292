import UniversalStepForm from "../../presentational/UniversalStepForm";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function InspectionStepForm({ ...props }) {
	const {
		inspectionItems,
		inspectionTypes,
		tools: { inspectionResultInput },
		auth: { user },
	} = useStoreState((state) => state);
	const {
		upsertToolInspectionResultThunk,
		getToolsThunk,
		getWorkPackageThunk,
		setAlertThunk,
		setShowInspectionQuestionsModalAction,
		setCurrentStep,
		setCurrentSubStep,
		completeToolInspectionThunk,
		filterToolsThunk,
	} = useStoreActions((actions) => actions);

	return (
		<UniversalStepForm
			handleNext={() => {
				upsertToolInspectionResultThunk({
					input: inspectionResultInput,
				});
			}}
			handleSubmit={async () => {
				setShowInspectionQuestionsModalAction(false);

				await upsertToolInspectionResultThunk({
					input: inspectionResultInput,
				});
				completeToolInspectionThunk();
				filterToolsThunk("");
				getToolsThunk({ id: 0, tenantId: user.tenantId });
				getWorkPackageThunk({ id: null, tenantId: user.tenantId });

				setCurrentStep(1);
				setCurrentSubStep(1);

				setAlertThunk({
					type: "success",
					message: "Inspection Complete!",
				});
			}}
			types={inspectionTypes.searchResults}
			items={inspectionItems.searchResults}
			steps={inspectionItems.searchResults}
		/>
	);
}
