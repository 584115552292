import List from '../../presentational/List';
import { useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

function UsersList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.user,
    searchResults: state.user.searchResults.filter((user) =>
      formatStringForSearch(user.firstName || user.lastName).includes(
        formatStringForSearch(state.user.searchInput)
      )
    ),
  }));

  return (
    <List
      tableHead={['User', 'Email', 'Status', 'Role', 'Edit']}
      tableRows={searchResults.map((user) => [
        `${user.firstName} ${user.lastName}`,
        user.email,
        user.active ? 'Active' : 'Inactive',
        user.role,
        <TouchableIcon
          Component={FontAwesome5}
          name="edit"
          size={20}
          onPress={() => navigation.navigate('Edit User', { id: user.id })}
        />,
      ])}
    />
  );
}

export default UsersList;
