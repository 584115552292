import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../Button';
import Input from '../../presentational/Input';
import Switch from '../../presentational/Switch';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import Card from '../../presentational/Card';
import Gap from '../../presentational/Gap';
import { remToDp } from '../../../utilities/general';

const ColSwitch = styled(Col)`
  padding-left: ${remToDp(1.5)}px;
`;

export default function ToolCategoryForm({ buttons, navigation, ...props }) {
  const { toolCategoryInput, toolCategoryIsEditable } = useStoreState(
    (state) => state.tools
  );

  const { setToolCategoryInputAction } = useStoreActions((actions) => actions);

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} lg={props.sideButtons ? 6 : 12}>
          <Col>
            <Input
              placeholder="Type in Category Code"
              label="Category Code"
              value={toolCategoryInput.categoryCode}
              onChangeText={(categoryCode) =>
                setToolCategoryInputAction({ categoryCode })
              }
              required
              editable={toolCategoryIsEditable}
            />
          </Col>

          <Col>
            <Input
              placeholder="Type in Category Description"
              label="Category Description"
              value={toolCategoryInput.categoryDescription}
              onChangeText={(categoryDescription) =>
                setToolCategoryInputAction({ categoryDescription })
              }
              required
              editable={toolCategoryIsEditable}
            />
          </Col>
        </Col>
      </Row>
      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
