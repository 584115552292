import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Ionicons } from '@expo/vector-icons';
import {
  Dimensions,
  Platform,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { FlatList } from 'react-native';
import Text from '../../presentational/Text';
import { Divider } from '@rneui/themed';
import Gap from '../../presentational/Gap';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import { useState } from 'react';
import Input from '../../presentational/Input';

const { width } = Dimensions.get('window');

export default function PrecheckWorkPackageToolsCheckoutModal() {
  const {
    auth: { user },
    tools: { myToolFilter, checkoutComment },
    workPackage,
    workPackage: {
      precheckWPToolModal,
      precheckWorkPackageTools,
      checkoutPackageList,
    },
  } = useStoreState((state) => state);

  const {
    setToolCheckOutModalAction,
    setReservationModalAction,
    setWorkOrderSerialModalAction,
    setWorkPackageWOSerialModalAction,
    setPrecheckWorkPackageToolModalVisibleAction,
    setWPReservationModalAction,
    setSelectedWPPrecheckItemAction,
    setPreCheckInfoWPModalAction,
    getDocumentThunk,
    resetDocumentAction,
    setCheckoutCommentAction,
  } = useStoreActions((actions) => actions);

  const [invalid, setInvalid] = useState(false);

  return (
    <CustomModal
      style={{ width: 800 }}
      width='50%'
      height='50%'
      isVisible={precheckWPToolModal}
      title={`Check-Out ${
        workPackage &&
        workPackage.searchResults &&
        checkoutPackageList &&
        checkoutPackageList.length > 0
          ? workPackage.searchResults.filter(
              (item) => item.id == checkoutPackageList
            )[0].name
          : ''
      }`}
      onClose={() => setPrecheckWorkPackageToolModalVisibleAction(false)}
    >
      <ScrollView
        style={{ maxHeight: 250 }}
        contentContainerStyle={{ maxHeight: 250 }}
      >
        <FlatList
          ListHeaderComponent={
            <Row>
              <Col xs={6} sm={6}>
                <Text style={{ fontSize: 17 }}>Part Number</Text>
              </Col>
              <Col xs={5} sm={5}>
                <Text style={{ fontSize: 17 }}>Location</Text>
              </Col>
              <Col xs={1} sm={1}></Col>
            </Row>
          }
          data={precheckWorkPackageTools}
          renderItem={({ item }, index) => {
            if (
              item.NotAllToolsAvailableFlag == true ||
              item.inspectionRequired == 'Y'
            ) {
              setInvalid(true);
            }

            return (
              <TouchableOpacity>
                <Row style={{ alignItems: 'center' }}>
                  <Col xs={6} sm={6}>
                    <Text style={{ fontSize: 17, color: '#ab2328' }}>
                      {item.partnumber} ({item.serialnumber})
                    </Text>
                  </Col>
                  <Col xs={5} sm={5}>
                    <Text style={{ fontSize: 17 }}>{item.storagelocation}</Text>
                  </Col>
                  <Col xs={1} sm={1}>
                    {(item.inspectionRequired &&
                      item.inspectionRequired == 'Y') ||
                    item.toolisavailable !== 'Y' ||
                    item.statusCode == 'CO' ? (
                      <TouchableOpacity
                        onPress={() => {
                          setSelectedWPPrecheckItemAction(item);

                          if (item && item.toolid) {
                            getDocumentThunk({
                              id: item.toolid,
                            });
                          } else {
                            resetDocumentAction();
                          }

                          setPreCheckInfoWPModalAction(true);
                        }}
                      >
                        <Ionicons
                          name='alert'
                          size={24}
                          color='#ab2328'
                          style={{ padding: 5 }}
                        />
                      </TouchableOpacity>
                    ) : (
                      <Ionicons
                        name='checkmark'
                        size={24}
                        color='green'
                        style={{ padding: 5 }}
                      />
                    )}
                  </Col>
                </Row>

                <Divider />
              </TouchableOpacity>
            );
          }}
        />
      </ScrollView>

      <Gap />

      <Row>
        <Col xs={12} sm={12}>
          <Input
            label='Comments'
            value={checkoutComment}
            onChangeText={(comment) => setCheckoutCommentAction(comment)}
            multiline
            style={{ height: Platform.OS == 'web' ? 150 : 25 }}
          />
        </Col>
      </Row>
      <Gap />

      <Text>
        I acknowledge that I am checking out these tools in good working order.
        I understand that it is my responsibility to use them safely and
        correctly. I am aware of the potential hazards associated with their
        operation, and I will take appropriate precautions to prevent any
        accidents or injuries. If there are any issues or malfunctions, I will
        immediately notify the relevant personnel. I agree to return the tools
        in the same condition as I received them and accept full responsibility
        for any damages or loss that may occur during my use. I understand that
        any consequences resulting from my negligence or misuse of the tools are
        my sole liability.
      </Text>
      <Gap />

      <Row>
        <Col xs={12} sm={6}>
          <Button
            color='#00205b'
            fullWidth
            onPress={() => {
              if (!myToolFilter) {
                setReservationModalAction(true);
                setToolCheckOutModalAction(false);
              } else {
                setWPReservationModalAction(true);
              }
            }}
          >
            {!myToolFilter ? 'Reserve Tool (s)' : 'Reserve Work Package'}
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            disabled={invalid}
            fullWidth
            onPress={() => {
              if (!myToolFilter) {
                setWorkOrderSerialModalAction(true);
              } else {
                setPrecheckWorkPackageToolModalVisibleAction(false);
                setWorkPackageWOSerialModalAction(true);
              }
            }}
          >
            {!myToolFilter ? 'Check-Out Tool (s)' : 'Check-Out Work Package'}
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}
