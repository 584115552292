import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function useOnPress(navigation) {

  return () => {
    navigation.navigate('Work Packages');
  };
}

export default function GoToWorkPackagesButton({
  navigation,
  children = "Work Packages",
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}
