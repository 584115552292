import React from 'react';
import Button from '../../presentational/Button';
import * as ImageManipulator from 'expo-image-manipulator';
import { useStoreActions, useStoreState } from 'easy-peasy';

const RotateToolImageButton = ({
  isEdit,
  getIndex,
  children = 'Rotate Image',
  ...props
}) => {
  const { documentsToAdd } = useStoreState((state) => state);
  const { setDocumentToAddListAction, setIsNewToolPhotoAction } =
    useStoreActions((actions) => actions);

  const handleButtonPress = async () => {
    if (documentsToAdd.length > 0) {
      const index = getIndex();
      const document = documentsToAdd[index || 0];
      const uri = document.body;
      const resizedImage = await ImageManipulator.manipulateAsync(uri, [
        { rotate: 90 },
      ]);
      documentsToAdd[index || 0] = {
        ...document,
        body: `data:image/jpeg;base64,${resizedImage.base64}`,
      };
      setDocumentToAddListAction([...documentsToAdd]);
    }

    setIsNewToolPhotoAction(true);
  };

  return (
    <Button onPress={handleButtonPress} disabled={isEdit} fullWidth {...props}>
      {children}
    </Button>
  );
};

export default RotateToolImageButton;
