import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

function EditToolButton({ children = 'Edit', ...props }) {
  const { isEditable } = useStoreState((state) => state.tools);

  const { setIsEditableToolsAction } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() => setIsEditableToolsAction(!isEditable)}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}

export default EditToolButton;
