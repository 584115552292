import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function StorageLocationsList({ navigation }) {
  const {
    location: { locationMap },
  } = useStoreState((state) => state);
  const { searchResults } = useStoreState((state) => ({
    ...state.storageLocation,
    searchResults: state.storageLocation.searchResults.filter((user) =>
      formatStringForSearch(user.name).includes(
        formatStringForSearch(state.storageLocation.searchInput)
      )
    ),
  }));

  const { setStorageLocationInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <List
      tableHead={['Location Name', 'Location Description', 'Facility', 'Edit']}
      tableRows={searchResults.map((location) => [
        location.name,
        location.description,
        locationMap[location.tenantlocationid]?.name,
        <TouchableIcon
          Component={FontAwesome5}
          name='edit'
          size={20}
          onPress={() => {
            setStorageLocationInputAction(location);
            navigation.navigate('Edit Storage Location', { id: location.id });
          }}
        />,
      ])}
    />
  );
}
