import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useIsFocused } from '@react-navigation/native';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function AddStorageLocationPage({
  route,
  navigation,
  ...props
}) {
  const isFocused = useIsFocused();

  const { name } = useStoreState((state) => state.storageLocation.input);
  const {
    setIsEditableStorageLocationAction,
    resetLocationInputAction,
    resetStorageLocationInputAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      resetStorageLocationInputAction();
      setIsEditableStorageLocationAction(true);
      resetLocationInputAction();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.StorageLocation
          navigation={navigation}
          buttons={[
            <Button.GoToStorageLocations navigation={navigation}>
              Cancel
            </Button.GoToStorageLocations>,
            <Button.SaveStorageLocation
              navigation={navigation}
              message='Added Location'
              disabled={!name}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
