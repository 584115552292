import { thunk } from 'easy-peasy';
import { getDropdownAPICall, upsertDropdownAPICall } from '../../api/dropdowns';

const thunks = {
  getDropdownThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getDropdownAPICall({
        controlId: null,
        tenantId: helpers.getState().tenant.input.id,
        componentId: null,
      });
      actions.setDropdownSearchResultsAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: 'Error',
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertDropdownThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await upsertDropdownAPICall({
          ...input,
          tenantId: helpers.getState().tenant.input.id,
        });
        actions.setAlertThunk({
          type: 'success',
          message,
        });
        navigation.goBack();
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
