const defaultState = {
  loginInput: {
    email: '',
    password: '',
    ipaddr: '',
  },
  user: {
    id: null,
    firstName: '',
    lastName: '',
    role: '',
    tenantId: null,
  },

  passwordChangeInput: {
    id: null,
    tenantId: null,
    password: '',
  },
  passwordChangeModal: false,
};

const state = {
  auth: defaultState,
};

export { state as default, defaultState };
