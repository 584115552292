import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setDocumentAction: action((state, document) => {
    state.document = {
      ...state.document,
      ...document,
    };
  }),
  resetDocumentAction: action((state) => {
    state.document = defaultInputState;
  }),

  setDocumentListAction: action((state, documents) => {
    state.documents = documents;
  }),

  setExtraDocumentListAction: action((state, documents) => {
    state.extraDocuments = documents;
  }),

  setExtraDocumentsToolIdAction: action((state, toolId) => {
    state.extraDocumentsToolId = toolId;
  }),

  showDocumentModalAction: action((state, showDocumentModal) => {
    state.status.showDocumentModal = showDocumentModal;
  }),

  setExtraDocumentAction: action((state, extraDocument) => {
    state.extraDocument = {
      ...state.extraDocument,
      ...extraDocument,
    };
  }),

  resetExtraDocumentAction: action((state) => {
    state.extraDocument = { ...defaultInputState, body: '' };
  }),

  setDocumentImageAction: action((state, image) => {
    state.docImage = image;
  }),
  setAreYouSureDeleteDocumentModalVisibleAction: action(
    (state, areYouSureDeleteDocumentModalVisible) => {
      state.areYouSureDeleteDocumentModalVisible =
        areYouSureDeleteDocumentModalVisible;
    }
  ),

  setRemoveDocumentInputAction: action((state, removeDocument) => {
    state.removeDocumentInput = {
      ...state.removeDocumentInput,
      ...removeDocument,
    };
  }),

  setDocumentLoadingAction: action((state, loading) => {
    state.documentLoading = loading;
  }),

  setDocumentToAddListAction: action((state, newDocuments) => {
    state.documentsToAdd = newDocuments.map((value, index) => {
      return { ...value, index };
    });
  }),

  addDocumentToAddListAction: action((state, newDocument) => {
    state.documentsToAdd.push(newDocument);
    state.documentsToAdd = state.documentsToAdd.map((value, index) => {
      return { ...value, index };
    });
  }),
};

export default actions;
