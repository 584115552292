import CalendarPicker from "react-native-calendar-picker";
import { Ionicons } from "@expo/vector-icons";

import { Dimensions, Platform } from "react-native";
import { Overlay } from "@rneui/themed";
import { vwToDp } from "../../utilities/general";

const { width } = Dimensions.get("window");

export default function DateTimePickerComponent({
	onChange,
	visible,
	onBackdropPress,
	...props
}) {
	return (
		<Overlay
			visible={true}
			onBackdropPress={onBackdropPress}
			overlayStyle={{
				alignContent: "center",
				backgroundColor: "#FFFFFF",
				maxWidth: vwToDp(70),
			}}
		>
			<CalendarPicker
				{...props}
				nextComponent={
					<Ionicons
						name="ios-arrow-forward"
						size={20}
						color="black"
						style={{
							right: width / 2.5,
							position: "absolute",
						}}
					/>
				}
				previousComponent={
					<Ionicons
						name="ios-arrow-back"
						size={20}
						color="black"
						style={{
							left: width / 2.5,
							position: "absolute",
						}}
					/>
				}
				startFromMonday={true}
				minDate={new Date(2018, 1, 1)}
				maxDate={new Date(2050, 6, 3)}
				weekdays={["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"]}
				months={[
					"January",
					"February",
					"March",
					"April",
					"May",
					"June",
					"July",
					"August",
					"September",
					"October",
					"November",
					"December",
				]}
				todayBackgroundColor="gray"
				selectedDayTextColor="#000000"
				scaleFactor={Platform.OS == "web" ? 1000 : 2000}
				textStyle={{
					color: "#000000",
					zIndex: 1000,
				}}
				onDateChange={onChange}
			/>
		</Overlay>
	);
}
