import React, { useState } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import Text from '../presentational/Text';
import Input from '../presentational/Input';
import Button from './Button';
import TouchableIcon from '../presentational/TouchableIcon';
import { FontAwesome5 } from '@expo/vector-icons';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: 'lightgrey',
    justifyContent: 'space-between',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  nestedContainer: {
    marginLeft: 20,
  },
  key: {
    fontWeight: 'bold',
    marginLeft: 10,
    fontSize: 16,
  },
  value: {
    color: 'blue',

    fontSize: 14,
  },
  brace: {
    color: 'grey',
  },
  addButton: {
    margin: 10,
  },
});

function JsonNode({ keyName, data, onChange }) {
  const [editableKey, setEditableKey] = useState(null);
  const [editableValue, setEditableValue] = useState(null);

  const handleAddItem = () => {
    if (Array.isArray(data)) {
      // Add empty string for new array item
      const newArray = [...data, ''];
      onChange(newArray);
    } else if (typeof data === 'object') {
      // Add empty key-value for new object item
      const newObject = { ...data, '': '' };
      onChange(newObject);
    }
  };

  if (typeof data === 'object' && data !== null) {
    return (
      <View style={styles.container}>
        {keyName !== undefined && <Text style={styles.key}>{keyName}: </Text>}
        <View style={styles.nestedContainer}>
          <Text style={styles.brace}>{'{'}</Text>
          {Object.entries(data).map(([key, value], index, array) => (
            <View key={key} style={styles.innerContainer}>
              <JsonNode
                keyName={key}
                data={value}
                onChange={(newValue) => {
                  data[key] = newValue;
                  onChange({ ...data });
                }}
              />
              {index < array.length - 1 && <Text>,</Text>}
            </View>
          ))}
          <TouchableIcon
            Component={FontAwesome5}
            name="plus"
            size={10}
            onPress={handleAddItem}
          />
          ,<Text style={styles.brace}>{'}'}</Text>
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.innerContainer}>
        <Text onPress={() => setEditableKey(keyName)} style={styles.key}>
          {editableKey === keyName ? '' : keyName}:{' '}
        </Text>
        {editableKey === keyName ? (
          <Input
            autoFocus
            onBlur={() => setEditableKey(null)}
            value={keyName}
            onChangeText={(newKey) => onChange({ [newKey]: data })}
          />
        ) : null}

        <Text
          onPress={() => setEditableValue(String(data))}
          style={styles.value}
        >
          {typeof editableValue === 'string' ? '' : String(data)}
        </Text>
        {typeof editableValue === 'string' ? (
          <Input
            autoFocus
            onBlur={() => setEditableValue(null)}
            value={String(data)}
            onChangeText={(value) => {
              if (value === 'true') onChange(true);
              else if (value === 'false') onChange(false);
              else if (!isNaN(value)) onChange(Number(value));
              else onChange(value);
            }}
          />
        ) : null}
      </View>
    );
  }
}

export default function EditableJsonTree({ jsonData, onChange }) {
  const [data, setData] = useState(jsonData);

  return (
    <View style={{ padding: 20 }}>
      <JsonNode
        data={data}
        onChange={(newData) => {
          setData(newData);
          onChange(newData);
        }}
      />
    </View>
  );
}
