import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from './Page';
import styled from 'styled-components/native';
import Input from '../../presentational/Input';
import Center from '../../presentational/Center';
import Card from '../../presentational/Card';
import Button from '../Button';
import Gap from '../../presentational/Gap';
import Text from '../../presentational/Text';
import Icon from '../../presentational/Icon';
import Image from '../../presentational/Image';
import { useIsFocused } from '@react-navigation/native';
import { useEffect } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import { vhToDp } from '../../../utilities/general';
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import CustomModal from '../Modal';

const SCard = styled(Card)`
  max-width: 600px;
`;

const Header = styled(Text)`
  font-size: 32px;
  text-align: center;
`;

function LoginPage() {
  const loginInput = useStoreState((state) => state.auth.loginInput);
  const { resetLoginInputAction, setLoginInputAction, loginThunk } =
    useStoreActions((actions) => actions);

  const isFocused = useIsFocused();

  useEffect(() => {
    isFocused && resetLoginInputAction();
  }, [isFocused]);

  function onLogin() {
    return () => loginThunk(loginInput);
  }

  return (
    <Page>
      <Center.Screen>
        <CustomModal.PasswordChange />
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SCard>
            <Image
              name={require('../../../../assets/favicon.png')}
              height={vhToDp(12)}
              style={{
                alignSelf: 'center',
                width: '90%',
                height: 80,
              }}
              resizeMode="contain"
              resizeMethod="contain"
              width="100%"
            />

            <Gap />

            <Header>Login</Header>

            <Gap />

            <Input
              onChangeText={(email) => setLoginInputAction({ email })}
              value={loginInput.email}
              placeholder="Type email"
              inputMode="email"
              leftIcon={
                <Icon
                  Component={MaterialCommunityIcons}
                  name="email"
                  size={20}
                />
              }
            />

            <Gap />

            <Input
              onChangeText={(password) => setLoginInputAction({ password })}
              value={loginInput.password}
              placeholder="Type password"
              secureTextEntry
              leftIcon={<Icon Component={FontAwesome5} name="lock" size={20} />}
              onSubmitEditing={onLogin()}
            />

            <Gap />

            <Button.Login>Login</Button.Login>
          </SCard>
        </KeyboardAvoidingView>
      </Center.Screen>
    </Page>
  );
}

export default LoginPage;
