import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { remToDp } from '../../../utilities/general';
import { CheckBox } from '@rneui/themed';
import TouchableIcon from '../../presentational/TouchableIcon';
import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity, View } from 'react-native';
import moment from 'moment';
import Text from '../../presentational/Text';

export default function MyToolsList({ navigation }) {
  const hasSearchText = (value, searchInput) =>
    value?.toString().toLowerCase().trim().includes(searchInput.toLowerCase());

  const setSearchResults = (searchResults, searchInput) => {
    const map = searchResults.reduce((map, item) => {
      if (!map[item.id]) {
        map[item.id] = item;
      }
      return map;
    }, {});

    const list = Object.values(map);
    return list
      .sort((a, b) => {
        if (a.storageLocation > b.storageLocation) {
          return 1;
        } else if (a.storageLocation < b.storageLocation) {
          return -1;
        }

        if (a.statusDescription < b.statusDescription) {
          return -1;
        } else if (a.statusDescription > b.statusDescription) {
          return 1;
        } else {
          return 0;
        }
      })
      .filter(
        (tool) =>
          hasSearchText(tool.partNumber, searchInput) ||
          hasSearchText(tool.nomenclature, searchInput) ||
          hasSearchText(tool.shopfloortoolid, searchInput) ||
          hasSearchText(tool.barcodescanid, searchInput) ||
          hasSearchText(tool.serialNumber, searchInput)
      );
  };

  const { searchResults, user, checkoutToolList, checkinToolList } =
    useStoreState((state) => ({
      ...state.auth,
      ...state.tools,

      searchResults: setSearchResults(
        state.tools.searchResults,
        state.tools.searchInput
      ),
    }));

  const {
    addToCheckoutToolListAction,
    addToCheckinToolListAction,
    getDocumentsThunk,
    addToReserveToolListAction,
    setToolsInputAction,
    setToolInformationModalVisibleAction,
  } = useStoreActions((actions) => actions);

  const isToolIdInList = (tool) => {
    if (typeof checkoutToolList === 'string' && checkoutToolList.length > 0) {
      const listArray = checkoutToolList.split(',');
      return listArray.filter((item) => item == tool.id).length > 0;
    } else if (
      typeof checkinToolList === 'string' &&
      checkinToolList.length > 0
    ) {
      const listArray = checkinToolList.split(',');
      return listArray.filter((item) => item == tool.id).length > 0;
    } else {
      return false;
    }
  };

  return (
    <List
      tableHead={[
        'Part Number',
        'Tool',
        'Serial',
        'Status',
        'Tool User',
        'Last Inspection',
        'Location',
        'Photo',
        user && user.role == 'admin'
          ? 'Edit'
          : user.role == 'customer admin'
          ? 'Edit'
          : user.role == 'Customer Admin'
          ? 'Edit'
          : 'Add',
      ]}
      tableRows={searchResults.map((tool) => [
        <TouchableOpacity
          onPress={() => {
            setToolsInputAction(tool);
            setToolInformationModalVisibleAction(true);
          }}
        >
          <Text
            style={{
              fontWeight: 'bold',
              color: '#0088CE',
            }}
          >
            {tool.partNumber}
          </Text>
        </TouchableOpacity>,
        tool.nomenclature,
        tool.serialNumber,
        tool.statusDescription,
        tool.tooluser,
        tool &&
        tool.lastinspectionDate &&
        moment(tool.lastinspectionDate).isValid()
          ? moment(tool.lastinspectionDate).format('MM/DD/YYYY')
          : 'Not Set',

        tool.storageLocation,
        <TouchableIcon
          Component={FontAwesome5}
          name='image'
          size={20}
          onPress={() => {
            getDocumentsThunk({
              id: tool.id,
            });
          }}
        />,

        <View style={{ flexDirection: 'row' }}>
          <CheckBox
            checked={isToolIdInList(tool)}
            checkedColor='#007AFF'
            onPress={() => {
              if (
                tool.statusDescription !== 'Available' &&
                tool.statusDescription == 'Checked Out'
              ) {
                addToCheckinToolListAction(tool);
              } else if (
                tool.statusDescription === 'Available' ||
                tool.statusDescription == 'Reserved' ||
                tool.statusDescription == 'Inspection'
              ) {
                addToReserveToolListAction(tool);
                addToCheckoutToolListAction(tool);
              }
            }}
            checkedIcon={
              <FontAwesome5
                name='check-square'
                size={remToDp(1.5)}
                color='#007AFF'
              />
            }
            uncheckedIcon={
              <FontAwesome5 name='square' size={remToDp(1.5)} color='#007AFF' />
            }
            containerStyle={{ backgroundColor: 'none' }}
          />
        </View>,
        ,
      ])}
    />
  );
}
