import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

function TenantsList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.tenant,
    searchResults: state.tenant.searchResults.filter((tenant) =>
      formatStringForSearch(tenant.name).includes(
        formatStringForSearch(state.tenant.searchInput)
      )
    ),
  }));

  const { sortCol, sortDir } = useStoreState((state) => state.status);
  const { setSortColAction, setSortDirAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <List
      sortDir={sortDir}
      sortCol={sortCol}
      setSortCol={(sortCol) => setSortColAction(sortCol)}
      setSortDir={(sortDir) => setSortDirAction(sortDir)}
      tableHead={['Tenant', 'Status', 'User Count', 'Location Count', 'Select']}
      tableRows={searchResults.map((tenant) => [
        tenant.name,
        tenant.active ? 'Active' : 'Inactive',
        tenant.userCnt,
        tenant.locationCnt,
        <TouchableIcon
          Component={FontAwesome5}
          name="angle-right"
          size={20}
          onPress={() => navigation.navigate('Edit Tenant', { id: tenant.id })}
        />,
      ])}
    />
  );
}

export default TenantsList;
