import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

export default function LabelsSearchBar() {
  const { labelSearchInput } = useStoreState((state) => state.tenant);
  const { setLabelSearchInputAction } = useStoreActions((actions) => actions);

  function clearSearchInput() {
    return () => setLabelSearchInputAction('');
  }

  return (
    <SearchBar
      value={labelSearchInput}
      onChangeText={(newVal) => setLabelSearchInputAction(newVal)}
      placeholder="Search labels by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}
