import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';

function useOnPress(navigation) {
  const { setWPAreYouSureModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  return () => {
    setWPAreYouSureModalVisibleAction(true);
  };
}

export default function DeleteWorkPackageButton({
  navigation,
  children = 'Delete Work Package',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props} color={'#ab2328'}>
      {children}
    </Button>
  );
}
