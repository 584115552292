import { useEffect, useState } from 'react';
import { AntDesign } from '@expo/vector-icons';
import Table from './Table';
import Button from './Button';
import { Platform, TouchableOpacity } from 'react-native';

function List({
  sortDir = 'asc',
  sortCol = null,
  tableHead = [],
  tableRows = [],
  striped = true,
  hover = true,
  border = true,
  pageSize = 10,
  ...props
}) {
  const [sortInfo, setSortInfo] = useState({ sortCol, sortDir });
  const [currentPage, setCurrentPage] = useState(0);
  const { setSortCol, setSortDir } = props;

  const handleSort = (colIndex) => {
    setCurrentPage(0);
    let newSortInfo = { sortCol: colIndex, sortDir: 'asc' };
    if (colIndex === sortInfo.sortCol) {
      newSortInfo.sortDir = sortInfo.sortDir === 'asc' ? 'desc' : 'asc';
    }
    setSortInfo(newSortInfo);
  };

  const handleShowMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const sortedRows = [...tableRows].sort((a, b) => {
    const { sortCol, sortDir } = sortInfo;
    if (sortCol === null) {
      return 0;
    }
    const aVal = a[sortCol];
    const bVal = b[sortCol];
    if (typeof aVal === 'number' && typeof bVal === 'number') {
      return sortDir === 'asc' ? aVal - bVal : bVal - aVal;
    } else {
      const sortVal = aVal?.toString().localeCompare(bVal?.toString());
      return sortDir === 'asc' ? sortVal : -sortVal;
    }
  });

  const paginatedRows = sortedRows.slice(0, (currentPage + 1) * pageSize);

  useEffect(() => {
    if (sortCol !== null) {
      setSortDir('asc');
    }
  }, [sortCol]);

  return (
    <>
      <Table {...props}>
        <Table.thead>
          <Table.tr fontFamily='Barlow_600SemiBold'>
            {tableHead.map((children, index) => (
              <Table.th
                key={index}
                border={border}
                index={index}
                width={tableHead.length ? `${100 / tableHead.length}%` : 'auto'}
                fontFamily='Barlow_600SemiBold'
              >
                {children}
                {index !== tableHead.length - 1 && Platform.OS == 'web' && (
                  <TouchableOpacity
                    type='button'
                    onPress={() => handleSort(index)}
                    aria-label={`Sort by ${children}`}
                  >
                    <AntDesign
                      name='caretdown'
                      size={16}
                      color='gray'
                      style={
                        Platform.OS == 'web' && {
                          transform:
                            sortInfo.sortCol === index
                              ? sortInfo.sortDir === 'desc'
                                ? 'rotate(180deg)'
                                : 'none'
                              : 'rotate(180deg)',
                        }
                      }
                    />
                  </TouchableOpacity>
                )}
              </Table.th>
            ))}
          </Table.tr>
        </Table.thead>
        <Table.tbody>
          {paginatedRows.map((row, index) => (
            <Table.tr
              key={index}
              striped={striped && index % 2 === 0}
              hover={hover}
            >
              {row.map((children, index) => (
                <Table.td
                  key={index}
                  border={border}
                  index={index}
                  width={
                    tableHead.length ? `${100 / tableHead.length}%` : 'auto'
                  }
                  color={
                    children === 'Inactive' ||
                    children === 'CO' ||
                    children === 'Unavailable' ||
                    children == 'Broken Tool' ||
                    children == 'Unserviceable' ||
                    children == 'Checked Out'
                      ? 'red'
                      : children === 'Active' ||
                        children === 'Available' ||
                        children === 'Configured'
                      ? 'green'
                      : children === 'Reserved' || children === 'Inspection'
                      ? '#c0b000'
                      : '#00205b'
                  }
                >
                  {children}
                </Table.td>
              ))}
            </Table.tr>
          ))}
        </Table.tbody>
      </Table>
      {tableRows.length > pageSize &&
        paginatedRows.length < tableRows.length && (
          <Button onPress={handleShowMore}>Show More</Button>
        )}
    </>
  );
}

export default List;
