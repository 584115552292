import List from "../../presentational/List";
import { useStoreState } from "easy-peasy";
import { formatStringForSearch } from "../../../utilities/general";
import { FontAwesome5 } from "@expo/vector-icons";
import TouchableIcon from "../../presentational/TouchableIcon";

export default function LabelList({ navigation }) {
	const { searchResults } = useStoreState((state) => ({
		...state.labels,
		searchResults: state.labels.searchResults.filter((user) =>
			formatStringForSearch(
				user.userlabel1 ||
					user.userlabel2 ||
					user.userlabel3 ||
					user.userlabel4 ||
					user.userlalel5 ||
					user.customerConfig ||
					user.cit
			).includes(formatStringForSearch(state.labels.searchInput))
		),
	}));

	return (
		<List
			tableHead={[
				"userLabel1",
				"userLabel2",
				"userLabel3",
				"userLabel4",
				"userLabel5",
				"customerConfig",
				"Edit",
			]}
			tableRows={searchResults.map((user) => [
				user.userlabel1,
				user.userlabel2,
				user.userlabel3,
				user.userlabel4,
				user.userlabel5,
				user.customerConfig,
				<TouchableIcon
					Component={FontAwesome5}
					name="edit"
					size={20}
					onPress={() => {
						navigation.navigate("Config", { id: user.id });
					}}
				/>,
			])}
		/>
	);
}
