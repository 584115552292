import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function SaveUserButton({
  navigation,
  children = 'Save',
  message,
  ...props
}) {
  const {
    user: { isEditable, input },
    tenant,
    document,
  } = useStoreState((state) => state);

  const { upsertUserThunk, upsertDocumentThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={async () => {
        await upsertDocumentThunk({ navigation, document, message });

        await upsertUserThunk({
          navigation,
          input: {
            ...input,
            tenantId: tenant.input.id,
          },
          message,
        });
      }}
      disabled={!isEditable}
      // disabled={confirmPassword !== input.password}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
