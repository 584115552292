import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function ToolsList({ navigation }) {
  const setSearchResults = (searchResults, searchInput) => {
    const map = searchResults.reduce((map, item) => {
      if (!map[item.id]) {
        map[item.id] = item;
      }
      return map;
    }, {});

    const list = Object.values(map);
    return list.filter((tool) =>
      formatStringForSearch(
        tool.partNumber ||
          tool.nomenclature ||
          tool.shopfloortoolid ||
          tool.barcodescanid
      ).includes(formatStringForSearch(searchInput))
    );
  };

  const {
    location: { locationMap },
  } = useStoreState((state) => state);
  const { searchResults } = useStoreState((state) => ({
    ...state.tools,
    searchResults: setSearchResults(
      state.tools.searchResults,
      state.tools.searchInput
    ),
  }));

  const { setToolsInputAction } = useStoreActions((actions) => actions);
  return (
    <List
      tableHead={[
        'Part Number',
        'Tool',
        'Serial',
        'Status',
        'Facility',
        'Storage Location',
        'Edit',
      ]}
      tableRows={searchResults.map((tool) => [
        tool.partNumber,
        tool.nomenclature,
        tool.serialNumber,
        tool.statusDescription,
        locationMap[tool.tenantLocation]?.name,
        tool.storageLocation,
        <TouchableIcon
          Component={FontAwesome5}
          name='edit'
          size={20}
          onPress={() => {
            setToolsInputAction(tool);
            navigation.navigate('Edit Tool', { id: tool.id });
          }}
        />,
      ])}
    />
  );
}
