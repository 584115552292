import { thunk } from 'easy-peasy';
import {
  completeToolInspectionAPICall,
  getInspectionItemAPICall,
  removeInspectionItemAPICall,
  upsertInspectionItemAPICall,
} from '../../api/inspection';

const thunks = {
  getInspectionItemsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const tenantResponse = await getInspectionItemAPICall({
        id: null,
        inspectionTypeId: parseInt(helpers.getState().inspectionTypes.input.id),
        tenantId: parseInt(helpers.getState().tenant.input.id),
      });
      actions.setInspectionItemsSearchResultsAction(tenantResponse.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertInspectionItemThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await upsertInspectionItemAPICall({
          ...input,
          tenantId: helpers.getState().tenant.input.id,
          inspectionTypeId: helpers.getState().inspectionTypes.input.id,
        });
        actions.setAlertThunk({
          type: 'success',
          message,
        });
        navigation.goBack();
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),

  removeInspectionItemThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);

    try {
      await removeInspectionItemAPICall({
        id: helpers.getState().inspectionItems.input.id,
        inspectionTypeId: helpers.getState().inspectionTypes.input.id,
        tenantID: helpers.getState().tenant.input.id,
      });

      const tenantResponse = await getInspectionItemAPICall({
        id: null,
        inspectionTypeId: parseInt(helpers.getState().inspectionTypes.input.id),
        tenantId: parseInt(helpers.getState().tenant.input.id),
      });
      actions.setInspectionItemsSearchResultsAction(tenantResponse.data);

      actions.setAlertThunk({
        type: 'success',
        message: 'Inspection Item Removed',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  completeToolInspectionThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);

    try {
      await completeToolInspectionAPICall({
        toolId:
          helpers.getState().workPackage.selectedWPPreCheck.toolid ||
          helpers.getState().tools.input.id,
        inspectiontypeId: helpers.getState().tools.inspectionTypeId,
        tenantId: helpers.getState().auth.user.tenantId,
      });
      actions.setInspectionTypeId(null);
      actions.setAlertThunk({
        type: 'success',
        message: 'Inspection Complete!',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
};

export default thunks;
