import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function EditTenantButton({ children = "Edit", ...props }) {
  const { isEditable } = useStoreState((state) => state.tenant);

  const { setIsEditableTenantAction } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() => setIsEditableTenantAction(true)}
      disabled={isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}

export default EditTenantButton;
