import { action } from "easy-peasy";
import {
	defaultInputState,
	duplicateInputState,
	workPackageInputState,
} from "./state";

const actions = {
	setWorkPackageSearchResultsAction: action((state, searchResults) => {
		state.workPackage.searchResults = searchResults;
	}),
	setWorkPackageSearchInputAction: action((state, searchInput) => {
		state.workPackage.searchInput = searchInput;
	}),
	setIsEditableWorkPackageAction: action((state, isEditable) => {
		state.workPackage.isEditable = isEditable;
	}),
	setWorkPackageInputAction: action((state, input) => {
		state.workPackage.input = {
			...state.workPackage.input,
			...input,
		};
	}),
	resetWorkPackageInputAction: action((state, input) => {
		state.workPackage.input = defaultInputState;
	}),

	setDuplicateWorkPackageInputAction: action((state, input) => {
		state.workPackage.duplicateInput = {
			...state.workPackage.duplicateInput,
			...input,
		};
	}),

	resetDuplicateWorkPackageInputAction: action((state, input) => {
		state.workPackage.duplicateInput = duplicateInputState;
	}),

	setFilterPackagesAction: action((state, filter) => {
		state.workPackage.filter = filter;
	}),

	addToCheckoutWorkPackageListAction: action((state, toolId) => {
		state.workPackage.checkinPackageList = [];
		const checkoutPackageList = state.workPackage.checkoutPackageList;
		if (checkoutPackageList !== toolId.id) {
			state.workPackage.checkoutPackageList = toolId.id;
		} else {
			state.workPackage.checkoutPackageList = "";
		}
	}),

	addToCheckInWorkPackageListAction: action((state, toolId) => {
		state.workPackage.checkoutPackageList = [];
		const checkoutPackageList = state.workPackage.checkinPackageList;
		if (checkoutPackageList !== toolId.id) {
			state.workPackage.checkinPackageList = toolId.id;
		} else {
			state.workPackage.checkinPackageList = "";
		}
	}),

	setCheckoutWorkPackageListAction: action((state, checkoutPackageList) => {
		state.workPackage.checkoutPackageList = checkoutPackageList;
	}),

	setCheckinWorkPackageListAction: action((state, checkinPackageList) => {
		state.workPackage.checkinPackageList = checkinPackageList;
	}),

	setWorkPackageCheckoutInputAction: action((state, input) => {
		state.workPackage.checkoutInput = {
			...state.workPackage.checkoutInput,
			...input,
		};
	}),

	setWorkPackageWOSerialModalAction: action((state, workOrderSerialModal) => {
		state.workPackage.workOrderSerialModal = workOrderSerialModal;
	}),

	resetWorkPackageReservationInputAction: action((state, input) => {
		state.workPackage.workPackageReservationInput = workPackageInputState;
	}),

	setWorkPackageReservationInputAction: action((state, input) => {
		state.workPackage.workPackageReservationInput = {
			...state.workPackage.workPackageReservationInput,
			...input,

			// ...input,
		};
	}),
	setWPAreYouSureModalVisibleAction: action(
		(state, wpAreYouSureModalVisible) => {
			state.workPackage.wpAreYouSureModalVisible = wpAreYouSureModalVisible;
		}
	),
	setPrecheckWorkPackageToolListAction: action((state, precheckToolList) => {
		state.workPackage.precheckWorkPackageTools = precheckToolList;
	}),
	setPrecheckWorkPackageToolModalVisibleAction: action(
		(state, precheckToolModal) => {
			state.workPackage.precheckWPToolModal = precheckToolModal;
		}
	),

	setWPReservationModalAction: action((state, reservationModal) => {
		state.workPackage.reservationModal = reservationModal;
	}),

	setPreCheckInfoWPModalAction: action((state, preCheckInfoWPModal) => {
		state.workPackage.preCheckInfoWPModal = preCheckInfoWPModal;
	}),

	setSelectedWPPrecheckItemAction: action((state, selectedToolPreCheck) => {
		state.workPackage.selectedWPPreCheck = selectedToolPreCheck;
	}),
	setCopyWorkPackageToTenantModalVisibleAction: action(
		(state, copyWorkPackageToTenantModalVisible) => {
			state.workPackage.copyWorkPackageToTenantModalVisible =
				copyWorkPackageToTenantModalVisible;
		}
	),

	setCopyWorkPackageInputAction: action((state, input) => {
		state.workPackage.copyWPToTenantInput = {
			...state.workPackage.copyWPToTenantInput,
			...input,
		};
	}),

	setCopyWorkPackageToTenantModalToolListVisibleAction: action(
		(state, copyWPToTenantToolList) => {
			state.workPackage.copyWPToTenantToolListModalVisible =
				copyWPToTenantToolList;
		}
	),

	setCopyWorkPackageToolListAction: action((state, copyWPToTenantToolList) => {
		state.workPackage.copyWPToTenantToolList = copyWPToTenantToolList;
	}),
	setWorkPackageReservationListAction: action(
		(state, workPackageReservationList) => {
			state.workPackage.workPackageReservationList = workPackageReservationList;
		}
	),

	setWorkPackageCancelInputAction: action((state, input) => {
		state.workPackage.cancelWorkPackageReservationInput = {
			...state.workPackage.cancelWorkPackageReservationInput,
			...input,
		};
	}),

	setCancelWorkPackageReservationModalVisibleAction: action(
		(state, cancelWorkPackageReservationModalVisible) => {
			state.workPackage.cancelWorkPackageReservationModalVisible =
				cancelWorkPackageReservationModalVisible;
		}
	),
};

export default actions;
