import styled from 'styled-components/native';
import Td from './Td';

const Th = styled(Td)`
  font-family: Barlow_600SemiBold;

  width: ${(props) => props.width || '20%'};
`;

export default Th;
