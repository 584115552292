import List from '../../presentational/List';
import { useStoreState, useStoreActions } from 'easy-peasy';
import moment from 'moment';
import TouchableIcon from '../../presentational/TouchableIcon';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function ReservationInformationList({ navigation }) {
  const { reservedTools } = useStoreState((state) => state.tools);
  const { setReservationInputAction, setAreYouSureModalVisibleAction } =
    useStoreActions((actions) => actions);

  return (
    <List
      tableHead={['Reserved By', 'Reservation Date', 'Cancel']}
      tableRows={reservedTools.map((tool) => [
        tool.reservedBy,
        moment(tool.reservationStartDate).format('MM/DD/YYYY') +
          ' - ' +
          moment(tool.reservationEndDate).format('MM/DD/YYYY'),
        <TouchableIcon
          Component={MaterialCommunityIcons}
          name="cancel"
          color="#ab2328"
          size={20}
          onPress={() => {
            setReservationInputAction({ id: tool.id });
            setAreYouSureModalVisibleAction(true);
          }}
        />,
      ])}
    />
  );
}
