// import { thunk } from 'easy-peasy';
// import { getLabelAPICall, upsertLabelAPICall } from '../../api/labels';

// const thunks = {
//   getLabelThunk: thunk(async (actions, id, helpers) => {
//     actions.setLoadingAction(true);

//     try {
//       const tenantResponse = await getLabelAPICall(
//         helpers.getState().tenant.input.id
//       );
//       actions.setLabelSearchResultsAction(tenantResponse.data);
//       const values = {};
//       if (tenantResponse.data && tenantResponse.data.length > 0) {
//         values.userlabel1 = tenantResponse.data[0].userlabel1 || '';
//         values.userlabel2 = tenantResponse.data[0].userlabel2 || '';
//         values.userlabel3 = tenantResponse.data[0].userlabel3 || '';
//         values.userlabel4 = tenantResponse.data[0].userlabel4 || '';
//         values.userlabel5 = tenantResponse.data[0].userlabel5 || '';
//         values.customerConfig =
//           JSON.parse(tenantResponse.data[0].customerConfig) || '';
//       }

//       actions.setLabelInputAction(values || {});
//     } catch (err) {
//       console.log(err);
//       actions.setAlertThunk({
//         type: 'error',
//         message: err.response.data,
//       });
//     }

//     actions.setLoadingAction(false);
//   }),

//   upsertLabelThunk: thunk(
//     async (actions, { navigation, input, message }, helpers) => {
//       actions.setLoadingAction(true);

//       try {
//         await upsertLabelAPICall({
//           ...input,
//           tenantId: helpers.getState().tenant.input.id,
//         });
//         actions.setAlertThunk({
//           type: 'success',
//           message,
//         });
//         navigation.goBack();
//       } catch (err) {
//         actions.setAlertThunk({
//           type: 'error',
//           message: err.response.data,
//         });
//       }

//       actions.setLoadingAction(false);
//     }
//   ),
// };

// export default thunks;

import { thunk } from 'easy-peasy';
import { getLabelAPICall, upsertLabelAPICall } from '../../api/labels';

const thunks = {
  getLabelThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    try {
      const tenantResponse = await getLabelAPICall(
        helpers.getState().tenant.input.id
      );
      actions.setLabelSearchResultsAction(tenantResponse.data);
      const values = {};
      if (tenantResponse.data && tenantResponse.data.length > 0) {
        values.userlabel1 = tenantResponse.data[0].userlabel1 || '';
        values.userlabel2 = tenantResponse.data[0].userlabel2 || '';
        values.userlabel3 = tenantResponse.data[0].userlabel3 || '';
        values.userlabel4 = tenantResponse.data[0].userlabel4 || '';
        values.userlabel5 = tenantResponse.data[0].userlabel5 || '';
        values.customerConfig = tenantResponse.data[0].customerConfig || '';
      }

      actions.setLabelInputAction(values || {});
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertLabelThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await upsertLabelAPICall({
          ...input,
          tenantId: helpers.getState().tenant.input.id,
        });
        actions.setAlertThunk({
          type: 'success',
          message,
        });
        navigation.goBack();
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
