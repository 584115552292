const defaultInputState = {
  userlabel1: '',
  userlabel2: '',
  userlabel3: '',
  userlabel4: '',
  userlabel5: '',
  customerConfig: '',
};

const state = {
  labels: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
  },
};

export { state as default, defaultInputState };
