import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import { Ionicons } from '@expo/vector-icons';
import { Dimensions, View } from 'react-native';
import { FlatList } from 'react-native';
import Text from '../../presentational/Text';
import { Divider } from '@rneui/themed';
import Gap from '../../presentational/Gap';
import { useState } from 'react';

const { width } = Dimensions.get('window');

export default function CopyWPToTenantListModal() {
  const {
    workPackage: { copyWPToTenantToolList, copyWPToTenantToolListModalVisible },
  } = useStoreState((state) => state);

  const { setCopyWorkPackageToTenantModalToolListVisibleAction } =
    useStoreActions((actions) => actions);

  const [invalid, setInvalid] = useState(false);

  return (
    <CustomModal
      style={{ width: 800 }}
      width="50%"
      height="50%"
      isVisible={copyWPToTenantToolListModalVisible}
      title={`Not All Tools Available`}
      onClose={() =>
        setCopyWorkPackageToTenantModalToolListVisibleAction(false)
      }
    >
      <FlatList
        data={copyWPToTenantToolList}
        renderItem={({ item }, index) => {
          return (
            <>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    color: item.AllPartsExist == 'true' ? 'green' : '#ab2328',
                  }}
                >
                  {item.partNumber}
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {item.AllPartsExist !== 'true' ? (
                    <Ionicons
                      name="alert"
                      size={24}
                      color="#ab2328"
                      style={{ padding: 5 }}
                    />
                  ) : (
                    <Ionicons
                      name="checkmark"
                      size={24}
                      color="green"
                      style={{ padding: 5 }}
                    />
                  )}
                </View>
              </View>
              <Divider />
            </>
          );
        }}
      />
      <Gap />
      <Text>
        Before proceeding, ensure all required tools are present in the Selected
        Tenant. It is MANDATORY to add any missing tool in order to successfully
        copy the package.
      </Text>
    </CustomModal>
  );
}
