import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import Button from '../Button';
import Text from '../../presentational/Text';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';

export default function AreYouSureWorkPackageModal({ navigation }) {
  const { wpAreYouSureModalVisible } = useStoreState(
    (state) => state.workPackage
  );

  const { setWPAreYouSureModalVisibleAction, deleteWorkPackageThunk } =
    useStoreActions((actions) => actions);

  const handleOnSubmit = () => {
    deleteWorkPackageThunk({ navigation });
    setWPAreYouSureModalVisibleAction(false);
  };

  return (
    <CustomModal
      style={{ width: 500 }}
      width="50%"
      height="50%"
      title="Confirmation"
      onClose={() => setWPAreYouSureModalVisibleAction(false)}
      isVisible={wpAreYouSureModalVisible}
    >
      <Text style={{ fontSize: 20, fontWeight: 'bold', alignSelf: 'center' }}>
        {'Are you sure you want to remove this Work Package?'}
      </Text>
      <Row>
        <Col xs={12} sm={6}>
          <Button
            fullWidth
            style={{ marginTop: 20 }}
            onPress={() => {
              setWPAreYouSureModalVisibleAction(false);
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            color="#ab2328"
            fullWidth
            style={{ marginTop: 20 }}
            onPress={handleOnSubmit}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}
