const defaultInputState = {
  id: null,
  name: '',
  address1: '',
  address2: '',
  address3: '',
  stateProvince: '',
  country: '',
  city: '',
  zipPostal: '',
  active: true,
};

const state = {
  location: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
    locationMap: {},
  },
};

export { state as default, defaultInputState };
