import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';

export default function WorkPackageSerialModal({ navigation, ...props }) {
  const {
    auth: { user },
    tools: { myToolFilter },
    workPackage,
  } = useStoreState((state) => state);

  const {
    getWorkPackageThunk,
    setToolCheckOutModalAction,
    checkoutWorkPackageThunk,
    setWorkOrderSerialModalAction,
    checkoutToolThunk,
    getToolsThunk,
    setWorkPackageCheckoutInputAction,
    setWorkPackageWOSerialModalAction,
    upsertToolUsageThunk,
  } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      onClose={() => {
        setWorkPackageWOSerialModalAction(false);
      }}
      isVisible={workPackage.workOrderSerialModal}
      title='Work Order / Serial Number'
      style={{ width: 500 }}
      width='50%'
      height='50%'
    >
      <Col>
        <Input
          placeholder='Type in Work Order'
          label='Work Order'
          value={workPackage.checkoutInput.workOrder}
          onChangeText={(workOrder) =>
            setWorkPackageCheckoutInputAction({ workOrder })
          }
        />
      </Col>
      <Col>
        <Input
          placeholder='Type in Engine Serial Number / Aircraft'
          label='Engine Serial Number / Aircraft'
          value={workPackage.checkoutInput.engineserialNumber}
          onChangeText={(engineserialNumber) =>
            setWorkPackageCheckoutInputAction({ engineserialNumber })
          }
        />
      </Col>

      <Button
        fullWidth
        onPress={async () => {
          if (!myToolFilter) {
            checkoutToolThunk({
              navigation,
            });
            getToolsThunk({ id: 0, tenantId: user.tenantId });
            setToolCheckOutModalAction(false);
            setWorkOrderSerialModalAction(false);
          } else {
            await checkoutWorkPackageThunk({
              navigation,
            });
            await upsertToolUsageThunk({
              isCheckIn: false,
              tools: workPackage.precheckWorkPackageTools,
            });
            setWorkPackageCheckoutInputAction({
              WPid: '',
              WPStatusCode: 'CO',
              WPStatusDescription: 'Checked Out',
              workOrder: '',
              engineserialNumber: '',
            });
            getWorkPackageThunk({
              id: null,
              tenantId: user.tenantId,
            });
            setToolCheckOutModalAction(false);
          }
        }}
      >
        {!myToolFilter ? 'Check-Out Tool (s)' : 'Check-Out Work Package'}
      </Button>
    </CustomModal>
  );
}
