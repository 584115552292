import List from '../../presentational/List';
import { useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';

function RoleList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.role,
    searchResults: state.role.searchResults.filter((user) =>
      formatStringForSearch(user.name).includes(
        formatStringForSearch(state.role.searchInput)
      )
    ),
  }));

  return (
    <List
      tableHead={['Name', 'Description']}
      tableRows={searchResults.map((user) => [user.name, user.description])}
    />
  );
}

export default RoleList;
