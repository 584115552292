import List from '../../presentational/List';

export default function ScoreCardList({ data }) {
  return (
    <List
      tableHead={['Audit Category', 'Score']}
      tableRows={data?.map((item) => [item.label, item.value])}
    />
  );
}
