import { useStoreActions, useStoreState } from 'easy-peasy';
import Image from '../../presentational/Image';
import Button from '../Button';
import Input from '../../presentational/Input';
import Switch from '../../presentational/Switch';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import Card from '../../presentational/Card';
import { pxToDp, remToDp, vhToDp } from '../../../utilities/general';

const ColSwitch = styled(Col)`
  padding-left: ${remToDp(1.5)}px;
`;

function AddTenantForm({ buttons, ...props }) {
  const {
    tenant: { input },
    document,
    status: { placeholder },
  } = useStoreState((state) => state);

  const { setTenantInputAction } = useStoreActions((actions) => actions);

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} lg={6}>
          <Col>
            <Input
              placeholder='Type in tenant name'
              label='Name'
              value={input.name}
              onChangeText={(name) => setTenantInputAction({ name })}
              required
            />
          </Col>

          <Col>
            <Input
              placeholder='Type in billing reference'
              label='Billing Reference'
              value={input.billingReference}
              onChangeText={(billingReference) =>
                setTenantInputAction({ billingReference })
              }
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Rhinestahl Contact'
              label='Rhinestahl Contact'
              value={input.rhinestahlEmailContact}
              onChangeText={(rhinestahlEmailContact) =>
                setTenantInputAction({ rhinestahlEmailContact })
              }
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Quality Control Email Contact'
              label='Quality Control Email Contact'
              value={input.qualitycontrolEmailContact}
              onChangeText={(qualitycontrolEmailContact) =>
                setTenantInputAction({ qualitycontrolEmailContact })
              }
            />
          </Col>

          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in address 1'
                label='Address 1'
                value={input.address1}
                onChangeText={(address1) => setTenantInputAction({ address1 })}
                required
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in address 2'
                label='Address 2'
                value={input.address2}
                onChangeText={(address2) => setTenantInputAction({ address2 })}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} xl={4}>
              <Input
                placeholder='Type in city'
                label='City'
                value={input.city}
                onChangeText={(city) => setTenantInputAction({ city })}
              />
            </Col>
            <Col xs={12} xl={4}>
              <Input
                placeholder='Type in state / province'
                label='State / Province'
                value={input.stateProvince}
                onChangeText={(stateProvince) =>
                  setTenantInputAction({ stateProvince })
                }
              />
            </Col>
            <Col xs={12} xl={4}>
              <Input
                placeholder='Type in Zip / Postal'
                label='Zip / Postal'
                value={input.zipPostal}
                onChangeText={(zipPostal) =>
                  setTenantInputAction({ zipPostal })
                }
              />
            </Col>

            <ColSwitch>
              <Switch
                label='Active'
                value={input.active}
                onValueChange={(active) => setTenantInputAction({ active })}
              />
            </ColSwitch>
          </Row>
        </Col>

        <Col xs={12} lg={6}>
          <Image
            style={{
              marginBottom: pxToDp(15),

              border: '1px solid black',
            }}
            height={vhToDp(25)}
            uri={document.body !== '' ? document.body : placeholder.body}
          />
          <Button.UploadTenantLogo disabled={false} />
        </Col>

        {buttons.map((button, index) => (
          <Col xs={12} md={6} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default AddTenantForm;
