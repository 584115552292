import { axios } from '.';

const reportAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
});

function getUpcomingReportsAPICall({ tenantId }) {
  return reportAxios({
    method: 'get',
    url: `/reports?tenantId=${tenantId}`,
  });
}

function getToolsByEngineAPICall({ tenantId, serialNumber }) {
  return reportAxios({
    method: 'get',
    url: `/reports/engine?tenantId=${tenantId}&engserialnumber=${serialNumber}`,
  });
}

function getReadinessScorecardAPICall({ tenantId }) {
  return reportAxios({
    method: 'get',
    url: `/readinessScorecard?tenantId=${tenantId}`,
  });
}

function upsertReadinessScorecardAPICall(data) {
  return reportAxios({
    method: 'put',
    url: `/readinessScorecard`,
    data,
  });
}

export {
  reportAxios,
  getUpcomingReportsAPICall,
  getToolsByEngineAPICall,
  getReadinessScorecardAPICall,
  upsertReadinessScorecardAPICall,
};
