import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';
import moment from 'moment';

export default function BrokenToolHistoryList({ navigation }) {
  const {
    tools: { brokenToolHistory },
    tenant,
  } = useStoreState((state) => state);

  const { setBrokenToolInputAction, setDocumentAction } = useStoreActions(
    (actions) => actions
  );
  return (
    <List
      pageSize={2}
      tableHead={['Breakage Description', 'Date Broken', 'View']}
      tableData={brokenToolHistory.sort(
        (a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated)
      )}
      tableRows={brokenToolHistory
        .sort((a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated))
        .map((tool) => [
          tool.notes,
          moment(tool.dateUpdated).format('MM/DD/YYYY'),
          <TouchableIcon
            Component={FontAwesome5}
            name="edit"
            size={20}
            onPress={() => {
              setBrokenToolInputAction({
                ...tool,
                toolbrokendescription: tool.notes,
              });
              setDocumentAction({
                notes: tool.name,
                tenantId: tenant.input.id,
                mimeType: tool.mimeType,
                type: 'broken',
                body: tool.body,
              });
              navigation.navigate('Broken Tool Report', { id: tool.id });
            }}
          />,
        ])}
    />
  );
}
