import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

export default function WorkPackageSearchBar() {
  const { searchInput } = useStoreState((state) => state.workPackage);
  const { setWorkPackageSearchInputAction } = useStoreActions((actions) => actions);

  function clearSearchInput() {
    return () => setWorkPackageSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setWorkPackageSearchInputAction(newVal)}
      placeholder="Search packages by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}
