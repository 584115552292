import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Col from '../presentational/Col';
import Button from '../presentational/Button';
import { vhToDp, vwToDp } from '../../utilities/general';
import { MessageStatus } from '../../store/message/state';
import CustomModal from '../presentational/Modal';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function MessageCenterPage({ navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    getMessagesThunk,
    setShowArchivedAction,
    setMessagesSearchResultsAction,
    setCommentsAction,
    setSelectedMessageAction,
    setLoadingAction,
    overrideToolStatusThunk,
  } = useStoreActions((actions) => actions);

  const { showArchived, selectedMessage } = useStoreState(
    (state) => state.messages
  );

  useEffect(() => {
    const loadData = async () => {
      await setLoadingAction(true);
      await getMessagesThunk();
      await setLoadingAction(false);
    };

    if (isFocused) {
      loadData();
    }
    return () => {
      setMessagesSearchResultsAction([]);
      setCommentsAction([]);
      setSelectedMessageAction(null);
    };
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <CustomModal.AreYouSure
        confirmationPhrase='Are you sure you want to override this tool?'
        onSubmit={() => {
          overrideToolStatusThunk();
          setSelectedMessageAction(null);
          setCommentsAction([]);
        }}
      />
      <Container>
        <SRow>
          <Col xs={12} sm={2}>
            <Button
              color='#00205B'
              fullWidth
              onPress={async () => {
                setShowArchivedAction();
                if (
                  showArchived &&
                  selectedMessage?.messagestatusID === MessageStatus.Archived
                ) {
                  setSelectedMessageAction(null);
                  setCommentsAction([]);
                }
              }}
            >
              {showArchived ? 'Hide Archived' : 'Show Archived'}
            </Button>
          </Col>
          <Col xs={12} sm={10}>
            <SearchBar.Messages />
          </Col>
        </SRow>

        <Gap />

        <Card>
          <List.Messages navigation={navigation} />
        </Card>
      </Container>
    </Page.Protected>
  );
}

export default MessageCenterPage;
