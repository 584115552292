import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { ActionSheetIOS, Alert, Platform } from 'react-native';
import { useState } from 'react';

function UploadUserPhotoButton({ children = 'Upload Image', ...props }) {
  const { user, tenant } = useStoreState((state) => state);
  const { isEditable } = user;

  const { setDocumentAction } = useStoreActions((actions) => actions);

  const handleButtonPress = () => {
    ActionSheetIOS.showActionSheetWithOptions(
      {
        options: ['Cancel', 'Take Photo', 'Pick from Library'],
        cancelButtonIndex: 0,
      },
      (buttonIndex) => {
        if (buttonIndex === 1) {
          takePhoto();
        } else if (buttonIndex === 2) {
          pickImage();
        }
      }
    );
  };

  const takePhoto = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();

    if (status !== 'granted') {
      Alert.alert('Sorry, we need camera roll permissions to make this work!');
      return;
    }

    let result = await ImagePicker.launchCameraAsync({
      base64: true,
    });
    if (!result.canceled) {
      setDocumentAction({
        notes: result.assets[0].fileName,
        tenantId: tenant.input.id,
        mimeType: result.assets[0].type,
        type: 'user',
        body: 'data:image/jpeg;base64,' + result.assets[0].base64,
      });
    }
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      base64: true,
    });
    if (!result.canceled) {
      setDocumentAction({
        notes: result.assets[0].fileName,
        tenantId: tenant.input.id,
        mimeType: result.assets[0].type,
        type: 'user',
        body: 'data:image/jpeg;base64,' + result.assets[0].base64,
      });
    }
  };

  return (
    <Button
      disabled={!isEditable}
      onPress={async () => {
        if (Platform.OS == 'web') {
          const document = await DocumentPicker.getDocumentAsync({
            type: 'image/*',
          });

          setDocumentAction({
            notes: document.name,
            tenantId: tenant.input.id,
            mimeType: document.mimeType,
            type: 'user',
            body: document.uri,
          });
        } else {
          handleButtonPress();
        }
      }}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
export default UploadUserPhotoButton;
