import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import Card from '../../presentational/Card';
import Button from '../Button';

export default function InspectionItemForm({ buttons, ...props }) {
  const {
    inspectionItems: { input, isEditable },
  } = useStoreState((state) => state);

  const { setInspectionItemsInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} md={props.sideButtons ? 5 : 6}>
          <Input
            placeholder="Type in Name"
            label="Name"
            value={input.name}
            onChangeText={(name) => setInspectionItemsInputAction({ name })}
            required
            editable={isEditable}
          />
        </Col>
        <Col xs={12} md={props.sideButtons ? 5 : 6}>
          <Input
            placeholder="Type in Description"
            label="Description"
            value={input.description}
            onChangeText={(description) =>
              setInspectionItemsInputAction({ description })
            }
            required
            editable={isEditable}
          />
        </Col>
        {props.sideButtons && (
          <Col xs={12} md={2}>
            <Button.DeleteInspectionItem navigation={props.navigation} />
          </Col>
        )}
      </Row>

      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
