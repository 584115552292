import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function BrokenToolsList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.tools,
    searchResults: state.tools.searchResults.filter((tool) =>
      formatStringForSearch(tool.nomenclature || tool.partNumber).includes(
        formatStringForSearch(state.tools.searchInput)
      )
    ),
  }));

  const { setBrokenToolInputAction } = useStoreActions((actions) => actions);
  return (
    <List
      tableHead={[
        'Part Number',
        'Tool',
        'Serial',
        'Status',
        'Location',
        'Edit',
      ]}
      tableRows={searchResults
        .filter((tool) => tool.statusDescription == 'Unserviceable')
        .map((tool) => [
          tool.partNumber,
          tool.nomenclature,
          tool.serialNumber,
          tool.statusDescription,
          tool.storageLocation,
          <TouchableIcon
            Component={FontAwesome5}
            name="edit"
            size={20}
            onPress={() => {
              setBrokenToolInputAction({
                ...tool,
              });

              navigation.navigate('Edit Unserviceable Tool', { id: tool.id });
            }}
          />,
        ])}
    />
  );
}
