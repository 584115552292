import { thunk } from 'easy-peasy';
import { getAuthUserAPICall, loginAPICall } from '../../api/auth';
import { removeAuthToken, setAuthToken } from '../../utilities/api';
import * as Network from 'expo-network';
import { changePasswordAPICall } from '../../api/user';

const thunks = {
  loginThunk: thunk(async (actions, input) => {
    actions.setLoadingAction(true);
    try {
      console.log(await Network.getIpAddressAsync());
      const response = await loginAPICall({
        ...input,
        ipaddr: await Network.getIpAddressAsync(),
      });
      const { mandatepasswordchange, token, ...user } = response.data;
      if (mandatepasswordchange == true) {
        await setAuthToken(token);
        actions.setPasswordChangeModalAction(true);

        actions.setUserTenantId(user.tenantId);
        actions.setUserId(user.id);
      } else if (mandatepasswordchange == false) {
        await setAuthToken(token);
        actions.setUserAction(user);
        actions.getTenantsThunk();
      }
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  logoutThunk: thunk(async (actions, navigation) => {
    await removeAuthToken();
    actions.resetUserAction();
    actions.setTenantSearchResultsAction([]);
    navigation && navigation.closeDrawer();
  }),
  getAuthUserThunk: thunk(async (actions) => {
    actions.setLoadingAction(true);

    try {
      const response = await getAuthUserAPICall();
      actions.setUserAction(response.data);
    } catch (err) {
      actions.logoutThunk();
    }

    actions.setLoadingAction(false);
  }),

  changePasswordThunk: thunk(async (actions, input, helpers) => {
    actions.setLoadingAction(true);
    try {
      await changePasswordAPICall({
        ...input,
        id: helpers.getState().auth.user.id,
        tenantId: helpers.getState().auth.user.tenantId,
      });

      actions.setPasswordChangeInputAction({
        password: '',
      });

      actions.setPasswordChangeModalAction(false);

      actions.setAlertThunk({
        type: 'success',
        message: 'Password Changed Successfully',
      });

      actions.resetUserAction();
      actions.setTenantSearchResultsAction([]);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
};

export default thunks;
