import { action } from "easy-peasy";
import { defaultInputState } from "./state";

const actions = {
  setRoleSearchResultsAction: action((state, searchResults) => {
    state.role.searchResults = searchResults;
  }),
  setRoleSearchInputAction: action((state, searchInput) => {
    state.role.searchInput = searchInput;
  }),
  setIsEditableRoleAction: action((state, isEditable) => {
    state.role.isEditable = isEditable;
  }),
  setRoleInputAction: action((state, input) => {
    state.role.input = {
      ...state.role.input,
      ...input,
    };
  }),
  resetRoleInputAction: action((state, input) => {
    state.role.input = defaultInputState;
  }),
};

export default actions;
