import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import Card from '../../presentational/Card';
import Image from '../../presentational/Image';
import Icon from '../../presentational/Icon';
import { Entypo } from '@expo/vector-icons';
import { pxToDp, vhToDp } from '../../../utilities/general';
import Button from '../Button';
import Gap from '../../presentational/Gap';
import { Divider } from '@rneui/themed';
import List from '../List';

export default function BrokenToolForm({ buttons, ...props }) {
  const {
    tools: {
      input,
      brokenToolInput,
      searchResults,
      brokenToolEditable,
      toolCategoryList,
    },
    document,
    status: { placeholder },
  } = useStoreState((state) => state);

  const { setBrokenToolInputAction, setToolsInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} lg={6}>
          <Col>
            <Input
              placeholder='Type in Tool Part Number'
              label='Tool Part Number'
              value={input.partNumber || brokenToolInput.toolId}
              onSelect={(toolpartNumber, index) => {
                setToolsInputAction(
                  searchResults.find((tool) => tool.id == toolpartNumber.id)
                );

                setBrokenToolInputAction({
                  toolId: toolpartNumber.id,
                });
              }}
              editable={brokenToolEditable}
              selectedItem={(item) => item.partNumber}
              rowTextForSelection={(item) => item.partNumber}
              dropdown
              dropdownChoices={searchResults}
              width={'100%'}
              search={true}
              searchInputStyle={{
                width: '100%',
                alignSelf: 'center',
                backgroundColor: '#F7F7F7',
                border: 0,

                boxShadow: '0px 0px 10px grey',
                borderColor: '#F7F7F7',
              }}
              searchInputTxtStyle={{
                fontFamily: 'Barlow_400Regular',
                fontSize: 16,
              }}
              renderSearchInputRightIcon={() => (
                <Icon Component={Entypo} name='magnifying-glass' />
              )}
              searchInputPlaceholder='Search for Tool Part Number'
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Serial Num'
              label='Serial Number'
              value={input.serialNumber}
              onSelect={(serialNumber) =>
                setBrokenToolInputAction({ serialNumber })
              }
              editable={brokenToolEditable}
              selectedItem={(item) => item.serialNumber}
              rowTextForSelection={(item) => item.serialNumber}
              dropdown
              // dropdownChoices={searchResults}
              dropdownChoices={
                // Sometimes tools will have the same name but different serial numbers so we need to filter out the tools with the same name
                searchResults.filter(
                  (tool) => tool.partNumber == input.partNumber
                ) || []
              }
              width={'100%'}
              search={true}
              searchInputStyle={{
                width: '100%',
                alignSelf: 'center',
                backgroundColor: '#F7F7F7',
                border: 0,

                boxShadow: '0px 0px 10px grey',
                borderColor: '#F7F7F7',
              }}
              searchInputTxtStyle={{
                fontFamily: 'Barlow_400Regular',
                fontSize: 16,
              }}
              renderSearchInputRightIcon={() => (
                <Icon Component={Entypo} name='magnifying-glass' />
              )}
              searchInputPlaceholder='Search for Tool Part Number'
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Nomenclature'
              label='Nomenclature'
              value={input.nomenclature}
              onChangeText={(toolbrokendescription) =>
                setBrokenToolInputAction({ toolbrokendescription })
              }
              multiline
              editable={brokenToolEditable}
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Breakage Category'
              label='Breakage Category'
              value={input.brokentoolcategoryDescription}
              onSelect={(brokentoolcategoryDescription) =>
                setBrokenToolInputAction({
                  brokentoolcategoryDescription:
                    brokentoolcategoryDescription.categoryDescription,
                })
              }
              editable={brokenToolEditable}
              selectedItem={(item) => item.categoryDescription}
              rowTextForSelection={(item) => item.categoryDescription}
              dropdown
              // dropdownChoices={searchResults}
              dropdownChoices={toolCategoryList}
              width={'100%'}
              search={true}
              searchInputStyle={{
                width: '100%',
                alignSelf: 'center',
                backgroundColor: '#F7F7F7',
                border: 0,

                boxShadow: '0px 0px 10px grey',
                borderColor: '#F7F7F7',
              }}
              searchInputTxtStyle={{
                fontFamily: 'Barlow_400Regular',
                fontSize: 16,
              }}
              renderSearchInputRightIcon={() => (
                <Icon Component={Entypo} name='magnifying-glass' />
              )}
              searchInputPlaceholder='Search for Tool Part Number'
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Description of Breakage'
              label='Description of Breakage'
              value={brokenToolInput.toolbrokendescription}
              onChangeText={(toolbrokendescription) =>
                setBrokenToolInputAction({ toolbrokendescription })
              }
              multiline
              style={{ height: 200 }}
              editable={brokenToolEditable}
            />
          </Col>
        </Col>

        <Col xs={12} lg={6}>
          <Image
            style={{
              marginBottom: pxToDp(15),
              border: '1px solid black',
            }}
            height={vhToDp(25)}
            uri={document.body !== '' ? document.body : placeholder?.body}
          />
          {props.uploadButton && <Button.UploadBrokenTool {...props} />}
          {/* <Button.UploadBrokenTool {...props} /> */}
        </Col>
      </Row>

      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
      {props.sideButtons && (
        <Col xs={12} lg={12}>
          <Divider />
          <Gap />
          <List.BrokenToolHistory navigation={props.navigation} />
        </Col>
      )}
    </Card>
  );
}
