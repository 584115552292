const state = {
  inspections: {
    list: [],
    input: { id: null },
    isEdit: false,
    showModal: false,
    toolId: null,
  },
};

export { state as default };
