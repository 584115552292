import { axios } from '.';

const messageAxios = axios.create({
  // baseURL: `https://tmpbackenduat.azurewebsites.net/message`,
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/message`,
});

function getMessagesAPICall({ tenantId }) {
  return messageAxios({
    method: 'get',
    url: `/getMessages?tenantId=${tenantId}`,
  });
}

function upsertMessageAPICall(data) {
  return messageAxios({ method: 'post', url: `/upsertMessage`, data });
}

export { messageAxios, getMessagesAPICall, upsertMessageAPICall };
