import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect, useState } from 'react';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Row from '../presentational/Row';
import Col from '../presentational/Col';
import ReadinessScorecardCard from '../containers/Card/ReadinessScorecardCard';
import { View } from 'react-native';
import CustomModal from '../presentational/Modal';

const Container = styled.View``;

const SRow = styled(Row)``;
const SCol = styled(Col)`
  padding: 0.5rem;
`;

function ReadinessScorePage({ navigation, ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const isFocused = useIsFocused();

  const {
    getReadinessScorecardsThunk,
    setScorecardType,
    setScorecardModalTypeAction,
    setScoreCardModalAction,
  } = useStoreActions((actions) => actions);

  const { currentScorecards, scorecardHistory } = useStoreState(
    (state) => state.readiness
  );

  useEffect(() => {
    const load = async () => {
      await getReadinessScorecardsThunk();
      setIsLoading(false);
    };
    if (isFocused) {
      load();
    }
    return () => {};
  }, [isFocused]);

  const showDetails = (cardType) => {
    setScorecardModalTypeAction(cardType);
    setScoreCardModalAction(true);
  };

  return (
    <Page.Protected {...props}>
      <CustomModal.ScoreCard />
      <Container>
        <Card>
          <View style={{ flex: 1, height: '100%', padding: '1rem' }}>
            <SRow>
              <SCol xs={12} sm={6}>
                <ReadinessScorecardCard
                  loading={isLoading}
                  title='Safety'
                  height={'300px'}
                  fillColor={'#017acd'}
                  addAction={() => {
                    setScorecardType('Safety');
                    navigation.navigate('Add Readiness Score');
                  }}
                  dataset={{
                    current: currentScorecards['Safety'],
                    previous: scorecardHistory['Safety'],
                  }}
                  onPress={() => showDetails('Safety')}
                ></ReadinessScorecardCard>
              </SCol>
              <SCol xs={12} sm={6}>
                <ReadinessScorecardCard
                  loading={isLoading}
                  title='People'
                  height={'300px'}
                  fillColor={'#017acd'}
                  addAction={() => {
                    setScorecardType('People');
                    navigation.navigate('Add Readiness Score');
                  }}
                  dataset={{
                    current: currentScorecards['People'],
                    previous: scorecardHistory['People'],
                  }}
                  onPress={() => showDetails('People')}
                ></ReadinessScorecardCard>
              </SCol>
              <SCol xs={12} sm={6}>
                <ReadinessScorecardCard
                  loading={isLoading}
                  title='Quality'
                  height={'300px'}
                  fillColor={'#017acd'}
                  addAction={() => {
                    setScorecardType('Quality');
                    navigation.navigate('Add Readiness Score');
                  }}
                  dataset={{
                    current: currentScorecards['Quality'],
                    previous: scorecardHistory['Quality'],
                  }}
                  onPress={() => showDetails('Quality')}
                ></ReadinessScorecardCard>
              </SCol>
              <SCol xs={12} sm={6}>
                <ReadinessScorecardCard
                  loading={isLoading}
                  title='Delivery'
                  height={'300px'}
                  fillColor={'#017acd'}
                  addAction={() => {
                    setScorecardType('Delivery');
                    navigation.navigate('Add Readiness Score');
                  }}
                  dataset={{
                    current: currentScorecards['Delivery'],
                    previous: scorecardHistory['Delivery'],
                  }}
                  onPress={() => showDetails('Delivery')}
                ></ReadinessScorecardCard>
              </SCol>
              <SCol xs={12} sm={6}>
                <ReadinessScorecardCard
                  loading={isLoading}
                  title='Cost'
                  height={'300px'}
                  fillColor={'#017acd'}
                  addAction={() => {
                    setScorecardType('Cost');
                    navigation.navigate('Add Readiness Score');
                  }}
                  dataset={{
                    current: currentScorecards['Cost'],
                    previous: scorecardHistory['Cost'],
                  }}
                  onPress={() => showDetails('Cost')}
                ></ReadinessScorecardCard>
              </SCol>
            </SRow>
          </View>
        </Card>
      </Container>
    </Page.Protected>
  );
}

export default ReadinessScorePage;
