import { thunk } from 'easy-peasy';
import { getUserAPICall, upsertUserAPICall } from '../../api/user';
import { loadDocumentAPICall, upsertDocumentAPICall } from '../../api/document';

const thunks = {
  getUsersThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getUserAPICall({
        tenantId: helpers.getState().tenant.input.id,
        id: null,
      });
      actions.setUserSearchResultsAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getUserThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    actions.resetDocumentAction();

    try {
      const tenantId = helpers.getState().tenant.input.id;

      const userResponse = await getUserAPICall({
        id,
        tenantId,
      });

      if (userResponse.data[0].repositoryId !== null) {
        const documentResponse = await loadDocumentAPICall({
          tenantId: parseInt(
            helpers.getState().tenant.input.id ||
              helpers.getState().auth.user.tenantId
          ),
          id: userResponse.data[0].repositoryId,
        });
        if (documentResponse.data.length > 0) {
          actions.setDocumentAction(documentResponse.data[0]);
        } else {
          actions.setDocumentAction({
            ...helpers.getState().document,
            body: helpers.getState().status.placeholder,
          });
        }
      } else {
        actions.setDocumentAction({
          ...helpers.getState().document,
          body: helpers.getState().status.placeholder,
        });
      }

      actions.setUserInputAction(userResponse.data[0]);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  upsertUserThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const res = await upsertDocumentAPICall({
          ...helpers.getState().document,
          tenantId: helpers.getState().tenant.input.id,
        });

        await upsertUserAPICall({
          ...input,
          tenantId: helpers.getState().tenant.input.id,
          repositoryId: res.data.id,
        });
        actions.setAlertThunk({
          type: 'success',
          message,
        });
        navigation.navigate('Users');
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
