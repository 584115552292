import { useStoreActions, useStoreState } from 'easy-peasy';
import List from '../../presentational/List';
import TouchableIcon from '../../presentational/TouchableIcon';
import { FontAwesome5 } from '@expo/vector-icons';
import { openBase64FileOnWeb } from '../../../utilities/document';

export default function DocumentList({ navigation, disabled }) {
  const { documents, tenant } = useStoreState((state) => state);
  const {
    showDocumentModalAction,
    setDocumentImageAction,
    setAreYouSureDeleteDocumentModalVisibleAction,
    setRemoveDocumentInputAction,
    loadDocumentThunk,
  } = useStoreActions((actions) => actions);
  return (
    <List
      tableHead={['Name', 'Type', 'Remove', 'View']}
      tableRows={documents.map((doc, index) => [
        doc.notes,
        doc.type,
        <TouchableIcon
          disabled={disabled}
          Component={FontAwesome5}
          name='trash'
          size={20}
          onPress={() => {
            setRemoveDocumentInputAction({
              id: doc.id,
              tenantId: tenant.input.id,
              toolid: doc.toolid,
            });

            setAreYouSureDeleteDocumentModalVisibleAction(true);
          }}
        />,
        <TouchableIcon
          Component={FontAwesome5}
          name='angle-right'
          size={20}
          onPress={() => {
            if (doc.body && doc.body.includes('data:application/pdf;base64,')) {
              const base64 = doc.body.split(',')[1];
              openBase64FileOnWeb(base64, 'application/pdf');
            } else {
              if (doc.body) {
                setDocumentImageAction(doc.body);
                showDocumentModalAction(true);
              } else if (doc.uri) {
                if (
                  doc.uri &&
                  doc.uri.includes('data:application/pdf;base64,')
                ) {
                  const base64 = doc.uri.split(',')[1];
                  openBase64FileOnWeb(base64, 'application/pdf');
                } else {
                  setDocumentImageAction(doc.uri);
                  showDocumentModalAction(true);
                }
              } else {
                loadDocumentThunk({ id: doc.id });
              }
            }
          }}
        />,
      ])}
    />
  );
}
