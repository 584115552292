import { useStoreActions, useStoreState } from 'easy-peasy';
import Image from '../../presentational/Image';
import Button from '../Button';
import Input from '../../presentational/Input';
import Switch from '../../presentational/Switch';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import Card from '../../presentational/Card';
import List from '../List';
import Gap from '../../presentational/Gap';
import { Divider } from '@rneui/themed';
import { remToDp } from '../../../utilities/general';
import * as DocumentPicker from 'expo-document-picker';

const ColSwitch = styled(Col)`
  padding-left: ${remToDp(1.5)}px;
`;

export default function WorkPackageForm({ buttons, ...props }) {
  const {
    workPackage: { input, isEditable },
    document,
    auth: { user },
  } = useStoreState((state) => state);

  const {
    setWorkPackageInputAction,
    setCopyWorkPackageToTenantModalVisibleAction,
    setCopyWorkPackageInputAction,
    bulkUploadWorkPackageToolsThunk,
    getWorkPackageToolsThunk,
  } = useStoreActions((actions) => actions);

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in Work Package Name'
                label='Work Package Name'
                value={input.name}
                onChangeText={(name) => setWorkPackageInputAction({ name })}
                required
                editable={isEditable}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in Engine Model'
                label='Engine Model'
                value={input.engineModel}
                onChangeText={(engineModel) =>
                  setWorkPackageInputAction({ engineModel })
                }
                required
                editable={isEditable}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                placeholder='Type in Description'
                label='Description'
                value={input.description}
                onChangeText={(description) =>
                  setWorkPackageInputAction({ description })
                }
                required
                editable={isEditable}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={props.sideButtons ? 4 : 6}>
          <Row>
            <Col xs={12} md={6}>
              <Input
                selectedItem={(item) => item.label}
                rowTextForSelection={(item) => item.label}
                buttonTextAfterSelection={(selectedItem, index) => {
                  return selectedItem.label;
                }}
                dropdown
                dropdownChoices={[{ label: 'Configured', value: 'CNFD' }]}
                placeholder='Type in Status'
                label='Status'
                value={input.statusDescription}
                onSelect={(status) => {
                  setWorkPackageInputAction({ status: status.value });
                  setWorkPackageInputAction({
                    statusDescription: status.label,
                  });
                }}
                required
                editable={isEditable}
                // disabled={!isEditable}
                width={'94%'}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in Engine'
                label='Engine'
                value={input.engine}
                onChangeText={(engine) => setWorkPackageInputAction({ engine })}
                required
                editable={isEditable}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in Module'
                label='Module'
                value={input.module}
                onChangeText={(module) => setWorkPackageInputAction({ module })}
                required
                editable={isEditable}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder='Type in Engine Manual Ref'
                label='Engine Manual Ref'
                value={input.engineManualRef}
                onChangeText={(engineManualRef) =>
                  setWorkPackageInputAction({ engineManualRef })
                }
                required
                editable={isEditable}
              />
            </Col>
          </Row>
        </Col>
        {props.sideButtons && (
          <Col xs={12} lg={2}>
            <Button
              fullWidth
              onPress={() =>
                props.navigation.navigate('Add Work Package Tool', {
                  id: input.id,
                })
              }
            >
              Add Work Package Tool
            </Button>
            <Gap />
            <Button.ReserveWorkPackage navigation={props.navigation} />
            {user.tenantId == 1 && (
              <>
                <Gap />
                <Button
                  fullWidth
                  onPress={() => {
                    setCopyWorkPackageInputAction({
                      WPid: null,
                      fromtenantId: null,
                      totenantId: null,
                      name: '',
                      description: '',
                    });

                    setCopyWorkPackageToTenantModalVisibleAction(true);
                  }}
                >
                  Copy to Tenant
                </Button>
              </>
            )}
            {user.tenantId == 1 && (
              <>
                <Gap />
                <Button
                  fullWidth
                  onPress={async () => {
                    const document = await DocumentPicker.getDocumentAsync({
                      type: 'json/csv',
                    });
                    const b64 = document.uri.split(',')[1];
                    await bulkUploadWorkPackageToolsThunk(b64);
                    getWorkPackageToolsThunk();
                    // await getToolsThunk(0);
                  }}
                >
                  Bulk Tool Upload
                </Button>
              </>
            )}

            <Gap />
            <Button.DuplicateWorkPackage navigation={props.navigation} />
            <Gap />
            <>
              <Button
                fullWidth
                onPress={async () => {
                  props.navigation.navigate('Work Package Reservations', {
                    id: input.id,
                  });
                }}
              >
                Reservation List
              </Button>
            </>
            <Gap />

            <Button.DeleteWorkPackage navigation={props.navigation} />
          </Col>
        )}
      </Row>
      <Row>
        <ColSwitch>
          <Switch
            disabled={!isEditable}
            label='Active'
            value={input.activeFlag == 'Y' ? true : false}
            onValueChange={(active) =>
              setWorkPackageInputAction({
                activeFlag: active == true ? 'Y' : 'N',
              })
            }
          />
        </ColSwitch>
      </Row>
      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
      <Gap />
      <Divider />
      <Gap />
      {props.sideButtons && (
        <List.WorkPackageTools navigation={props.navigation} />
      )}
    </Card>
  );
}
