import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { vwToDp } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';
import { ActivityIndicator, TouchableOpacity, View } from 'react-native';
import { MessageStatus } from '../../../store/message/state';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import { useState } from 'react';
import Input from '../../presentational/Input';
import { Platform } from 'react-native';
import Button from '../../presentational/Button';
import Text from '../../presentational/Text';
import Gap from '../../presentational/Gap';

export default function MessageList({ navigation }) {
  const [comment, setComment] = useState();
  const hasSearchText = (value, searchInput) =>
    value?.toString().toLowerCase().trim().includes(searchInput.toLowerCase());

  const {
    searchResults,
    showArchived,
    comments,
    loading,
    selectedId,
    selectedMessage,
  } = useStoreState((state) => ({
    ...state.messages,
    searchResults: (state.messages.searchResults || []).filter(
      (message) =>
        hasSearchText(message?.messagepreview, state.messages.searchInput) ||
        hasSearchText(message?.message, state.messages.searchInput) ||
        hasSearchText(message?.comments, state.messages.searchInput) ||
        hasSearchText(message?.createdBy, state.messages.searchInput) ||
        hasSearchText(
          moment.utc(message?.dateCreated).format('MM/DD/YYYY'),
          state.messages.searchInput
        )
    ),
  }));

  const {
    upsertMessageThunk,
    getCommentsThunk,
    upsertCommentThunk,
    removeCommentThunk,
    updateCommentAction,
    setCommentsAction,
    setSelectedMessageAction,
    setAreYouSureModalVisibleAction,
  } = useStoreActions((actions) => actions);

  return (
    <View style={{ border: '1px solid rgb(222, 226, 230)' }}>
      <Row>
        <Col
          xs={12}
          md={3}
          style={{
            borderRight: '1px solid rgb(222, 226, 230)',
            maxHeight: '70vh',
            overflow: 'auto',
          }}
        >
          <Row style={{ borderBottom: '1px solid rgb(222, 226, 230)' }}>
            <Col xs={12}>
              <Text
                style={{
                  fontFamily: 'Barlow_600SemiBold',
                  fontSize: '1.25rem',
                }}
              >
                Messages
              </Text>
            </Col>
          </Row>
          {searchResults
            .filter(
              (item) =>
                showArchived || item.messagestatusID !== MessageStatus.Archived
            )
            .sort(
              (a, b) =>
                parseInt(a.messagestatusID) - parseInt(b.messagestatusID)
            )
            .map((message) => {
              return (
                <Row
                  style={{
                    borderBottom: '1px solid rgb(222, 226, 230)',
                  }}
                >
                  <Col
                    xs={12}
                    style={{
                      backgroundColor:
                        message?.messageID === selectedId
                          ? 'lightgrey'
                          : 'white',
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setSelectedMessageAction(message);
                        getCommentsThunk({ messageId: message.messageID });
                        if (message.messagestatusID === MessageStatus.Unread) {
                          upsertMessageThunk({
                            ...message,
                            messagestatusID: parseInt(MessageStatus.Read),
                          });
                        }
                      }}
                    >
                      <Text
                        style={{
                          fontFamily:
                            message.messagestatusID === MessageStatus.Unread
                              ? 'Barlow_600SemiBold'
                              : 'Barlow_400Regular',
                          color: '#00205b',
                          fontSize: '1.25rem',
                        }}
                      >
                        {message.messagepreview}
                      </Text>
                    </TouchableOpacity>
                  </Col>
                </Row>
              );
            })}
        </Col>
        <Col xs={12} md={9} style={{ paddingTop: 0 }}>
          <>
            <Row>
              <Col xs={12} style={{ paddingTop: 0, alignItems: 'end' }}>
                <Row>
                  <Col xs={12} sm={6}>
                    <Button
                      color='#ab2328'
                      fullWidth
                      disabled={
                        !selectedMessage ||
                        selectedMessage?.messagestatusID ===
                          MessageStatus.Archived
                      }
                      onPress={async () => {
                        setAreYouSureModalVisibleAction(true);
                      }}
                    >
                      Override
                    </Button>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Button
                      color='#00205B'
                      fullWidth
                      disabled={
                        !selectedMessage ||
                        selectedMessage?.messagestatusID ===
                          MessageStatus.Archived
                      }
                      onPress={() => {
                        upsertMessageThunk({
                          ...selectedMessage,
                          messagestatusID: parseInt(MessageStatus.Archived),
                        });
                        setSelectedMessageAction(null);
                        setCommentsAction([]);
                      }}
                    >
                      Archive
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {selectedMessage && (
                  <>
                    <Text
                      style={{
                        fontFamily: 'Barlow_400Regular',
                        color: '#00205b',
                        fontSize: '1.25rem',
                      }}
                    >
                      {selectedMessage?.messagepreview} -{' '}
                      {moment
                        .utc(selectedMessage?.dateCreated)
                        .format('MM/DD/YYYY')}
                    </Text>
                    <Gap />
                    <Text
                      style={{
                        fontFamily: 'Barlow_400Regular',
                        color: '#00205b',
                        fontSize: '1.25rem',
                      }}
                    >
                      {selectedMessage?.message}
                    </Text>
                  </>
                )}
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: '1px solid rgb(222, 226, 230)',
                borderTop: '1px solid rgb(222, 226, 230)',
                marginTop: '2rem',
              }}
            >
              <Col xs={10} md={10}>
                <Input
                  disabled={!selectedMessage}
                  placeholder='Comment'
                  value={comment}
                  onChangeText={(commentText) => setComment(commentText)}
                  multiline
                  style={{ height: Platform.OS == 'web' ? 75 : 25 }}
                />
              </Col>
              <Col xs={2} md={2} style={{ justifyContent: 'center' }}>
                <Button
                  disabled={!selectedMessage}
                  fullWidth
                  onPress={async () => {
                    upsertCommentThunk({
                      commentID: null,
                      messageID: selectedMessage?.messageID,
                      comment,
                    });

                    setComment();
                  }}
                >
                  {'Add Comment'}
                </Button>
              </Col>
            </Row>
            {comments
              .sort((a, b) => {
                return new Date(b.datecreated) - new Date(a.datecreated);
              })
              .map((comment) => {
                return (
                  <View
                    style={{ borderBottom: '1px solid rgb(222, 226, 230)' }}
                  >
                    <Row>
                      <Col xs={6} md={6}>
                        <Text
                          style={{
                            fontFamily: 'Barlow_400Regular',
                            color: '#00205b',
                            fontSize: '1.25rem',
                          }}
                        >
                          {comment.createdBy} - (
                          {moment.utc(comment.datecreated).format('MM/DD/YYYY')}
                          )
                        </Text>
                      </Col>
                      <Col xs={6} md={6} style={{ alignItems: 'end' }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            width: vwToDp(3),
                            justifyContent: 'space-between',
                            alignContent: 'center',
                            alignItems: 'end',
                          }}
                        >
                          {comment.isDisabled ? (
                            <TouchableIcon
                              Component={FontAwesome5}
                              name='pencil-alt'
                              size={20}
                              onPress={() => {
                                updateCommentAction({
                                  ...comment,
                                  isDisabled: false,
                                });
                              }}
                            />
                          ) : (
                            <TouchableIcon
                              Component={FontAwesome5}
                              name='save'
                              size={20}
                              onPress={() => {
                                upsertCommentThunk({
                                  ...comment,
                                });
                              }}
                            />
                          )}
                          <TouchableIcon
                            Component={FontAwesome5}
                            name='trash'
                            size={20}
                            onPress={() => {
                              removeCommentThunk({
                                commentId: comment.commentID,
                                messageId: selectedMessage.messageID,
                              });
                            }}
                          />
                        </View>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Input
                          placeholder='Comment'
                          value={comment.comment}
                          disabled={comment.isDisabled}
                          onChangeText={(commentText) =>
                            updateCommentAction({
                              ...comment,
                              comment: commentText,
                            })
                          }
                          multiline
                          style={{ height: Platform.OS == 'web' ? 75 : 25 }}
                        />
                      </Col>
                    </Row>
                  </View>
                );
              })}
          </>
          {loading && (
            <View
              pointerEvents='none'
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#F5FCFF88',
                zIndex: 1000,
              }}
            >
              <ActivityIndicator size='large' />
            </View>
          )}
        </Col>
      </Row>
    </View>
  );
}
