import Page from '../containers/Page';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Row from '../presentational/Row';
import { vhToDp, vwToDp } from '../../utilities/general';
import Form from '../containers/Form';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function AddReadinessScorePage({ navigation, ...props }) {
  return (
    <Page.Protected {...props}>
      <Container>
        <Card>
          <Form.AddReadinessScore />
        </Card>
      </Container>
    </Page.Protected>
  );
}

export default AddReadinessScorePage;
