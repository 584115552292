import { Button as RNButton } from '@rneui/themed';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components/native';

const Container = styled.View`
  flex-direction: ${(props) => (props.fullWidth ? '' : 'row')};
`;

function Button({ children, fullWidth = false, ...props }) {
  const { input } = useStoreState((state) => state.tenant);
  return (
    <Container fullWidth={fullWidth}>
      <RNButton
        titleStyle={{ fontFamily: 'Barlow_400Regular' }}
        size="lg"
        title={children}
        color="#0088CE"
        {...props}
        disabled={props.disabled}
      />
    </Container>
  );
}

export default Button;
