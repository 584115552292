import { axios } from ".";
import { convertJsonToQueryString } from "../utilities/general";

const dropdownAxios = axios.create({
	// baseURL: `https://tmpbackenduat.azurewebsites.net/dropdown`,
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/dropdown`,
});

function getDropdownAPICall(payload) {
	return dropdownAxios({
		method: "get",
		url: `/get?${convertJsonToQueryString(payload)}`,
	});
}

function upsertDropdownAPICall(data) {
	return dropdownAxios({ method: "put", url: "/upsert", data });
}

export { dropdownAxios, upsertDropdownAPICall, getDropdownAPICall };
