const defaultInputState = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  userName: '',
  password: '',
  role: '',
  locationId: '',
  repositoryId: '',
  active: true,
};

const state = {
  role: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
  },
};

export { state as default, defaultInputState };
