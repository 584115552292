import { useStoreActions } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

export default function EngineFamilyListPage({ navigation, ...props }) {
  const isFocused = useIsFocused();

  const { getEnginesThunk, setSelectedEngineAction } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    const getData = async () => {
      await getEnginesThunk();
    };
    if (isFocused) {
      getData();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <SRow>
          <Col xs={12} sm={2}>
            <Button
              fullWidth
              onPress={() => {
                setSelectedEngineAction({
                  tenantid: null,
                  EngineFamilyID: null,
                  EngineFamily: null,
                  EngineFamilyDescription: null,
                  variants: [],
                });
                navigation.navigate('Add Engine Family');
              }}
            >
              Add Engine Family
            </Button>
          </Col>
          <Col xs={12} sm={10}>
            <SearchBar.EngineFamily />
          </Col>
        </SRow>
        <Gap />
        <Card>
          <List.EngineFamily navigation={navigation} />
        </Card>
      </Container>
    </Page.Protected>
  );
}
