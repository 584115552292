import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function AddLabelPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const { resetToolsInputAction } = useStoreActions((actions) => actions);

  useEffect(() => {
    isFocused && resetToolsInputAction();
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.AddLabel
          navigation={navigation}
          buttons={[
            <Button.GoToLabels navigation={navigation}>
              Cancel
            </Button.GoToLabels>,
            <Button.SaveLabel
              navigation={navigation}
              message="Added Label"
              disabled={false}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
