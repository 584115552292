import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function InspectionList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.inspectionTypes,
    searchResults: state.inspectionTypes.searchResults.filter((user) =>
      formatStringForSearch(user?.name).includes(
        formatStringForSearch(state?.inspectionTypes?.searchInput)
      )
    ),
  }));
  const { setInspectionInputAction } = useStoreActions((actions) => actions);

  return (
    <List
      tableHead={['Name', 'Created By', 'Edit']}
      tableRows={searchResults.map((inspection) => [
        inspection.name,
        inspection.createdBy,
        <TouchableIcon
          Component={FontAwesome5}
          name='edit'
          size={20}
          onPress={() => {
            setInspectionInputAction({
              ...inspection,
              inspectionRequiredName:
                inspection.inspectionRequired === 'Y' ? 'Yes' : 'No',
            });
            navigation.navigate('Edit Inspection Type', { id: inspection.id });
          }}
        />,
      ])}
    />
  );
}
