import queryString from 'querystring';
import { ActionSheetIOS, Alert, Dimensions, PixelRatio } from 'react-native';
import * as ImagePicker from 'expo-image-picker';

function delay(ms) {
  return new Promise((res) => setTimeout(res, ms));
}

function formatStringForSearch(string) {
  return string.trim().toLowerCase();
}

function convertJsonToQueryString(paramJson) {
  const cleanedParams = Object.fromEntries(
    Object.entries(paramJson).filter(([_, v]) => v !== null)
  );
  return queryString.stringify(cleanedParams);
}

function getScreenHeightInVh() {
  const windowHeight = Dimensions.get('window').height;
  const onePercentOfHeight = windowHeight / 100;
  const screenHeightInVh = 100 / onePercentOfHeight;
  return screenHeightInVh;
}

function pxToDp(pxValue) {
  return PixelRatio.roundToNearestPixel(pxValue / PixelRatio.get());
}

function remToDp(remValue, baseFontSize = 16) {
  const dpValue = remValue * PixelRatio.getFontScale() * baseFontSize;

  return PixelRatio.roundToNearestPixel(dpValue);
}

function vwToDp(vwValue) {
  const dpValue = (vwValue * Dimensions.get('window').width) / 100;

  return PixelRatio.roundToNearestPixel(dpValue);
}

function vhToDp(vhValue) {
  const dpValue = (vhValue * Dimensions.get('window').height) / 100;

  return PixelRatio.roundToNearestPixel(dpValue);
}

function getObjectsByIds(ids, objectList) {
  if (!ids || !objectList) return [];

  if (typeof ids === 'string') {
    ids = ids.split(',');
  }

  return objectList.filter((obj) => ids.includes(obj.id.toString()));
}

function getObjectsByNames(name, list) {
  // Get the names from the list
  const names = list.map((item) => item.name);

  // Get the index of the name in the list
  const index = names.indexOf(name);

  // Return the object at that index
  return list[index];
}

export {
  delay,
  formatStringForSearch,
  convertJsonToQueryString,
  getScreenHeightInVh,
  pxToDp,
  remToDp,
  vwToDp,
  vhToDp,
  getObjectsByIds,
  getObjectsByNames,
};
