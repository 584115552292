import { thunk } from 'easy-peasy';
import { getRolesAPICall } from '../../api/role';

const thunks = {
  getRolesThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getRolesAPICall({
        id: 0,
        tenantId: parseInt(helpers.getState().tenant.input.id),
      });

      actions.setRoleSearchResultsAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};
export default thunks;
