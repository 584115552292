import React from 'react';
import DateTimePickerComponent from '../../../presentational/DateTimePicker';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';

export default function NextPMIDatePicker(visible) {
  const { nextPMIDateCalendarVisible } = useStoreState((state) => state.tools);

  const { setNextPMICalendarVisible, setToolsInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <DateTimePickerComponent
      onChange={(date) => {
        setNextPMICalendarVisible(!nextPMIDateCalendarVisible);

        setToolsInputAction({
          nextPMIDate: moment(date).format('MM/DD/YYYY'),
        });
      }}
      visible={nextPMIDateCalendarVisible}
      onBackdropPress={() => setNextPMICalendarVisible(false)}
    />
  );
}
