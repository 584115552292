import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function ToolCategoryPage({ navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    auth: { user },
  } = useStoreState((state) => state);

  const { getToolCategoryListThunk, resetToolCategoryInputAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      resetToolCategoryInputAction();
      getToolCategoryListThunk();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <SRow>
          <Col xs={12} sm={2}>
            <Button
              fullWidth
              onPress={() => navigation.navigate('Add Breakage Category')}
            >
              Add Breakage Category
            </Button>
          </Col>
          <Col xs={12} sm={10}>
            <SearchBar.ToolCategory />
          </Col>
        </SRow>

        <Gap />

        <Card
          toolTipText={'How to use the Breakage Category Screen'}
          toolUrl={'https://www.screencast.com/t/uiGMpc335ub3'}
        >
          <List.ToolCategory navigation={navigation} />
        </Card>
      </Container>
    </Page.Protected>
  );
}

export default ToolCategoryPage;
