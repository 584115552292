import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useIsFocused } from '@react-navigation/native';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditToolCategoryPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const { categoryDescription, categoryCode } = useStoreState(
    (state) => state.tools.toolCategoryInput
  );
  const { setToolCategoryIsEditableAction } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    if (isFocused) {
      setToolCategoryIsEditableAction(false);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.ToolCategory
          navigation={navigation}
          buttons={[
            <Button.EditToolCategory navigation={navigation} />,
            <Button.GoToToolCategory navigation={navigation}>
              Cancel
            </Button.GoToToolCategory>,
            <Button.SaveToolCategory
              navigation={navigation}
              message="Added Location"
              disabled={!categoryCode || !categoryDescription}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
