import { thunk } from 'easy-peasy';
import {
  getWorkPackageAPICall,
  getWorkPackageToolsAPICall,
  getWorkPackageToolsLocationReport,
  upsertWorkPackageAPICall,
  upsertWorkPackageToolAPICall,
} from '../../api/workPackage';
import { removeToolFromWorkPackageAPICall } from '../../api/tool';

const thunks = {
  getWorkPackageToolsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const tenantResponse = await getWorkPackageToolsAPICall({
        id: null,
        workpackageId: helpers.getState().workPackage.input.id,
        tenantId: helpers.getState().tenant.input.id,
        userlocationid: null,
      });
      actions.setWorkPackageToolsSearchResultsAction(tenantResponse.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  getWorkPackageToolsBeforeCheckInThunk: thunk(
    async (actions, data, helpers) => {
      actions.setLoadingAction(true);
      try {
        const tenantResponse = await getWorkPackageToolsAPICall({
          id: null,
          workpackageId: helpers.getState().workPackage.checkinPackageList,
          tenantId: helpers.getState().auth.user.tenantId,
          userlocationid: helpers.getState().auth.user.locationId,
        });
        actions.setWorkPackageToolsSearchResultsAction(tenantResponse.data);
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  getWorkPackageToolThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    const { id, workpackageId } = data;
    try {
      const tenantResponse = await getWorkPackageToolsAPICall({
        id: id || null,
        workpackageId: workpackageId, //helpers.getState().workPackage.input.id,
        tenantId: helpers.getState().tenant.input.id,
      });
      actions.setWorkPackageToolsInputAction(tenantResponse.data[0]);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertWorkPackageToolsThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        // we want to check if the work package tool already exists in the work package tool list
        const blah = helpers
          .getState()
          .workPackageTools.input.toolpartNumber.split(',')
          .map((id) => {
            return helpers
              .getState()
              .tools.searchResults.find((tool) => tool.id == id).partNumber;
          })
          .join(',');
        await upsertWorkPackageToolAPICall({
          // ...input,
          // id: helpers.getState().workPackageTools.input.id || null,
          workpackageId: helpers.getState().workPackage.input.id,
          workpackageName: helpers.getState().workPackage.input.name,
          tenantId: helpers.getState().tenant.input.id,
          toolpartNumber:
            // helpers.getState().workPackageTools.input.toolpartNumber,
            // this is a list of comma delimited ids. We want to sent the partNumber from the tool search results
            helpers
              .getState()
              .workPackageTools.input.toolpartNumber.split(',')
              .map((id) => {
                return helpers
                  .getState()
                  .tools.searchResults.find((tool) => tool.id == id).partNumber;
              })
              .join(','),
        });
        actions.setAlertThunk({
          type: 'success',
          message,
        });
        navigation.goBack();
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  removeWorkPackageToolThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await removeToolFromWorkPackageAPICall({
          id: helpers.getState().workPackageTools.input.id,
          workpackageId: helpers.getState().workPackage.input.id,
          tenantId: helpers.getState().tenant.input.id,
        });
        actions.setAlertThunk({
          type: 'success',
          message,
        });
        navigation.goBack();
      } catch (err) {
        console.log(err);
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }
    }
  ),

  getWorkPackageToolLocationReportThunk: thunk(
    //   async (actions, { navigation, input, message }, helpers) => {
    //     actions.setLoadingAction(true);
    // const response = await getWorkPackageAPICall(
    //   helpers.getState().workPackage.input.id
    // );
    // actions.setWorkPackageToolsLocationReportAction(response.data);
    //     actions.setLoadingAction(false);
    //   }
    // ),
    async (actions, data, helpers) => {
      actions.setLoadingAction(true);

      try {
        const response = await getWorkPackageToolsLocationReport(
          helpers.getState().tenant.input.id
        );
        actions.setWorkPackageToolsLocationReportAction(response.data);
      } catch (err) {
        console.log(err);
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }
    }
  ),
};

export default thunks;
