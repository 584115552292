import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import { formatStringForSearch } from "../../../utilities/general";
import { FontAwesome5 } from "@expo/vector-icons";
import TouchableIcon from "../../presentational/TouchableIcon";

export default function InspectionItemsList({ navigation }) {
	const { searchResults } = useStoreState((state) => ({
		...state.inspectionItems,
		searchResults: state.inspectionItems.searchResults.filter((user) =>
			formatStringForSearch(user.name).includes(
				formatStringForSearch(state.inspectionItems.searchInput)
			)
		),
	}));

	const { setInspectionItemsInputAction } = useStoreActions(
		(actions) => actions
	);

	return (
		<List
			tableHead={["Name", "Description", "Edit"]}
			tableRows={searchResults.map((user) => [
				user.name,
				user.description,
				<TouchableIcon
					Component={FontAwesome5}
					name="edit"
					size={20}
					onPress={() => {
						setInspectionItemsInputAction(user);
						navigation.navigate("Edit Inspection Item", { id: user.id });
					}}
				/>,
			])}
		/>
	);
}
