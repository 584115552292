import Button from "../../presentational/Button";

function useOnPress(navigation) {
  return () => {
    navigation.navigate("Inspection Items");
  };
}

export default function GoToInspectionItemButton({
  navigation,
  children = "Inspection Items",
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}
