import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';

export default function WorkOrderSerialModal({ navigation, ...props }) {
  const {
    auth: { user },
    tools: { myToolFilter, checkoutInput, workOrderSerialModal, checkoutMap },
  } = useStoreState((state) => state);

  const {
    getWorkPackageThunk,
    setToolCheckOutModalAction,
    checkoutWorkPackageThunk,
    setWorkOrderSerialModalAction,
    setCheckoutInputAction,
    checkoutToolThunk,
    upsertToolUsageThunk,
    filterToolsThunk,
    setCheckoutMapAction,
  } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      onClose={() => {
        setWorkOrderSerialModalAction(false);
      }}
      isVisible={workOrderSerialModal}
      title='Work Order / Serial Number'
      style={{ width: 500 }}
      width='50%'
      height='50%'
    >
      <Col>
        <Input
          placeholder='Type in Work Order'
          label='Work Order'
          value={checkoutInput.workOrder}
          onChangeText={(workOrder) => setCheckoutInputAction({ workOrder })}
        />
      </Col>
      <Col>
        <Input
          placeholder='Type in Engine Serial Number'
          label='Engine Serial Number'
          value={checkoutInput.engineserialNumber}
          onChangeText={(engineserialNumber) =>
            setCheckoutInputAction({ engineserialNumber })
          }
        />
      </Col>

      <Button
        fullWidth
        onPress={async () => {
          if (!myToolFilter) {
            await checkoutToolThunk({
              navigation,
            });
            await upsertToolUsageThunk({
              isCheckIn: false,
              tools: Object.values(checkoutMap),
            });
            setCheckoutMapAction({});
            filterToolsThunk('');
            setToolCheckOutModalAction(false);
            setWorkOrderSerialModalAction(false);
          } else {
            checkoutWorkPackageThunk({
              navigation,
            });
            getWorkPackageThunk({
              id: null,
              tenantId: user.tenantId,
            });
            setToolCheckOutModalAction(false);
          }
        }}
      >
        {!myToolFilter ? 'Check-Out Tool (s)' : 'Check-Out Work Package'}
      </Button>
    </CustomModal>
  );
}
