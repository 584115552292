import { action } from 'easy-peasy';

const actions = {
  setInspectionsListAction: action((state, list) => {
    state.inspections.list = list;
  }),
  setInspectionsInputAction: action((state, input) => {
    state.inspections.input = { ...input };
  }),
  setInspectionsEditAction: action((state, isEdit) => {
    state.inspections.isEdit = isEdit;
  }),
  setInspectionsShowModalAction: action((state, showModal) => {
    state.inspections.showModal = showModal;
  }),
  setInspectionsToolIdAction: action((state, toolId) => {
    state.inspections.toolId = toolId;
  }),
};

export default actions;
