import Chart from 'react-google-charts';
import { TouchableOpacity } from 'react-native';

function ComboChartGraph({ onPress, data, height, yAxis, fillColor }) {
  const options = {
    vAxis: yAxis,
    seriesType: 'bars',
  };
  return (
    <TouchableOpacity onPress={onPress}>
      <Chart
        chartType='ComboChart'
        width='100%'
        height={height}
        data={data}
        options={options}
      ></Chart>
    </TouchableOpacity>
  );
}

export default ComboChartGraph;
