import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditDropdownPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();
  const { isEditable } = useStoreState((state) => state.dropdown);
  const { setIsEditableDropdownAction } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      setIsEditableDropdownAction(false);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.Dropdown
          navigation={navigation}
          buttons={[
            <Button.GoToLabels navigation={navigation}>
              Cancel
            </Button.GoToLabels>,
            <Button.EditDropdown navigation={navigation} disabled={false} />,
            <Button.SaveDropdown
              navigation={navigation}
              message="Added Dropdown"
              disabled={!isEditable}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
