import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Entypo } from '@expo/vector-icons';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import Icon from '../../presentational/Icon';
import Gap from '../../presentational/Gap';

import Row from '../../presentational/Row';
import { Platform } from 'react-native';
import { useEffect, useState } from 'react';

export default function TransferToolModal({ onSubmit }) {
  const [toOptions, setToOptions] = useState([]);
  const {
    tools: { toolTransferInput, showToolTransferModal },
    location: { searchResults, locationMap },
  } = useStoreState((state) => state);
  const { setTransferModalAction, setToolTransferInputAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (toolTransferInput.fromtoollocationid) {
      setToOptions(
        searchResults.filter(
          (x) => x.id !== toolTransferInput.fromtoollocationid
        )
      );
    } else {
      setToOptions(searchResults);
    }
  }, [searchResults, toolTransferInput.fromtoollocationid, setToOptions]);

  return (
    <CustomModal
      onClose={() => {
        setToolTransferInputAction({
          id: null,
          fromtoollocationid: null,
          totoollocationid: null,
          trackingnumber: null,
          amount: null,
        });
        setTransferModalAction(false);
      }}
      isVisible={showToolTransferModal}
      title='Request Transfer'
      style={{ width: 800 }}
      width='80%'
      height={Platform.OS == 'web' ? '50%' : '64%'}
    >
      <Row>
        <Col>
          <Input
            required
            placeholder='From Location'
            label='From'
            value={
              locationMap[toolTransferInput.fromtoollocationid]?.name || ''
            }
            onSelect={(location) => {
              setToolTransferInputAction({
                ...toolTransferInput,
                fromtoollocationid: location.id,
              });
            }}
            editable
            selectedItem={(item) => item.name}
            rowTextForSelection={(item) => item.name}
            dropdown
            dropdownChoices={searchResults
              .sort((a, b) => a.name.localeCompare(b.name))
              .filter((item) => item.active)}
            width={Platform.OS == 'web' ? '95%' : '98%'}
            search={true}
            searchInputStyle={{
              width: '100%',
              alignSelf: 'center',
              backgroundColor: '#F7F7F7',
              border: 0,

              boxShadow: '0px 0px 10px grey',
              borderColor: '#F7F7F7',
            }}
            searchInputTxtStyle={{
              fontFamily: 'Barlow_400Regular',
              fontSize: 16,
            }}
            renderSearchInputRightIcon={() => (
              <Icon Component={Entypo} name='magnifying-glass' />
            )}
            searchInputPlaceholder='Search for Location'
          />
        </Col>
        <Col>
          <Input
            required
            placeholder='To Location'
            label='To'
            value={locationMap[toolTransferInput.totoollocationid]?.name || ''}
            onSelect={(location) => {
              setToolTransferInputAction({
                ...toolTransferInput,
                totoollocationid: location.id,
              });
            }}
            editable
            selectedItem={(item) => item.name}
            rowTextForSelection={(item) => item.name}
            dropdown
            dropdownChoices={toOptions
              .sort((a, b) => a.name.localeCompare(b.name))
              .filter((item) => item.active)}
            width={Platform.OS == 'web' ? '95%' : '98%'}
            search={true}
            searchInputStyle={{
              width: '100%',
              alignSelf: 'center',
              backgroundColor: '#F7F7F7',
              border: 0,

              boxShadow: '0px 0px 10px grey',
              borderColor: '#F7F7F7',
            }}
            searchInputTxtStyle={{
              fontFamily: 'Barlow_400Regular',
              fontSize: 16,
            }}
            renderSearchInputRightIcon={() => (
              <Icon Component={Entypo} name='magnifying-glass' />
            )}
            searchInputPlaceholder='Search for Location'
          />
        </Col>
      </Row>
      <Gap />
      <Button
        fullWidth
        disabled={
          !(
            toolTransferInput.fromtoollocationid &&
            toolTransferInput.totoollocationid
          )
        }
        onPress={async () => {
          setToolTransferInputAction({
            ...toolTransferInput,
            status: 'Pending',
            amount: null,
            trackingnumber: null,
          });
          onSubmit();
        }}
      >
        Request
      </Button>
    </CustomModal>
  );
}
