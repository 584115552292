import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';
import { View } from 'react-native';
import { CheckBox } from '@rneui/themed';
import { remToDp } from '../../../utilities/general';

export default function EngineVariantList({ data }) {
  const { selectedEngine } = useStoreState((state) => state.engines);
  const {
    setVariantInputAction,
    setShowVariantModalAction,
    setSelectedEngineAction,
    removeVariantThunk,
  } = useStoreActions((actions) => actions);

  const onDelete = async (variant, index) => {
    if (variant.EngineVariantID) {
      await removeVariantThunk(variant);
    }

    selectedEngine.variants.splice(index, 1);
    setSelectedEngineAction({ ...selectedEngine });
  };

  return (
    <List
      tableHead={['Name', 'Is Military', 'Edit', 'Remove']}
      tableRows={data.map((variant, index) => [
        variant.EngineVariant,
        <View style={{ flexDirection: 'row' }}>
          <CheckBox
            checked={variant.ismilitaryFlag}
            checkedColor='#007AFF'
            checkedIcon={
              <FontAwesome5
                name='check-square'
                size={remToDp(1.5)}
                color='#007AFF'
              />
            }
            uncheckedIcon={
              <FontAwesome5 name='square' size={remToDp(1.5)} color='#007AFF' />
            }
            containerStyle={{ backgroundColor: 'none' }}
          />
        </View>,
        <TouchableIcon
          Component={FontAwesome5}
          name='edit'
          size={20}
          onPress={() => {
            setVariantInputAction({ input: variant, index });
            setShowVariantModalAction(true);
          }}
        />,
        <TouchableIcon
          Component={FontAwesome5}
          name='trash'
          size={20}
          onPress={() => {
            onDelete(variant, index);
          }}
        />,
      ])}
    />
  );
}
