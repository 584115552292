import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditOEMStatusPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const { oemInput } = useStoreState((state) => state.tools);

  const { setOEMIsEditableAction, upsertAllToolStatusThunk } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    if (isFocused) {
      setOEMIsEditableAction(false);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.OEMStatus
          navigation={navigation}
          buttons={[
            <Button.GoToOEMStatus navigation={navigation}>
              Cancel
            </Button.GoToOEMStatus>,
            <Button
              fullWidth
              onPress={async () => {
                await upsertAllToolStatusThunk({
                  status: oemInput.OEMstatusCode.trim(),
                });
              }}
            >
              Add To All Tools
            </Button>,
            <Button.EditOEMStatus />,
            <Button.SaveOEMStatus
              navigation={navigation}
              message='Added OEM Status'
              disabled={false}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
