import { useStoreActions } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';
import * as DocumentPicker from 'expo-document-picker';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

export default function WorkPackageToolsPage({ navigation, ...props }) {
  const isFocused = useIsFocused();
  const { getWorkPackageToolsThunk } = useStoreActions((actions) => actions);

  useEffect(() => {
    isFocused && getWorkPackageToolsThunk();
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <SRow>
          <Col xs={12} sm={2}>
            <Button
              fullWidth
              onPress={() => {
                navigation.navigate('Add Work Package Tool');
              }}
            >
              Add Work Package Tool
            </Button>
          </Col>
          <Col xs={12} sm={2}>
            <Button
              fullWidth
              onPress={async () => {
                const document = await DocumentPicker.getDocumentAsync({
                  type: 'json/csv',
                });
                console.log(document);
                const b64 = document.uri.split(',')[1];
                await upsertBulkToolsThunk({
                  navigation,
                  input: b64,
                });
              }}
            >
              Bulk Tool List Upload
            </Button>
          </Col>
          <Col xs={12} sm={8}>
            <SearchBar.WorkPackageTools />
          </Col>
        </SRow>

        <Gap />

        <Card>
          <List.WorkPackageTools navigation={navigation} />
        </Card>
      </Container>
    </Page.Protected>
  );
}
