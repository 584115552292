import List from '../../presentational/List';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';

export default function ReservationToolsList({ navigation }) {
  const { reservedTools } = useStoreState((state) => state.tools);

  return (
    <List
      tableHead={[
        'Part Number',
        'Nomenclature',
        'Serial',
        'Reserved By',
        'Reservation Date',
      ]}
      tableRows={reservedTools.map((tool) => [
        tool.partNumber,
        tool.nomenclature || 'N/A',
        tool.serialNumber,
        tool.reservedBy,
        moment(tool.reservationDate).format('MM/DD/YYYY'),
      ])}
    />
  );
}
