import { useStoreActions } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';

import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

export default function InspectionItemPage({ navigation, ...props }) {
  const isFocused = useIsFocused();
  const { getInspectionItemsThunk } = useStoreActions((actions) => actions);

  useEffect(() => {
    isFocused && getInspectionItemsThunk();
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <SRow>
          <Col xs={12} sm={2}>
            <Button
              fullWidth
              onPress={() => navigation.navigate('Add Inspection Item')}
            >
              Add Inspection Item
            </Button>
          </Col>
          <Col xs={12} sm={10}>
            <SearchBar.InspectionItems />
          </Col>
        </SRow>

        <Gap />

        <Card>
          <List.InspectionItems navigation={navigation} />
        </Card>
      </Container>
    </Page.Protected>
  );
}
