import { useStoreActions, useStoreState } from 'easy-peasy';
import { ActivityIndicator, TouchableOpacity, View } from 'react-native';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import { useState } from 'react';
import Input from '../../presentational/Input';
import Button from '../../presentational/Button';
import Text from '../../presentational/Text';

export default function AddReadinessScorecardForm({ navigation }) {
  const { selectedType, scoreCardInputs, loading } = useStoreState(
    (state) => state.readiness
  );

  const {
    setScorecardType,
    updateScorecard,
    resetScorecard,
    upsertReadinessScorecardThunk,
  } = useStoreActions((actions) => actions);

  return (
    <View style={{ border: '1px solid rgb(222, 226, 230)' }}>
      <Row>
        <Col
          xs={12}
          md={2}
          style={{ borderRight: '1px solid rgb(222, 226, 230)' }}
        >
          <Row style={{ borderBottom: '1px solid rgb(222, 226, 230)' }}>
            <Col xs={12}>
              <Text
                style={{
                  fontFamily: 'Barlow_600SemiBold',
                  fontSize: '1.25rem',
                }}
              >
                Scorecards
              </Text>
            </Col>
          </Row>
          {Object.values(scoreCardInputs).map((card) => {
            return (
              <Row
                key={card.type}
                style={{ borderBottom: '1px solid rgb(222, 226, 230)' }}
              >
                <Col
                  xs={12}
                  style={{
                    backgroundColor:
                      card.type === selectedType ? 'lightgrey' : 'white',
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      resetScorecard(selectedType);
                      setScorecardType(card.type);
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'Barlow_400Regular',
                        color: '#00205b',
                        fontSize: '1.25rem',
                      }}
                    >
                      {card.type}
                    </Text>
                  </TouchableOpacity>
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col xs={12} md={10}>
          <>
            <Row>
              <Col xs={12} style={{ alignItems: 'end' }}>
                <View
                  style={{
                    alignItems: 'end',
                  }}
                >
                  <Button
                    onPress={() => upsertReadinessScorecardThunk(selectedType)}
                  >
                    Save
                  </Button>
                </View>
              </Col>
            </Row>
            <Row>
              {scoreCardInputs[selectedType].categories.map((input, index) => {
                return (
                  <Col key={index} xs={12} sm={6}>
                    <Input
                      key={index}
                      placeholder='Score'
                      label={input.label}
                      value={input.value}
                      onChangeText={(text) =>
                        updateScorecard({
                          type: selectedType,
                          value: parseInt(text),
                          index,
                        })
                      }
                      number
                    />
                  </Col>
                );
              })}
            </Row>
          </>
          {loading && (
            <View
              pointerEvents='none'
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#F5FCFF88',
                zIndex: 1000,
              }}
            >
              <ActivityIndicator size='large' />
            </View>
          )}
        </Col>
      </Row>
    </View>
  );
}
