import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function AddTenantPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const { name } = useStoreState((state) => state.tenant.input);
  const { resetTenantInputAction, resetDocumentAction } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    if (isFocused) {
      resetDocumentAction();
      resetTenantInputAction();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.AddTenant
          navigation={navigation}
          buttons={[
            <Button.GoToTenants navigation={navigation}>
              Cancel
            </Button.GoToTenants>,
            <Button.SaveTenant
              navigation={navigation}
              message="Added tenant"
              disabled={!name}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}

export default AddTenantPage;
