import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setToolStatusSearchResultsAction: action((state, searchResults) => {
    state.toolStatus.searchResults = searchResults;
  }),
  setToolStatusSearchInputAction: action((state, searchInput) => {
    state.toolStatus.searchInput = searchInput;
  }),
  setIsEditableToolStatusAction: action((state, isEditable) => {
    state.toolStatus.isEditable = isEditable;
  }),
  setToolStatusInputAction: action((state, input) => {
    state.toolStatus.input = {
      ...state.toolStatus.input,
      ...input,
    };
  }),
  resetToolStatusInputAction: action((state, input) => {
    state.toolStatus.input = defaultInputState;
  }),
};

export default actions;
