import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../Button';
import Input from '../../presentational/Input';
import Switch from '../../presentational/Switch';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import Card from '../../presentational/Card';
import Gap from '../../presentational/Gap';
import { remToDp } from '../../../utilities/general';

const ColSwitch = styled(Col)`
  padding-left: ${remToDp(1.5)}px;
`;

export default function LocationForm({ buttons, navigation, ...props }) {
  const { input, isEditable } = useStoreState((state) => state.location);

  const { setLocationInputAction } = useStoreActions((actions) => actions);

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} lg={props.sideButtons ? 6 : 12}>
          <Col>
            <Input
              placeholder="Type in location"
              label="Location"
              value={input.name}
              onChangeText={(name) => setLocationInputAction({ name })}
              required
              editable={isEditable}
            />
          </Col>

          <Col>
            <Input
              placeholder="Type in Address 1"
              label="Address 1"
              value={input.address1}
              onChangeText={(address1) => setLocationInputAction({ address1 })}
              required
              editable={isEditable}
            />
          </Col>
          <Col>
            <Input
              placeholder="Type in Address 2"
              label="Address 2"
              value={input.address2}
              onChangeText={(address2) => setLocationInputAction({ address2 })}
              editable={isEditable}
            />
          </Col>
          <Col>
            <Input
              placeholder="Type in Address 3"
              label="Address 3"
              value={input.address3}
              onChangeText={(address3) => setLocationInputAction({ address3 })}
              editable={isEditable}
            />
          </Col>
          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in Country"
                label="Country"
                value={input.country}
                onChangeText={(country) => setLocationInputAction({ country })}
                editable={isEditable}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in State"
                label="State"
                value={input.stateProvince}
                onChangeText={(stateProvince) =>
                  setLocationInputAction({ stateProvince })
                }
                editable={isEditable}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in City"
                label="City"
                value={input.city}
                onChangeText={(city) => setLocationInputAction({ city })}
                editable={isEditable}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in Zip Code"
                label="Zip Code"
                value={input.zipPostal}
                onChangeText={(zipPostal) =>
                  setLocationInputAction({ zipPostal })
                }
                editable={isEditable}
              />
            </Col>
          </Row>
        </Col>
        {props.sideButtons && (
          <Col xs={12} lg={6}>
            <Button.GoToUsers navigation={navigation} />
            <Gap />
            <Button.GoToTools navigation={navigation} />
          </Col>
        )}
        <Col>
          <ColSwitch>
            <Switch
              disabled={!isEditable}
              label="Active"
              value={input.active}
              onValueChange={(active) => setLocationInputAction({ active })}
            />
          </ColSwitch>
        </Col>
      </Row>
      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
