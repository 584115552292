import List from "../../presentational/List";
import { action, useStoreActions, useStoreState } from "easy-peasy";
import { formatStringForSearch } from "../../../utilities/general";
import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import TouchableIcon from "../../presentational/TouchableIcon";
import moment from "moment";

export default function WorkPackageReservationList({ navigation }) {
	// const { workPackageReservationList } = useStoreState((state) => ({
	// 	...state.workPackage,
	// 	workPackageReservationList: (
	// 		state.workPackage.workPackageReservationList || []
	// 	).filter((user) =>
	// 		formatStringForSearch(user.workpackageName).includes(
	// 			formatStringForSearch(state.workPackage.searchInput)
	// 		)
	// 	),
	// }));

	const { workPackageReservationList } = useStoreState(
		(state) => state.workPackage
	);
	const {
		setCancelWorkPackageReservationModalVisibleAction,
		setWorkPackageCancelInputAction,
	} = useStoreActions((actions) => actions);

	return (
		<List
			tableHead={["Work Package Name", "Tool Part Number", "Dates", "Cancel"]}
			width={["20%", "40%", "30%", "10%"]}
			tableRows={workPackageReservationList.map((user) => [
				user.name,
				user.description,
				moment(user.reservationStartDate).format("MM/DD/YYYY") +
					" - " +
					moment(user.reservationEndDate).format("MM/DD/YYYY"),
				<TouchableIcon
					Component={MaterialCommunityIcons}
					name="cancel"
					color="#ab2328"
					size={20}
					onPress={() => {
						setWorkPackageCancelInputAction({
							// WPId: user.id,
							reservationstartdate: user.reservationStartDate,
							reservationenddate: user.reservationEndDate,
						});
						setCancelWorkPackageReservationModalVisibleAction(true);
						// cancelWorkPackageReservationThunk({ id: tool.id });
					}}
				/>,
			])}
		/>
	);
}
