import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../Button';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import Card from '../../presentational/Card';
import Gap from '../../presentational/Gap';
import List from '../List';
import styled from 'rn-css';
import { Divider } from '@rneui/themed';

const SRow = styled(Row)`
  align-items: center;
`;

export default function InspectionTypeForm({ buttons, ...props }) {
  const {
    inspectionTypes: { input, isEditable },
  } = useStoreState((state) => state);

  const {
    setInspectionInputAction,
    setInspectionTypeAreYouSureModalVisibleAction,
    addInspectionToToolsThunk,
  } = useStoreActions((actions) => actions);

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} lg={props.sideButtons ? 10 : 12}>
          <Input
            placeholder='Type in Name'
            label='Name'
            value={input.name}
            onChangeText={(name) => setInspectionInputAction({ name })}
            required
            editable={isEditable}
          />
          <Row>
            <Col xs={12} lg={4}>
              <Input
                placeholder='Number of Checkouts'
                label='Default Number of Checkouts'
                value={input.numofcheckoutsThreshold}
                onChangeText={(numofcheckoutsThreshold) =>
                  setInspectionInputAction({
                    ...input,
                    numofcheckoutsThreshold,
                  })
                }
                editable={isEditable}
                number
              />
            </Col>
            <Col xs={12} lg={4}>
              <Input
                placeholder='Number of Days'
                label='Default Number of Days'
                value={input.numofdaysThreshold}
                onChangeText={(numofdaysThreshold) =>
                  setInspectionInputAction({ ...input, numofdaysThreshold })
                }
                number
                editable={isEditable}
              />
            </Col>
            <Col xs={12} lg={4}>
              <Input
                placeholder='Inspection Required'
                label='Default Inspection Required'
                value={input.inspectionRequiredName}
                onSelect={(inspectionRequired) =>
                  setInspectionInputAction({
                    ...input,
                    inspectionRequired: inspectionRequired.value,
                    inspectionRequiredName: inspectionRequired.label,
                  })
                }
                editable={isEditable}
                selectedItem={(item) => item.label}
                rowTextForSelection={(item) => item.label}
                dropdown
                dropdownChoices={[
                  { label: 'Yes', value: 'Y' },
                  { label: 'No', value: 'N' },
                ]}
              />
            </Col>
          </Row>
        </Col>
        {props.sideButtons && (
          <>
            <Col xs={12} lg={2}>
              <Button
                fullWidth
                onPress={() => props.navigation.navigate('Add Inspection Item')}
              >
                Add Inspection Item
              </Button>
              <Gap />
              <Button
                fullWidth
                onPress={async () => {
                  await addInspectionToToolsThunk({
                    inspectionTypeID: input.id,
                    numofcheckoutsThreshold: input.numofcheckoutsThreshold,
                    numofdaysThreshold: input.numofdaysThreshold,
                    inspectionRequired: input.inspectionRequired,
                  });
                }}
              >
                Add To Tools
              </Button>
              <Gap />
              <Button
                color='#ab2328'
                fullWidth
                onPress={() => {
                  setInspectionTypeAreYouSureModalVisibleAction(true);
                }}
              >
                Delete Inspection Type
              </Button>
            </Col>
          </>
        )}
      </Row>

      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
      {props.sideButtons && (
        <Col xs={12} lg={12}>
          <Divider />
          <Gap />
          <List.InspectionItems navigation={props.navigation} />
        </Col>
      )}
    </Card>
  );
}
