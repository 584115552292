import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";

function UsersSearchBar() {
  const { searchInput } = useStoreState((state) => state.user);
  const { setUserSearchInputAction } = useStoreActions((actions) => actions);

  function clearSearchInput() {
    return () => setUserSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setUserSearchInputAction(newVal)}
      placeholder="Search users by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}

export default UsersSearchBar;
