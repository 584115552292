const state = {
  engines: {
    list: [],
    filteredList: [],
    searchInput: '',
    selectedEngine: {
      variants: [],
    },
    showVariantModal: false,
    variantInput: {},
    editIndex: null,
  },
};

export { state as default };
