import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setTenantSearchResultsAction: action((state, searchResults) => {
    state.tenant.searchResults = searchResults;
  }),
  setTenantSearchInputAction: action((state, searchInput) => {
    state.tenant.searchInput = searchInput;
  }),
  setIsEditableTenantAction: action((state, isEditable) => {
    state.tenant.isEditable = isEditable;
  }),
  setTenantInputAction: action((state, input) => {
    state.tenant.input = {
      ...state.tenant.input,
      ...input,
    };
  }),
  resetTenantInputAction: action((state, input) => {
    state.tenant.input = defaultInputState;
  }),

  setTenantLabels: action((state, searchResults) => {
    state.tenant.labels = searchResults;
  }),
  setLabelSearchInputAction: action((state, searchInput) => {
    state.tenant.labelSearchInput = searchInput;
  }),
};

export default actions;
