import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';

function useOnPress(navigation) {
  const {
    setBrokenToolModalVisibleAction,
    resetToolsInputAction,
    resetDocumentAction,
  } = useStoreActions((actions) => actions);

  return () => {
    resetToolsInputAction();
    resetDocumentAction();
    setBrokenToolModalVisibleAction(true);
  };
}

export default function ReportBrokenToolButton({
  navigation,
  children = 'Report Unserviceable Tool',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props} color={'#ab2328'}>
      {children}
    </Button>
  );
}
