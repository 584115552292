import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Ionicons } from '@expo/vector-icons';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import DatePicker from '../DatePicker';
import moment from 'moment';

export default function ReservationModal() {
  const {
    auth: { user },
    tools: {
      myToolFilter,
      reservationModal,
      reservationInput,
      reservationCalendarVisible,
    },
  } = useStoreState((state) => state);

  const {
    getWorkPackageThunk,
    setToolCheckOutModalAction,
    checkoutWorkPackageThunk,

    setReservationModalAction,
    reserveToolThunk,
    setReservationCalendarVisible,
    setReservationInputAction,
  } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      onClose={() => {
        setReservationModalAction(false);
      }}
      isVisible={reservationModal}
      title="Reservation"
      style={{ width: 500 }}
      width="50%"
      height="50%"
    >
      {reservationCalendarVisible && <DatePicker.Reservation />}
      <Col>
        <Input
          placeholder="Type in Reservation Date"
          label="Reservation Date"
          value={
            reservationInput.reservationstartDate &&
            reservationInput.reservationendDate
              ? moment(reservationInput.reservationstartDate).format(
                  'MM/DD/YYYY'
                ) +
                ' - ' +
                moment(reservationInput.reservationendDate).format('MM/DD/YYYY')
              : ''
          }
          onChangeText={(nextPMIDate) =>
            setReservationInputAction({ reservationDate: nextPMIDate })
          }
          onClick={() => {
            setReservationCalendarVisible(!reservationCalendarVisible);
          }}
          rightIcon={<Ionicons name="calendar" size={20} color="#00205b" />}
        />
      </Col>
      <Col>
        <Input
          placeholder="Type in Reservation Notes"
          label="Reservation Notes"
          value={reservationInput.reservationNotes}
          onChangeText={(reservationNotes) =>
            setReservationInputAction({ reservationNotes })
          }
        />
      </Col>

      <Col>
        <Input
          placeholder="Type in Reservation Work Order"
          label="Reservation Work Order"
          value={reservationInput.reservationworkOrder}
          onChangeText={(reservationworkOrder) =>
            setReservationInputAction({ reservationworkOrder })
          }
        />
      </Col>

      <Col>
        <Input
          placeholder="Type in Reservation Engine Number"
          label="Reservation Engine Number"
          value={reservationInput.reservationengineNumber}
          onChangeText={(reservationengineNumber) =>
            setReservationInputAction({ reservationengineNumber })
          }
        />
      </Col>

      <Button
        color="#00205b"
        fullWidth
        onPress={() => {
          if (!myToolFilter) {
            reserveToolThunk({
              navigation,
              input: reservationInput,
            });
            setReservationModalAction(false);
          } else {
            checkoutWorkPackageThunk({
              navigation,
            });
            getWorkPackageThunk({
              id: null,
              tenantId: user.tenantId,
            });
            setToolCheckOutModalAction(false);
          }
        }}
      >
        {!myToolFilter ? 'Reserve Tool (s)' : 'Reserve Work Package'}
      </Button>
    </CustomModal>
  );
}
