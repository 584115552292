import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';
import { vhToDp, vwToDp } from '../../utilities/general';
import CustomModal from '../presentational/Modal';
const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

export default function WorkPackageReservationsPage({ navigation, ...props }) {
  const isFocused = useIsFocused();
  const { input } = useStoreState((state) => state.workPackage);
  const { getWorkPackageReservationListThunk } = useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    isFocused && getWorkPackageReservationListThunk(input.id);
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <CustomModal.CancelWPReservation />
      <Container>
        <SRow>
          <Col xs={12} sm={12}>
            <SearchBar.WorkPackageTools />
          </Col>
        </SRow>

        <Gap />

        <Card>
          <List.WorkPackageReservations navigation={navigation} />
        </Card>
      </Container>
    </Page.Protected>
  );
}
