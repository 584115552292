const defaultInputState = {
  id: null,
  partNumber: '',
  nomenclature: '',
  currentRevision: '',
  serialNumber: '',
  multipleParts: false,
  notes: null,
  manufacturerCageCode: '',
  storageLocation: '',
  nextPMIDate: '',
  PMIComments: null,
  results: null,
  status: null,
  qtyRequired: 0,
  calibrationRequired: false,
  nextCalibrationTestDueDate: '',
  rhinestahlContact: '',
  checkOuts: 0,
  tenantLocation: 0,
  toolLocation: 0,
  phase: 0,
  toolStatus: '',
  fandFCode: '',
  comments: null,
  GVIInstructions: null,
  CFMId: null,
  loadTestRequired: false,
  repositoryId: '',
  repositoryId: '',
  toolStatusCode: '',
  activeFlag: 'Y',
  inspectionTypes: '',
  inspectioncheckoutThreshold: '',
  inspectionnumofdaysThreshold: '',
  inspectionRequired: 'N',
  requirecheckoutNotes: 'N',
  barcodescanid: '',
  loadTestDueDate: '',
};

const reservationInputState = {
  id: null,
  toolId: '',
  WPId: '',
  reservationstartDate: '',
  reservationendDate: '',
  reservationworkOrder: '',
  reservationengineNumber: '',
  reservationNotes: '',
  cancelReservation: '',
};

const state = {
  tools: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    editStepCount: 1,
    input: defaultInputState,
    historyModalActive: false,
    bulkToolString: '',
    nextCalibrationDateCalendarVisible: false,
    nextPMIDateCalendarVisible: false,
    myToolFilter: false,
    toolCheckOutModal: false,
    toolCheckInModal: false,
    checkoutToolList: '',
    checkinToolList: '',
    reserveToolList: '',
    selectedToolInspectionTypes: [],
    showInspectionQuestionsModal: false,

    validCheckInToolList: '',
    validCheckOutToolList: '',
    brokenToolModalVisible: false,
    reservationInput: reservationInputState,

    reservationModal: false,
    showInspectionHistoryModal: false,
    inspectionHistory: [],
    inspectionTypeId: null,
    inspectionInput: {
      id: null,
      inspectiontypeId: null,
      inspectionTypeName: '',
      inspectionitemId: null,
      inspectionItemName: '',
      inspectionItemDesc: '',
      inspectedBy: '',
      inspectionResponse: '',
      inspectionDate: '',
      toolId: '',
    },
    brokenToolEditable: false,
    inspectionQuestions: [],
    currentStep: 1,
    currentSubStep: 1,
    reservationCalendarVisible: false,
    reservedTools: [],
    toolPreCheck: [],

    selectedToolPreCheck: {},
    preCheckToolModal: false,

    workOrderSerialModal: false,

    checkoutInput: {
      toolId: '',
      toolStatusCode: 'CO',
      toolStatusDescription: 'Checked Out',
      workOrder: '',
      engineserialNumber: '',
    },

    brokenToolInput: {
      toolId: null,
      toolStatusCode: 'BRKN',
      toolStatusDescription: 'Unserviceable',
      toolbrokendescription: '',
      brokentoolcategoryDescription: '',
      toolbrokenCategoryCode: '',
      serialNumber: '',
    },

    toolCategoryInput: {
      id: null,
      tenantId: '',
      categoryCode: '',
      categoryDescription: '',
    },

    brokenToolCalendarVisible: false,
    brokenToolHistory: [],
    toolCategoryList: [],
    toolCategorySearchInput: '',
    toolCategoryIsEditable: false,
    brokenToolSearchInput: '',
    oemStatusList: [],
    oemSearchInput: '',

    oemInput: {
      id: null,
      tenantId: null,
      OEMstatusCode: '',
      OEMstatusDescription: '',
    },

    oemIsEditable: false,
    scannerOpen: false,
    hasCameraPermission: null,
    toolInformationModalVisible: false,
    loadTestDatePickerActive: false,
    isNewToolPhoto: false,
    toolHistory: [],
    toolHistoryMap: {},
    showToolHistoryModal: false,
    uniquePartList: [],
    toolTransfers: [],
    toolTransferInput: { id: null },
    showToolTransferModal: false,
    transferSearchInput: '',
    showApproveTransferModal: false,
    showReceiveTransferModal: false,
    transferIsSelected: false,
    selectedTransfer: {},
    reports: {},
    showReportsModal: false,
    reportType: '',
    toolsByEngineMap: {},
    toolsByEngine: [],
    toolsByStatusMap: {},
    toolsByOEMStatusMap: {},
    reportListData: [],
    reportsLoading: false,
    usageList: [],
    usageMap: {},
    checkoutComment: '',
    checkInComment: '',
    checkoutMap: {},
    overriddenTools: [],
  },
};

export { state as default, defaultInputState };
