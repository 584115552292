import { thunk } from 'easy-peasy';
import {
  getReadinessScorecardAPICall,
  upsertReadinessScorecardAPICall,
} from '../../api/report';

const thunks = {
  getReadinessScorecardsThunk: thunk(async (actions, value, helpers) => {
    actions.setScorecardLoading(true);
    actions.setLoadingAction(true);
    try {
      const response = await getReadinessScorecardAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.initializeScorecardData(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  upsertReadinessScorecardThunk: thunk(async (actions, cardType, helpers) => {
    actions.setScorecardLoading(true);
    try {
      const scorecard = helpers.getState().readiness.scoreCardInputs[cardType];
      const body = {
        ...scorecard,
        categories: JSON.stringify(scorecard.categories),
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      };
      await upsertReadinessScorecardAPICall(body);
      actions.resetScorecard(cardType);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setScorecardLoading(false);
  }),
};

export default thunks;
