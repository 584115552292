import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

function StorageLocationSearchBar() {
  const { searchInput } = useStoreState((state) => state.storageLocation);
  const { setStorageLocationSearchInputAction } = useStoreActions(
    (actions) => actions
  );

  function clearSearchInput() {
    return () => setStorageLocationSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setStorageLocationSearchInputAction(newVal)}
      placeholder="Search storage locations by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}

export default StorageLocationSearchBar;
