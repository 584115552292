import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import Card from '../../presentational/Card';
// import EntypoIcon from 'react-native-vector-icons/Entypo';
import Icon from '../../presentational/Icon';
import { Entypo } from '@expo/vector-icons';
import { remToDp } from '../../../utilities/general';
import Button from '../Button';
import SelectBox from 'react-native-multi-selectbox';
import { xorBy } from 'lodash';
import React from 'react';
import { View } from 'react-native';

export default function AddWorkPackageToolForm({ buttons, ...props }) {
  const {
    workPackageTools: { input, isEditable, searchResults },
    workPackage,
    tools,
    document,
  } = useStoreState((state) => state);

  const { setWorkPackageToolsInputAction } = useStoreActions(
    (actions) => actions
  );

  function onSelectedItemsChange(item) {
    return (item) => {
      if (!item) {
        console.warn('onSelectedItemsChange called with undefined item');
        return;
      }
      let currentIds = input.toolpartNumber
        ? input.toolpartNumber.split(',')
        : [];

      if (currentIds.includes(item.id.toString())) {
        currentIds = currentIds.filter((id) => id !== item.id.toString());
      } else {
        currentIds.push(item.id.toString());
      }
      let newToolPartNumber = currentIds.join(',');

      setWorkPackageToolsInputAction({
        toolpartNumber: newToolPartNumber,
      });
    };
  }

  function getObjectsByIds(ids, objectList) {
    if (!ids || !objectList) return [];

    if (typeof ids === 'string') {
      ids = ids.split(',');
    }

    return objectList.filter((obj) => ids.includes(obj.id.toString()));
  }
  // Write a function that breaks down the partnumber comma delimited string into an array of strings
  // Then remove the options that are already in 'searchResults' from the options array

  function removeItemsByIds() {
    let currentIds = searchResults.map((item) => item.toolpartNumber);

    let list = tools.uniquePartList.filter(
      (item) => !currentIds.includes(String(item.partNumber))
    );
    console.log(currentIds, list);

    return list.map((item) => {
      return { item: item.partNumber, id: item.id };
    });
  }

  return (
    <Card>
      <Row {...props}>
        <Col>
          <Row>
            <Col xs={12} md={props.sideButtons ? 5 : 6}>
              <Input
                placeholder='Type in Work Package Name'
                label='Work Package Name'
                value={workPackage.input.name}
                onChangeText={(workpackageName) =>
                  setWorkPackageToolsInputAction({
                    workpackageName: workPackage.input.name,
                  })
                }
                required
                editable={false}
              />
            </Col>
            <Col xs={12} md={props.sideButtons ? 5 : 6}>
              <View style={{ width: '100%' }}>
                <SelectBox
                  containerStyle={{
                    overflow: 'auto',
                    width: '100%',
                    top: 10,
                    left: 10,
                  }}
                  placeholder='Type in Tool Part Number'
                  label='Tool Part Number'
                  labelStyle={{
                    fontFamily: 'Barlow_400Regular',
                    color: '#00205b',
                    fontSize: 16,
                  }}
                  // options={tools.searchResults.map((item) => {
                  //   return { item: item.partNumber, id: item.id };
                  // })}

                  options={removeItemsByIds()}
                  selectedValues={getObjectsByIds(
                    input.toolpartNumber ||
                      [].map((item) => item.id).join(', '),
                    tools.searchResults
                  )}
                  onMultiSelect={onSelectedItemsChange()}
                  multiOptionContainerStyle={{
                    backgroundColor: '#AB2328',
                    color: '#fff',
                  }}
                  multiOptionsLabelStyle={{
                    backgroundColor: '#AB2328',
                    fontFamily: 'Barlow_400Regular',
                    fontSize: 15,
                    color: '#fff',
                  }}
                  optionsLabelStyle={{
                    fontFamily: 'Barlow_400Regular',
                    color: '#AB2328',
                  }}
                  onTapClose={onSelectedItemsChange()}
                  isMulti
                />
              </View>
            </Col>
            {props.sideButtons && (
              <Col xs={12} md={2}>
                <Button.RemoveWorkPackageTool navigation={props.navigation} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
