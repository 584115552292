import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';

function useOnPress(navigation) {
  const {
    setIsEditableToolsAction,
    setToolsSearchResultsAction,
    setToolsSearchInputAction,
  } = useStoreActions((actions) => actions);

  return () => {
    setIsEditableToolsAction(false);
    setToolsSearchResultsAction([]);
    setToolsSearchInputAction('');
    navigation.navigate('Tools');
  };
}

export default function GoToToolsButton({
  navigation,
  children = 'Tools',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}
