import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import Card from '../../presentational/Card';
import Button from '../Button';
import List from '../List';
import Gap from '../../presentational/Gap';

export default function EngineFamilyForm({ navigation, ...props }) {
  const { selectedEngine } = useStoreState((state) => state.engines);
  const {
    setSelectedEngineAction,
    setVariantInputAction,
    setShowVariantModalAction,
  } = useStoreActions((actions) => actions);

  return (
    <Card>
      <Row {...props}>
        <Col xs={12} md={5}>
          <Input
            placeholder='Engine Family Name'
            label='Engine Family Name'
            value={selectedEngine?.EngineFamily}
            onChangeText={(name) =>
              setSelectedEngineAction({ EngineFamily: name })
            }
            required
          />
        </Col>
        <Col xs={12} md={5}>
          <Input
            required
            placeholder='Engine Family Description'
            label='Engine Family Description'
            value={selectedEngine?.EngineFamilyDescription}
            onChangeText={(description) =>
              setSelectedEngineAction({
                EngineFamilyDescription: description,
              })
            }
          />
        </Col>
        <Col xs={12} md={2} style={{ justifyContent: 'center' }}>
          <Button
            fullWidth
            onPress={() => {
              setVariantInputAction({
                input: {
                  EngineVariantID: null,
                  EngineVariant: null,
                  ismilitaryFlag: false,
                },
                index: null,
              });
              setShowVariantModalAction(true);
            }}
          >
            Add Variant
          </Button>
        </Col>
      </Row>
      <Gap />
      <Row>
        <Col xs={12} md={12}>
          <List.EngineVariant data={selectedEngine.variants} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Button.GoToEngineFamilies navigation={navigation}>
            Cancel
          </Button.GoToEngineFamilies>
        </Col>
        <Col xs={12} md={6}>
          <Button.SaveEngineFamily
            disabled={
              !selectedEngine.EngineFamilyDescription ||
              !selectedEngine.EngineFamily
            }
          >
            Save
          </Button.SaveEngineFamily>
        </Col>
      </Row>
    </Card>
  );
}
