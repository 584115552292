const defaultInputState = {
  id: null,
  name: '',
};

const state = {
  inspectionTypes: {
    map: {},
    searchResults: [],
    dropdownOptions: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
    inspectionTypeAreYouSureModalVisible: false,
  },
};

export { state as default, defaultInputState };
