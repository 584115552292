import { useStoreActions, useStoreState } from "easy-peasy";
import Input from "../../presentational/Input";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Card from "../../presentational/Card";

export default function ToolStatusForm({ buttons, ...props }) {
	const {
		toolStatus: { input, isEditable },
	} = useStoreState((state) => state);

	const { setToolStatusInputAction } = useStoreActions((actions) => actions);

	return (
		<Card>
			<Row {...props}>
				<Col>
					<Row>
						<Col xs={12} md={6}>
							<Input
								placeholder="Type in Status Code"
								label="Status Code"
								value={input.statusCode}
								onChangeText={(statusCode) =>
									setToolStatusInputAction({ statusCode })
								}
								editable={input.id ? false : isEditable} //}
							/>
						</Col>
						<Col xs={12} md={6}>
							<Input
								placeholder="Type in Status Description"
								label="Status Description"
								value={input.statusDescription}
								onChangeText={(statusDescription) =>
									setToolStatusInputAction({ statusDescription })
								}
								editable={isEditable}
							/>
						</Col>
						{/* <Col xs={12} md={6}>
							<Input
								placeholder="Type in Default Value"
								label="Default Value"
								value={input.defaultValue}
								onChangeText={(defaultValue) =>
									setToolStatusInputAction({ defaultValue })
								}
								editable={isEditable}
							/>
						</Col> */}
					</Row>

					<Row></Row>
				</Col>
			</Row>

			<Row>
				{buttons.map((button, index) => (
					<Col xs={12} md={12 / buttons.length} key={index}>
						{button}
					</Col>
				))}
			</Row>
		</Card>
	);
}
