import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';
import CustomModal from '../presentational/Modal';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditWorkPackageToolPage({
  route,
  navigation,
  ...props
}) {
  const isFocused = useIsFocused();
  const { workPackage } = useStoreState((state) => state);
  const { id } = workPackage.input;
  const { setIsEditableWorkPackageToolsAction, getWorkPackageToolThunk } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      getWorkPackageToolThunk({ id: route.params.id, workpackageId: id });
      setIsEditableWorkPackageToolsAction(false);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <CustomModal.AreYouSureWorkPackageTool navigation={navigation} />

      <Container>
        <Form.EditWorkPackageTool
          sideButtons
          navigation={navigation}
          buttons={[
            <Button.EditWorkPackageTool navigation={navigation} />,

            <Button.GoToWorkPackages navigation={navigation}>
              Cancel
            </Button.GoToWorkPackages>,
            <Button.SaveWorkPackageTool
              navigation={navigation}
              message="Added Tool"
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
