import { useStoreActions } from "easy-peasy";
import Page from "../containers/Page";
import { useEffect } from "react";
import SearchBar from "../containers/SearchBar";
import List from "../containers/List";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import Card from "../presentational/Card";
import Gap from "../presentational/Gap";
import Row from "../presentational/Row";
import Button from "../presentational/Button";
import Col from "../presentational/Col";

import { vhToDp, vwToDp } from "../../utilities/general";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
	align-items: center;
`;

export default function ToolStatusPage({ navigation, ...props }) {
	const isFocused = useIsFocused();

	const { getToolStatusThunk } = useStoreActions((actions) => actions);

	useEffect(() => {
		if (isFocused) {
			getToolStatusThunk();
		}
	}, [isFocused]);

	return (
		<Page.Protected {...props}>
			<Container>
				<SRow>
					<Col xs={12} sm={2}>
						<Button
							fullWidth
							onPress={() => navigation.navigate("Add Tool Status")}
						>
							Add Tool Status
						</Button>
					</Col>

					<Col xs={12} sm={10}>
						<SearchBar.ToolStatus />
					</Col>
				</SRow>

				<Gap />

				<Card
					toolTipText={"How to use the Tool Status Screen"}
					toolUrl={"https://www.screencast.com/t/AsvW1OH4cAk"}
				>
					<List.ToolStatus navigation={navigation} />
				</Card>
			</Container>
		</Page.Protected>
	);
}
