import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function EditLabelButton({
  navigation,
  children = 'Edit',
  message,
  ...props
}) {
  const {
    labels: { isEditable },
  } = useStoreState((state) => state);

  const { setIsEditableLabelAction } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() => setIsEditableLabelAction(true)}
      disabled={isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
