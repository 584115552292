import Page from "../containers/Page";
import Form from "../containers/Form";
import styled from "styled-components/native";
import Button from "../containers/Button";
import { useIsFocused } from "@react-navigation/native";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import Row from "../presentational/Row";
import { vhToDp, vwToDp } from "../../utilities/general";
import CustomModal from "../presentational/Modal";

import ExcelJS from "exceljs";
import moment from "moment";
import download from "downloadjs";
import { View } from "react-native";

const exportToCSV = async (data) => {
	const now = new Date();
	const fileName = `WorkPackageToolLocations_${moment().format(
		"MM/DD/YYYY"
	)}.xlsx`;

	return new Promise((resolve, reject) => {
		const workbook = new ExcelJS.Workbook();
		workbook.creator = "Me";
		workbook.created = now;
		workbook.modified = now;

		const worksheet = workbook.addWorksheet("My Sheet", {});

		worksheet.columns = Object.keys(data[0]).map((key) => ({
			header: key,
			key,
		}));

		data.forEach((user) => {
			worksheet.addRow(user);
		});

		worksheet.getRow(1).eachCell((cell) => {
			cell.font = { bold: true };
		});

		worksheet.columns.forEach((column) => {
			let dataMax = column.values.reduce((max, rowVal) => {
				if (rowVal !== null && rowVal.toString().length > max) {
					return rowVal.toString().length;
				}
				return max;
			}, 0);
			column.width = dataMax < 10 ? 10 : dataMax;
		});

		workbook.xlsx
			.writeBuffer()
			.then((buffer) => {
				const blob = new Blob([buffer], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				});
				download(
					blob,
					fileName,
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				);
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
	align-items: center;
`;

export default function EditWorkPackagePage({ route, navigation, ...props }) {
	const isFocused = useIsFocused();

	const {
		workPackage: { isEditable },
		workPackageTools: { workPackageToolsLocationReport, searchResults },
	} = useStoreState((state) => state);
	const {
		setWorkPackageInputAction,
		getWorkPackageToolsThunk,
		getWorkPackagesThunk,
		setIsEditableWorkPackageAction,
		getToolsThunk,
		getWorkPackageToolLocationReportThunk,
	} = useStoreActions((actions) => actions);

	useEffect(() => {
		if (isFocused) {
			getToolsThunk(0);
			setWorkPackageInputAction({ id: route.params.id });
			getWorkPackagesThunk(route.params.id);
			getWorkPackageToolsThunk({ id: null, workpackageId: route.params.id });
			setIsEditableWorkPackageAction(false);
			getWorkPackageToolLocationReportThunk();
		}
	}, [isFocused]);

	return (
		<>
			<Page.Protected {...props}>
				<Container>
					<CustomModal.CopyWorkPackageToolList navigation={navigation} />
					<CustomModal.CopyWorkPackageToTenant navigation={navigation} />
					<CustomModal.AreYouSureWorkPackage navigation={navigation} />
					<CustomModal.WorkPackageReservation navigation={navigation} />
					<Form.WorkPackage
						sideButtons={true}
						navigation={navigation}
						buttons={[
							<Button.EditWorkPackage navigation={navigation} />,
							<Button.GoToWorkPackages navigation={navigation}>
								Cancel
							</Button.GoToWorkPackages>,
							<Button.SaveWorkPackage
								navigation={navigation}
								message="Added Tool"
								disabled={!isEditable}
							/>,
						]}
					/>
				</Container>
			</Page.Protected>
			<View
				style={{
					position: "absolute",
					right: 20,
					bottom: 20,
					justifyContent: "space-between",
					flexDirection: "row",
				}}
			>
				<Button
					fullWidth
					onPress={() => {
						exportToCSV(searchResults);
					}}
				>
					Generate Tool CSV
				</Button>
			</View>
		</>
	);
}
