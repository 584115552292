import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import Image from '../../presentational/Image';
import { vhToDp } from '../../../utilities/general';
import { Platform } from 'react-native';

export default function DocumentListImageModal() {
  const { status, document, docImage } = useStoreState((state) => state);

  const { showDocumentModalAction } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      style={{
        width: Platform.OS == 'web' ? 500 : 1000,
      }}
      width={Platform.OS == 'web' ? '50%' : 1000}
      title='Tool Image'
      onClose={() => showDocumentModalAction(false)}
      isVisible={status.showDocumentModal}
    >
      {docImage.includes('data:image') && (
        <Image
          style={{ width: '100%', height: vhToDp(25) }}
          uri={docImage !== '' ? docImage : status.placeholder}
        />
      )}
    </CustomModal>
  );
}
