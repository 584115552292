import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function EditStorageLocationButton({
  children = 'Edit',
  ...props
}) {
  const { isEditable } = useStoreState((state) => state.storageLocation);

  const { setIsEditableStorageLocationAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={() => setIsEditableStorageLocationAction(true)}
      disabled={isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
