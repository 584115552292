import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function SaveToolButton({
  navigation,
  children = 'Save',
  message,
  ...props
}) {
  const {
    tools: { input },
    document,
  } = useStoreState((state) => state);

  const { upsertToolsThunk, upsertDocumentThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={async () => {
        await upsertDocumentThunk({
          navigation,
          document,
          message: 'Document Upserted',
        });
        await upsertToolsThunk({
          navigation,
          input,
          message,
        });
      }}
      // disabled={!isEditable || !input.name}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
