import Page from "../containers/Page";
import Form from "../containers/Form";
import styled from "styled-components/native";
import Button from "../containers/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useIsFocused } from "@react-navigation/native";

import { vhToDp, vwToDp } from "../../utilities/general";
import CustomModal from "../presentational/Modal";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditInspectionTypesPage({
	route,
	navigation,
	...props
}) {
	const isFocused = useIsFocused();
	const { isEditable } = useStoreState((state) => state.inspectionTypes);

	const { setIsEditableInspectionAction, getInspectionItemsThunk } =
		useStoreActions((actions) => actions);

	useEffect(() => {
		if (isFocused) {
			setIsEditableInspectionAction(false);
			getInspectionItemsThunk();
		}
	}, [isFocused]);

	return (
		<Page.Protected {...props}>
			<Container>
				<CustomModal.AreYouSureInspectionType navigation={navigation} />

				<Form.InspectionType
					sideButtons={true}
					navigation={navigation}
					buttons={[
						<Button.EditInspectionType navigation={navigation} />,
						<Button.GoToInspection navigation={navigation}>
							Cancel
						</Button.GoToInspection>,
						<Button.SaveInspectionType
							navigation={navigation}
							message="Added Inspection Type"
							disabled={!isEditable}
						/>,
					]}
				/>
			</Container>
		</Page.Protected>
	);
}
