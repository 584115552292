import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

function SaveTenantButton({
  navigation,
  children = 'Save',
  message,
  ...props
}) {
  const {
    tenant: { isEditable, input },
    document,
  } = useStoreState((state) => state);

  const { upsertTenantThunk, upsertDocumentThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={async () => {
        await upsertDocumentThunk({ navigation, document, message });
        await upsertTenantThunk({
          navigation,
          input,
          message,
        });
      }}
      disabled={!isEditable || !input.name}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}

export default SaveTenantButton;
