import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import { Platform, ScrollView } from 'react-native';
import { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import Text from '../../presentational/Text';
import Row from '../../presentational/Row';
import moment from 'moment';

export default function ToolHistoryModal() {
  const [selectedId, setSelectedId] = useState(null);
  const {
    tools: { toolHistory, toolHistoryMap, showToolHistoryModal },
  } = useStoreState((state) => state);

  const Item = ({ type, items }) => {
    const isExpanded = type === selectedId;
    return (
      <View>
        <TouchableOpacity
          onPress={() => setSelectedId(isExpanded ? null : type)}
          style={{
            width: '70vh',
            padding: 10,
            borderWidth: 0.1,
            borderColor: 'lightgray',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: isExpanded ? 'lightgray' : 'white',
          }}
        >
          <MaterialIcons
            name={isExpanded ? 'keyboard-arrow-down' : 'keyboard-arrow-right'}
            size={24}
            color='black'
          />
          <Text style={{ fontSize: 20, color: '#ab2328', marginLeft: 10 }}>
            Work Order: {type}
          </Text>
        </TouchableOpacity>
        {isExpanded && (
          <>
            <Text
              style={{
                fontSize: 16,
                margin: 5,
                fontFamily: 'Barlow_600SemiBold',
              }}
            >
              Engine Serial Number / Aircraft: {items[0]?.engineserialNumber}
            </Text>
            {items.map((item, index) => (
              <View key={item.id}>
                <Text
                  style={{
                    fontSize: 16,
                    margin: 5,
                    fontFamily: 'Barlow_600SemiBold',
                  }}
                >
                  Status: {item.statusDescription}
                </Text>
                <Row>
                  <Text
                    style={{
                      fontSize: 14,
                      marginLeft: 15,
                    }}
                  >
                    User:
                  </Text>

                  <Text
                    style={{
                      fontSize: 14,
                      marginLeft: 15,
                      fontFamily: 'Barlow_500Medium_Italic',
                    }}
                  >
                    {` ${item.tooluser} (${moment
                      .utc(item?.datemodified)
                      .format('MM/DD/YYYY')})`}
                  </Text>
                </Row>
              </View>
            ))}
          </>
        )}
      </View>
    );
  };

  const { setToolHistoryModalAction } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      isVisible={showToolHistoryModal}
      title='Tool History'
      onClose={() => {
        setToolHistoryModalAction(false);
      }}
      style={{
        height: Platform.OS == 'web' ? '50vh' : '50%',
        width: Platform.OS == 'web' ? '70vh' : '70%',
      }}
    >
      <ScrollView
        style={{
          height: Platform.OS == 'web' ? '40vh' : '40%',
        }}
      >
        <View style={{ width: '70%' }}>
          {toolHistory.map((type) => (
            <Item key={type} type={type} items={toolHistoryMap[type].entries} />
          ))}
        </View>
      </ScrollView>
    </CustomModal>
  );
}
