import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function AddWorkPackagePage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const { name } = useStoreState((state) => state.workPackage.input);
  const {
    resetWorkPackageInputAction,
    setIsEditableWorkPackageAction,
    setWorkPackageToolsSearchResultsAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      setIsEditableWorkPackageAction(true);
      resetWorkPackageInputAction();
      setWorkPackageToolsSearchResultsAction([]);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.WorkPackage
          navigation={navigation}
          buttons={[
            <Button.GoToWorkPackages navigation={navigation}>
              Cancel
            </Button.GoToWorkPackages>,
            <Button.SaveWorkPackage
              navigation={navigation}
              message="Added Tool"
              disabled={!name}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
