import { axios } from ".";

const tenantLabelAxios = axios.create({
	// baseURL: `https://tmpbackenduat.azurewebsites.net/label`,
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/label`,
});

function getLabelAPICall(id) {
	return tenantLabelAxios({ method: "get", url: `/get/${id}` });
}

function upsertLabelAPICall(data) {
	return tenantLabelAxios({ method: "put", url: `/upsert`, data });
}

export { tenantLabelAxios, getLabelAPICall, upsertLabelAPICall };
