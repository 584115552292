import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import { useState } from 'react';

export default function ForcePasswordChangeModal({ ...props }) {
  const {
    auth: { passwordChangeModal, passwordChangeInput },
  } = useStoreState((state) => state);

  const {
    setPasswordChangeInputAction,
    setPasswordChangeModalAction,
    changePasswordThunk,
  } = useStoreActions((actions) => actions);

  const [matchPW, setMatchPW] = useState('');

  return (
    <CustomModal
      onClose={() => {
        setPasswordChangeModalAction(false);
      }}
      isVisible={passwordChangeModal}
      title="Update Password"
      style={{ width: 500 }}
      width="50%"
      height="50%"
    >
      <Col>
        <Input
          placeholder="Type in New Password"
          label="New Password"
          value={matchPW}
          onChangeText={(workOrder) => setMatchPW(workOrder)}
        />
      </Col>
      <Col>
        <Input
          placeholder="Type in Confirm Password"
          label="Confirm Password"
          value={passwordChangeInput.password}
          onChangeText={(password) =>
            setPasswordChangeInputAction({ password })
          }
        />
      </Col>

      <Button
        disabled={matchPW != passwordChangeInput.password}
        onPress={() => {
          changePasswordThunk(passwordChangeInput);
        }}
        fullWidth
      >
        {'Change Password'}
      </Button>
    </CustomModal>
  );
}
