import NextCalibrationDatePicker from "./Tools/NextCalibrationDatePicker";
import { View as DatePicker } from "react-native";
import NextPMIDatePicker from "./Tools/NextPMIDatePicker";
import ReservationDatePicker from "./Tools/ReservationDatePicker";
import WorkPackageReservationDatePicker from "./WorkPackageReservationDatePicker";
import LoadTestDueDatePicker from "./Tools/LoadTestDueDatePicker";

DatePicker.NextCalibration = NextCalibrationDatePicker;
DatePicker.NextPMI = NextPMIDatePicker;
DatePicker.Reservation = ReservationDatePicker;
DatePicker.WorkPackageReservation = WorkPackageReservationDatePicker;
// DatePicker.BrokenTool = BrokenToolDatePicker;

DatePicker.LoadTest = LoadTestDueDatePicker;

export default DatePicker;
