import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function EditWorkPackageButton({ children = 'Edit', ...props }) {
  const { isEditable } = useStoreState((state) => state.workPackage);

  const { setIsEditableWorkPackageAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={() => setIsEditableWorkPackageAction(true)}
      disabled={isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
