import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';

export default function OEMStatusList({ navigation }) {
  const { oemStatusList } = useStoreState((state) => ({
    ...state.tools,
    oemStatusList: state.tools.oemStatusList.filter((tool) =>
      formatStringForSearch(
        tool.OEMstatusCode || tool.OEMstatusDescription
      ).includes(formatStringForSearch(state.tools.oemSearchInput))
    ),
  }));

  const { setOEMInputAction } = useStoreActions((actions) => actions);
  return (
    <List
      tableHead={['Status Code', 'Status Description', 'Edit']}
      tableRows={oemStatusList.map((tool) => [
        tool.OEMstatusCode,
        tool.OEMstatusDescription,
        <TouchableIcon
          Component={FontAwesome5}
          name="edit"
          size={20}
          onPress={() => {
            setOEMInputAction(tool);
            navigation.navigate('Edit OEM Status');
          }}
        />,
      ])}
    />
  );
}
