import Button from '../../presentational/Button';

function useOnPress(navigation) {
  return () => {
    navigation.navigate('Unserviceable Tools');
  };
}

export default function GoToBrokenToolsButton({
  navigation,
  children = 'Unserviceable Tools',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props} color={'#ab2328'}>
      {children}
    </Button>
  );
}
