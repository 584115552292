import { thunk } from 'easy-peasy';
import {
  cancelWorkPackageReservationAPICall,
  checkinWorkPackageAPICall,
  checkoutWorkPackageAPICall,
  copyPrecheckAPICall,
  copyWorkPackageToTenantAPICall,
  deleteWorkPackageAPICall,
  duplicateWorkPackageAPICall,
  getWorkPackageAPICall,
  getWorkPackageReservationsAPICall,
  precheckWorkPackageAPICall,
  reserveWorkPackageAPICall,
  upsertWorkPackageAPICall,
} from '../../api/workPackage';
import { bulkUploadWorkPackageToolsAPICall } from '../../api/tool';

function removeSingleQuotes(str) {
  // Check if the string starts and ends with single quotes
  if (str.startsWith("'") && str.endsWith("'")) {
    // Use replace() with a regular expression to remove the single quotes
    return str.replace(/^'|'$/g, '');
  }
  return str; // If no single quotes at the beginning and end, return the string as is
}

const thunks = {
  duplicateWorkPackageThunk: thunk(async (actions, { navigation }, helpers) => {
    actions.setLoadingAction(true);
    try {
      await duplicateWorkPackageAPICall({
        currentWPid: helpers.getState().workPackage.input.id,
        name: helpers.getState().workPackage.duplicateInput.name,
        description: helpers.getState().workPackage.duplicateInput.description,
        tenantId: helpers.getState().tenant.input.id,
      });
      actions.setAlertThunk({
        type: 'success',
        message: 'Work Package duplicated successfully',
      });
      navigation.navigate('Work Packages');
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getWorkPackageThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    const { id, tenantId } = data;

    try {
      const tenantResponse = await getWorkPackageAPICall({
        id: id || null,
        tenantId: tenantId || helpers.getState().tenant.input.id,
      });
      actions.setWorkPackageSearchResultsAction(tenantResponse.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getWorkPackagesThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    const { id, tenantId } = data;
    try {
      const tenantResponse = await getWorkPackageAPICall({
        id: id || null,
        tenantId: tenantId, // helpers.getState().tenant.input.id,
      });
      actions.setWorkPackageInputAction(tenantResponse.data[0]);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertWorkPackageThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await upsertWorkPackageAPICall({
          ...input,
          tenantId: helpers.getState().tenant.input.id,
          // logo: helpers.getState().document,
        });
        actions.setAlertThunk({
          type: 'success',
          message: 'Work Package saved successfully',
        });
        navigation.goBack();
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  deleteWorkPackageThunk: thunk(async (actions, { navigation }, helpers) => {
    actions.setLoadingAction(true);
    try {
      await deleteWorkPackageAPICall({
        WPid: helpers.getState().workPackage.input.id,
        tenantId: helpers.getState().tenant.input.id,
      });
      actions.setAlertThunk({
        type: 'success',
        message: 'Work Package deleted successfully',
      });
      // navigation.goBack();
      navigation.navigate('Work Packages');

      // actions.setWorkPackageSearchResultsAction(tenantResponse.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  checkoutWorkPackageThunk: thunk(async (actions, { navigation }, helpers) => {
    actions.setLoadingAction(true);
    try {
      await checkoutWorkPackageAPICall({
        ...helpers.getState().workPackage.checkoutInput,
        tenantId: helpers.getState().auth.user.tenantId,
        WPid: helpers.getState().workPackage.checkoutPackageList,
      });
      actions.setAlertThunk({
        type: 'success',
        message: 'Work Packages checked out successfully',
      });
      const tenantResponse = await getWorkPackageAPICall({
        id: null,
        tenantId: helpers.getState().auth.user.tenantId,
      });
      actions.setWorkPackageSearchResultsAction(tenantResponse.data);

      actions.setCheckoutWorkPackageListAction([]);
      actions.setCheckinWorkPackageListAction([]);
      actions.setWorkPackageWOSerialModalAction(false);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),

  reserveWorkPackageThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);
      try {
        await reserveWorkPackageAPICall({
          ...input,
          tenantId:
            parseInt(helpers.getState().tenant.input.id) ||
            parseInt(helpers.getState().auth.user.tenantId),
          WPid:
            helpers.getState().workPackage.checkoutPackageList ||
            helpers.getState().workPackage.input.id,
        });

        actions.resetWorkPackageReservationInputAction();
        actions.setReservationModalAction(false);
        actions.setWPReservationModalAction(false);
        actions.setAlertThunk({
          type: 'success',
          message: `Reserved Work Package Successfully`,
        });
      } catch (err) {
        actions.setWPReservationModalAction(false);
        actions.setPrecheckWorkPackageToolModalVisibleAction(false);
        console.log(err);
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),

  checkinWorkPackageThunk: thunk(async (actions, { navigation }, helpers) => {
    actions.setLoadingAction(true);
    try {
      await checkinWorkPackageAPICall({
        WPid: helpers.getState().workPackage.checkinPackageList,
        tenantId: helpers.getState().auth.user.tenantId,
        WPStatusCode: 'AV',
        WPStatusDescription: 'Available',
      });
      const tenantResponse = await getWorkPackageAPICall({
        id: null,
        tenantId: helpers.getState().auth.user.tenantId,
      });
      actions.setWorkPackageSearchResultsAction(tenantResponse.data);
      actions.setCheckoutWorkPackageListAction([]);
      actions.setCheckinWorkPackageListAction([]);
      await actions.setAlertThunk({
        type: 'success',
        message: 'Work Packages checked out successfully',
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  precheckoutWorkPackageThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await precheckWorkPackageAPICall({
        WPid: helpers.getState().workPackage.checkoutPackageList,
        tenantId: helpers.getState().auth.user.tenantId,
        userlocationid: helpers.getState().auth.user.locationId,
      });

      if (response && response.data && response.data.length > 0) {
        let arr = [];
        response.data.map((item) => {
          console.log(item);
          arr.push(item[''] == null ? null : JSON.parse(item['']));
        });

        actions.setPrecheckWorkPackageToolListAction(arr[0].toolid);
      }
      actions.setPrecheckWorkPackageToolModalVisibleAction(true);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: 'No Tools Associated with this Work Package',
      });
    }
    actions.setLoadingAction(false);
  }),

  precheckCopyWorkPackageThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      let res = await copyPrecheckAPICall({
        WPid: parseInt(helpers.getState().workPackage.input.id),
        fromtenantId: parseInt(helpers.getState().tenant.input.id),
        totenantId: parseInt(
          helpers.getState().workPackage.copyWPToTenantInput.totenantId
        ),
      });
      actions.setCopyWorkPackageToolListAction(res.data);

      if (res.data.every((item) => item['AllPartsExist'] == 'true')) {
        await copyWorkPackageToTenantAPICall({
          WPid: parseInt(helpers.getState().workPackage.input.id),
          fromtenantId: parseInt(helpers.getState().tenant.input.id),
          totenantId: parseInt(
            helpers.getState().workPackage.copyWPToTenantInput.totenantId
          ),
          name: helpers.getState().workPackage.copyWPToTenantInput.name,
          description:
            helpers.getState().workPackage.copyWPToTenantInput.description,
        });

        actions.setCopyWorkPackageToTenantModalVisibleAction(false);
        actions.setCopyWorkPackageInputAction({
          ...helpers.getState().workPackage.copyWPToTenantInput,
          totenantId: null,
          name: '',
          description: '',
        });

        actions.setAlertThunk({
          type: 'success',
          message: 'Work Package copied successfully',
        });
      } else {
        actions.setCopyWorkPackageToTenantModalVisibleAction(false);

        actions.setCopyWorkPackageToTenantModalToolListVisibleAction(true);
      }
      // if (res.data.length > 1) {
      // actions.setCopyWorkPackageToTenantModalToolListVisibleAction(true);
      // } else if (res.data.length == 1) {
      // }
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  // ),
  copyWorkPackageToTenantThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      await copyWorkPackageToTenantAPICall({
        WPid: helpers.getState().workPackage.input.id,
        fromtenantId: helpers.getState().tenant.input.id,
        totenantId:
          helpers.getState().workPackage.copyWPToTenantInput.totenantId,
        name: helpers.getState().workPackage.copyWPToTenantInput.name,
        description:
          helpers.getState().workPackage.copyWPToTenantInput.description,
      });

      actions.setAlertThunk({
        type: 'success',
        message: 'Work Package copied successfully',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  bulkUploadWorkPackageToolsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      await bulkUploadWorkPackageToolsAPICall({
        // WPid: helpers.getState().workPackage.input.id,
        id: null,
        tenantId: helpers.getState().tenant.input.id,
        workpackageId: helpers.getState().workPackage.input.id,
        workpackageName: helpers.getState().workPackage.input.name,
        base64: data,
      });

      actions.setAlertThunk({
        type: 'success',
        message: 'Tools Uploaded successfully',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  getWorkPackageReservationListThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getWorkPackageReservationsAPICall({
        WPId: data || helpers.getState().workPackage.input.id,
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setWorkPackageReservationListAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  cancelWorkPackageReservationThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      await cancelWorkPackageReservationAPICall({
        // WPId: 1,
        // tenantId: 1,
        // reservationstartdate: "2023-10-03 12:16:11",
        // reservationenddate: "2023-10-03 12:16:11",
        ...helpers.getState().workPackage.cancelWorkPackageReservationInput,
        WPId: helpers.getState().workPackage.input.id,
        tenantId: helpers.getState().tenant.input.id,
      });
      const response = await getWorkPackageReservationsAPICall({
        WPId: helpers.getState().workPackage.input.id,
        tenantId: helpers.getState().tenant.input.id,
      });
      actions.setWorkPackageReservationListAction(response.data);
      actions.setAlertThunk({
        type: 'success',
        message: 'Reservation cancelled successfully',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
};

export default thunks;
