import { thunk } from 'easy-peasy';
import {
  getInspectionsAPICall,
  upsertInspectionsAPICall,
  removeInspectionsAPICall,
} from '../../api/inspection';

const thunks = {
  getInspectionsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const inspections = await getInspectionsAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
        toolId: helpers.getState().tools.input.id,
      });
      actions.setInspectionsListAction(inspections.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  upsertInspectionsThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await upsertInspectionsAPICall({
          ...input,
          tenantId:
            helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId,
          toolId:
            helpers.getState().tools.input.id ||
            helpers.getState().inspections.toolId,
        });
        actions.setAlertThunk({
          type: 'success',
          message: 'Inspection Updated',
        });
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  removeInspectionsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      await removeInspectionsAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
        id: helpers.getState().inspections.input.id,
      });

      const inspections = await getInspectionsAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
        toolId: helpers.getState().tools.input.id,
      });
      actions.setInspectionsListAction(inspections.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
