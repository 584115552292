import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';
import CustomModal from '../presentational/Modal';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditInspectionItemsPage({
  route,
  navigation,
  ...props
}) {
  const isFocused = useIsFocused();
  const { isEditable } = useStoreState((state) => state.inspectionItems);

  const { resetDocumentAction, setIsEditableInspectionItemsAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      setIsEditableInspectionItemsAction(false);
      resetDocumentAction();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <CustomModal.AreYouSureInspectionItem navigation={navigation} />
        <Form.InspectionItem
          sideButtons
          navigation={navigation}
          buttons={[
            <Button.EditInspectionItem navigation={navigation} />,
            <Button.GoToInspection navigation={navigation}>
              Cancel
            </Button.GoToInspection>,
            <Button.SaveInspectionItem
              navigation={navigation}
              message="Added Inspection Type"
              disabled={!isEditable}
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
