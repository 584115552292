import React, { useState } from "react";
import DateTimePickerComponent from "../../../presentational/DateTimePicker";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";

export default function LoadTestDueDatePicker(visible) {
	const { loadTestDatePickerActive } = useStoreState((state) => state.tools);

	const {
		setReservationCalendarVisible,
		setWorkPackageReservationInputAction,
		setLoadTestDatePickerActiveAction,
		setToolsInputAction,
	} = useStoreActions((actions) => actions);

	const minDate = new Date(); // Today
	const maxDate = new Date(2017, 6, 3);

	return (
		<DateTimePickerComponent
			startFromMonday={true}
			minDate={minDate}
			maxDate={maxDate}
			todayBackgroundColor={"#0088CE"}
			selectedDayColor="#0088CE"
			selectedDayTextColor="#0088CE"
			selectedRangeStartStyle={{ backgroundColor: "#0088CE" }}
			selectedRangeEndStyle={{ backgroundColor: "#0088CE" }}
			// allowRangeSelection={true}
			onChange={(date, type) => {
				// if (type === "END_DATE") {
				// 	setWorkPackageReservationInputAction({
				// 		WPreservationendDate: date,
				// 	});
				// } else {
				// 	setWorkPackageReservationInputAction({
				// 		WPreservationstartDate: date,
				// 	});
				setToolsInputAction({
					loadTestDueDate: moment(date).format("MM/DD/YYYY"),
				});
			}}
			visible={loadTestDatePickerActive}
			onBackdropPress={() => setLoadTestDatePickerActiveAction(false)}
		/>
	);
}
