import { thunk } from 'easy-peasy';
import {
  deleteDocumentAPICall,
  getDocumentAPICall,
  upsertDocumentAPICall,
  loadDocumentAPICall,
} from '../../api/document';
import download from 'downloadjs';
import { openBase64FileOnWeb } from '../../utilities/document';
const thunks = {
  getDocumentsThunk: thunk(async (actions, queryPayload, helpers) => {
    actions.setLoadingAction(true);
    try {
      if (queryPayload.id) {
        actions.resetDocumentAction();
        const toolImages = await loadDocumentAPICall({
          toolid: parseInt(queryPayload.id),
          tenantId: parseInt(
            helpers.getState().tenant.input.id ||
              helpers.getState().auth.user.tenantId
          ),
          type: 'tool',
        });

        actions.setDocumentToAddListAction(toolImages.data);
      }
      actions.showDocumentModalAction(true);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  getDocumentThunk: thunk(async (actions, queryPayload, helpers) => {
    actions.setLoadingAction(true);
    try {
      actions.resetDocumentAction();
      const response = await getDocumentAPICall({
        id: null,
        tenantId: parseInt(
          helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId
        ),
        toolid: parseInt(queryPayload.id),
      });
      const toolImage = response.data.find((image) => image.type === 'tool');
      if (toolImage) {
        const image = await loadDocumentAPICall({
          tenantId: parseInt(
            helpers.getState().tenant.input.id ||
              helpers.getState().auth.user.tenantId
          ),
          id: toolImage.id,
        });
        actions.setDocumentAction(image.data[0]);
      }
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  getDocumentsListThunk: thunk(async (actions, queryPayload, helpers) => {
    actions.setLoadingAction(true);
    actions.setDocumentLoadingAction(true);
    try {
      actions.resetDocumentAction();
      const response = await getDocumentAPICall({
        id: null,
        tenantId: helpers.getState().tenant.input.id,
        toolid: helpers.getState().tools.input.id,
      });

      const extraDocs = response.data.filter((doc) => doc.type === 'extra');

      const toolImages = await loadDocumentAPICall({
        toolid: helpers.getState().tools.input.id,
        tenantId: helpers.getState().tenant.input.id,
        type: 'tool',
      });
      actions.setDocumentAction(toolImages.data[0]);
      actions.setDocumentToAddListAction(toolImages.data);

      actions.setDocumentListAction(extraDocs);
    } catch (err) {
      console.log('err', err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
    actions.setDocumentLoadingAction(false);
  }),

  loadDocumentThunk: thunk(async (actions, queryPayload) => {
    actions.setLoadingAction(true);
    try {
      const response = await loadDocumentAPICall({
        ...queryPayload,
        tenantId: parseInt(
          helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId
        ),
      });
      const doc = response.data[0];
      if (doc.body && doc.body.includes('data:application/pdf;base64,')) {
        const base64 = doc.body.split(',')[1];
        openBase64FileOnWeb(base64, 'application/pdf');
      } else {
        actions.setDocumentImageAction(doc.body);
        actions.showDocumentModalAction(true);
      }
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),

  downloadDocumentThunk: thunk(async (actions, queryPayload) => {
    actions.setLoadingAction(true);
    try {
      const response = await loadDocumentAPICall(queryPayload);
      console.log(response);
      const base64String = response.data[0].body;
      const filename = 'ToolSampleFile.csv';
      const mimeType = 'text/csv';
      download(base64String, filename, mimeType);
      actions.setAlertThunk({
        type: 'success',
        message: 'Document downloaded successfully!',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  upsertDocumentThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);
      try {
        await upsertDocumentAPICall({
          tenantId: helpers.getState().tenant.input.id,
          toolid: helpers.getState().tools.input.id,
          ...helpers.getState().document,
        });
        actions.setAlertThunk({
          type: 'success',
          message: 'Document saved successfully!',
        });
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }
      actions.setLoadingAction(false);
    }
  ),

  upsertExtraDocumentThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);
      try {
        let doc = { ...input };
        if (!input.toolid) {
          doc.toolid = helpers.getState().extraDocumentsToolId;
        }
        const extraDocument = await upsertDocumentAPICall({
          tenantId: helpers.getState().tenant.input.id,
          ...doc,
        });
        actions.setDocumentListAction([
          ...helpers.getState().documents,
          extraDocument.data,
        ]);
        actions.resetExtraDocumentAction();
        actions.setAlertThunk({
          type: 'success',
          message: 'Document saved successfully!',
        });
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  removeDocumentThunk: thunk(async (actions, _, helpers) => {
    actions.setDocumentLoadingAction(false);
    try {
      const removeInputs = helpers.getState().removeDocumentInput;
      const documents = helpers.getState().documents;
      const documentsToAdd = helpers.getState().documentsToAdd;
      await deleteDocumentAPICall(removeInputs);
      actions.setDocumentListAction(
        documents.filter((doc) => doc.id !== removeInputs.id)
      );
      actions.setDocumentToAddListAction(
        documentsToAdd.filter((doc) => doc.id !== removeInputs.id)
      );
      actions.setAlertThunk({
        type: 'success',
        message: 'Document removed successfully!',
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setDocumentLoadingAction(false);
  }),
};

export default thunks;
