import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

export default function EditInspectionItemButton({ children = "Edit", ...props }) {
  const { isEditable } = useStoreState((state) => state.inspectionItems);

  const { setIsEditableInspectionItemsAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={() => setIsEditableInspectionItemsAction(true)}
      disabled={isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
