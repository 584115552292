import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function EditToolStatusButton({ children = 'Edit', ...props }) {
  const { isEditable } = useStoreState((state) => state.toolStatus);

  const { setIsEditableToolStatusAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={() => setIsEditableToolStatusAction(true)}
      disabled={isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
