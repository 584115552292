import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import Image from '../../presentational/Image';
import { vhToDp, vwToDp } from '../../../utilities/general';
import { Platform, View } from 'react-native';
import Carousel, { Pagination } from 'react-native-reanimated-carousel';
import { useSharedValue } from 'react-native-reanimated';
import { useRef } from 'react';

export default function DocumentModal() {
  const ref = useRef(null);
  const progress = useSharedValue(0);
  const { status, document, documentsToAdd } = useStoreState((state) => state);

  const { showDocumentModalAction, resetDocumentAction } = useStoreActions(
    (actions) => actions
  );

  const onPressPagination = (index) => {
    ref.current?.scrollTo({
      count: index - progress.value,
      animated: true,
    });
  };

  return (
    <CustomModal
      style={{
        width: Platform.OS == 'web' ? 500 : 1000,
      }}
      width={Platform.OS == 'web' ? '50%' : 1000}
      title='Tool Image'
      onClose={() => {
        resetDocumentAction();
        showDocumentModalAction(false);
      }}
      isVisible={status.showDocumentModal}
    >
      <View width='100%' height='100%' style={{ alignItems: 'center' }}>
        <Carousel
          ref={ref}
          width={vwToDp(20)}
          height={vhToDp(33)}
          style={{ border: '1px solid black' }}
          data={
            documentsToAdd.length === 0 ? [status.placeholder] : documentsToAdd
          }
          onProgressChange={progress}
          renderItem={({ item }) => (
            <Image width={vwToDp(20)} height={vhToDp(33)} uri={item?.body} />
          )}
        ></Carousel>
        <Pagination.Basic
          progress={progress}
          data={documentsToAdd}
          dotStyle={{
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: 50,
          }}
          containerStyle={{ gap: 5, marginTop: 10 }}
          onPress={onPressPagination}
        />
      </View>
    </CustomModal>
  );
}
