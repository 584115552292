import { axios } from '.';
import { convertJsonToQueryString } from '../utilities/general';
import { queryString } from 'querystring';

const inspectionAxios = axios.create({
  // baseURL: `https://tmpbackenduat.azurewebsites.net/inspection`,
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/inspection`,
});

const inspectionsAxios = axios.create({
  // baseURL: `https://tmpbackenduat.azurewebsites.net/inspection`,
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/inspections`,
});

function getInspectionAPICall(tenantId) {
  return inspectionAxios({
    method: 'get',
    url: `/type/get/${tenantId}`,
  });
}

function getInspectionItemAPICall(queryString) {
  return inspectionAxios({
    method: 'get',
    url: `/item/get?${convertJsonToQueryString(queryString)}`,
  });
}

function upsertInspectionAPICall(data) {
  return inspectionAxios({ method: 'put', url: 'type/upsert', data });
}

function addToAllToolsAPICall(data) {
  return inspectionAxios({ method: 'post', url: 'addToTools', data });
}

function upsertInspectionItemAPICall(data) {
  return inspectionAxios({ method: 'put', url: 'item/upsert', data });
}

function upsertToolInspectionResultAPICall(data) {
  return inspectionAxios({ method: 'put', url: '/results/upsert', data });
}

function getInspectionResultsAPICall(queryString) {
  return inspectionAxios({
    method: 'get',
    url: `/results/get?${convertJsonToQueryString(queryString)}`,
  });
}

function getInspectionHistoryAPICall(queryString) {
  return inspectionAxios({
    method: 'get',
    url: `/history/get?${convertJsonToQueryString(queryString)}`,
  });
}

function removeInspectionTypeAPICall(queryString) {
  return inspectionAxios({
    method: 'delete',
    url: `/type/delete?${convertJsonToQueryString(queryString)}`,
  });
}

function removeInspectionItemAPICall(queryString) {
  return inspectionAxios({
    method: 'delete',
    url: `/item/delete?${convertJsonToQueryString(queryString)}`,
  });
}

function completeToolInspectionAPICall(data) {
  return inspectionAxios({ method: 'put', url: 'tool/complete', data });
}

function getInspectionsAPICall(queryString) {
  return inspectionsAxios({
    method: 'get',
    url: `/get?toolId=${queryString.toolId}&tenantId=${queryString.tenantId}`,
  });
}

function upsertInspectionsAPICall(data) {
  return inspectionsAxios({ method: 'post', url: '/upsert', data });
}

function removeInspectionsAPICall(queryString) {
  return inspectionsAxios({
    method: 'delete',
    url: `/delete?id=${queryString.id}&tenantId=${queryString.tenantId}`,
  });
}

export {
  inspectionAxios,
  inspectionsAxios,
  getInspectionAPICall,
  getInspectionItemAPICall,
  upsertInspectionAPICall,
  upsertInspectionItemAPICall,
  getInspectionResultsAPICall,
  upsertToolInspectionResultAPICall,
  getInspectionHistoryAPICall,
  removeInspectionTypeAPICall,
  removeInspectionItemAPICall,
  completeToolInspectionAPICall,
  getInspectionsAPICall,
  upsertInspectionsAPICall,
  removeInspectionsAPICall,
  addToAllToolsAPICall,
};
