import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";
import Row from "../../presentational/Row";

export default function ToolSearchBar() {
  const { searchInput } = useStoreState((state) => state.tools);
  const { setToolsSearchInputAction } = useStoreActions((actions) => actions);

  function clearSearchInput() {
    return () => setToolsSearchInputAction('');
  }

  return (
      <SearchBar
        value={searchInput}
        onChangeText={(newVal) => setToolsSearchInputAction(newVal)}
        placeholder="Search tools by name"
        onClear={clearSearchInput}
        onCancel={clearSearchInput}
      />
  );
}

