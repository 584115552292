import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setUserSearchResultsAction: action((state, searchResults) => {
    state.user.searchResults = searchResults;
  }),
  setUserSearchInputAction: action((state, searchInput) => {
    state.user.searchInput = searchInput;
  }),
  setIsEditableUserAction: action((state, isEditable) => {
    state.user.isEditable = isEditable;
  }),
  setUserInputAction: action((state, input) => {
    state.user.input = {
      ...state.user.input,
      ...input,
    };
  }),

  setUserConfirmPasswordAction: action((state, confirmPassword) => {
    state.user.confirmPassword = confirmPassword;
  }),
  resetUserInputAction: action((state, input) => {
    state.user.input = defaultInputState;
  }),
};

export default actions;
