import { axios } from ".";

const locationAxios = axios.create({
	// baseURL: `https://tmpbackenduat.azurewebsites.net/location`,
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/location`,
});

function getLocationAPICall({ id, tenantId }) {
	return locationAxios({
		method: "get",
		url: `?id=${id}&tenantId=${tenantId}`,
	});
}

function getLocationsAPICall(tenantId) {
	return locationAxios({ method: "get", url: `?tenantId=${tenantId}` });
}

function upsertLocationAPICall(data) {
	return locationAxios({ method: "put", url: "/upsert", data });
}

export {
	locationAxios,
	getLocationAPICall,
	upsertLocationAPICall,
	getLocationsAPICall,
};
