import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function SaveBrokenToolButton({
  navigation,
  children = 'Save',
  message,
  ...props
}) {
  const { reportBrokenToolThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={async () => {
        await reportBrokenToolThunk();
        navigation.goBack();
      }}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
