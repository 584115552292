import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setStorageLocationSearchResultsAction: action((state, searchResults) => {
    state.storageLocation.searchResults = searchResults;
  }),
  setStorageLocationSearchInputAction: action((state, searchInput) => {
    state.storageLocation.searchInput = searchInput;
  }),
  setIsEditableStorageLocationAction: action((state, isEditable) => {
    state.storageLocation.isEditable = isEditable;
  }),
  setStorageLocationInputAction: action((state, input) => {
    state.storageLocation.input = {
      ...state.storageLocation.input,
      ...input,
    };
  }),
  resetStorageLocationInputAction: action((state, input) => {
    state.storageLocation.input = defaultInputState;
  }),
};

export default actions;
