import { axios } from ".";
import { convertJsonToQueryString } from "../utilities/general";

const userAxios = axios.create({
	// baseURL: `https://tmpbackenduat.azurewebsites.net/user`,
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/user`,
});

function getUserAPICall(queryJson) {
	return userAxios({
		method: "get",
		url: `/get?${convertJsonToQueryString(queryJson)}`,
	});
}

function upsertUserAPICall(data) {
	return userAxios({ method: "put", url: "/upsert", data });
}

function changePasswordAPICall(data) {
	return userAxios({ method: "put", url: "/password/upsert", data });
}

export { userAxios, getUserAPICall, upsertUserAPICall, changePasswordAPICall };
