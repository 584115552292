import { thunk } from 'easy-peasy';
import {
  addToAllToolsAPICall,
  getInspectionAPICall,
  getInspectionItemAPICall,
  removeInspectionTypeAPICall,
  upsertInspectionAPICall,
} from '../../api/inspection';

const thunks = {
  getInspectionThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const tenantResponse = await getInspectionAPICall(
        helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId
      );
      actions.setInspectionSearchResultsAction(tenantResponse.data);
      actions.setInspectionDropdownOptionsAction(tenantResponse.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getInspectionQuestionsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);

    if (data && data.includes(',')) {
      const inspectionTypeIds = data.split(',');

      const results = [];
      for (const inspectionTypeId of inspectionTypeIds) {
        try {
          const tenantResponse = await getInspectionItemAPICall({
            id: null,
            inspectionTypeId: inspectionTypeId.trim(),
            tenantId:
              helpers.getState().tenant.input.id ||
              helpers.getState().auth.user.tenantId,
          });

          results.push(tenantResponse.data);
          actions.setLoadingAction(false);
        } catch (err) {
          actions.setAlertThunk({
            type: 'error',
            message: err.response ? err.response.data : err.message,
          });
        }
      }
      actions.setInspectionItemsSearchResultsAction(results);

      actions.setLoadingAction(false);
    } else {
      try {
        const tenantResponse = await getInspectionItemAPICall({
          id: null,
          inspectionTypeId: data,
          tenantId:
            helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId,
        });

        actions.setInspectionItemsSearchResultsAction([tenantResponse.data]);
        actions.setLoadingAction(false);
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response ? err.response.data : err.message,
        });
      }
    }
  }),

  upsertInspectionThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await upsertInspectionAPICall({
          ...input,
          tenantId:
            helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId,
        });
        actions.setAlertThunk({
          type: 'success',
          message,
        });
        navigation.goBack();
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  addInspectionToToolsThunk: thunk(async (actions, input, helpers) => {
    actions.setLoadingAction(true);
    try {
      await addToAllToolsAPICall({
        ...input,
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setAlertThunk({
        type: 'success',
        message: 'Inspection added to all tools',
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  removeInspectionTypeThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);

    try {
      await removeInspectionTypeAPICall({
        id: helpers.getState().inspectionTypes.input.id,
        tenantID:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });

      const tenantResponse = await getInspectionAPICall(
        helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId
      );

      actions.setInspectionSearchResultsAction(tenantResponse.data);
      actions.setInspectionDropdownOptionsAction(tenantResponse.data);
      actions.setAlertThunk({
        type: 'success',
        message: 'Inspection Type Removed',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
};

export default thunks;
