import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

export default function TransferSearchBar() {
  const { transferSearchInput } = useStoreState((state) => state.tools);
  const { setTransferSearchInputAction } = useStoreActions(
    (actions) => actions
  );

  function clearSearchInput() {
    return () => setMessagesSearchInputAction('');
  }

  return (
    <SearchBar
      value={transferSearchInput}
      onChangeText={(newVal) => setTransferSearchInputAction(newVal)}
      placeholder='Search Transfers'
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}
