import styled from 'rn-css';

const Tr = styled.View`
  display: table-row;
  flex-direction: row;
  background-color: ${(props) => props.striped && '#f2f2f2'};

  &:hover {
    background-color: ${(props) => props.hover && '#EDEDED'};
  }
`;

export default Tr;
