import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import moment from 'moment';
import { View } from 'react-native';
import Text from '../../presentational/Text';
import Gap from '../../presentational/Gap';

function Field({ title, value }) {
  return (
    <View
      style={{
        borderBottomWidth: 0.1,
        bottomBorderWidth: 0.1,
        borderColor: 'lightgray',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
      }}
    >
      <Text
        style={{
          fontSize: 20,
          color: '#ab2328',
        }}
      >
        {title}:
      </Text>

      <Text
        style={{
          fontSize: 20,
          marginLeft: 15,
          fontFamily: 'Barlow_500Medium_Italic',
        }}
      >
        {value}
      </Text>
    </View>
  );
}

export default function PreCheckWarningModal() {
  const {
    tools: { searchResults, preCheckToolModal, selectedToolPreCheck },
  } = useStoreState((state) => state);

  const {
    setToolCheckOutModalAction,

    getInspectionQuestionsThunk,
    setShowInspectionQuestionsModalAction,

    setPreCheckToolModalAction,
  } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      style={{ width: 500 }}
      width="50%"
      height="50%"
      title="Pre-Check Warning"
      onClose={() => setPreCheckToolModalAction(false)}
      isVisible={preCheckToolModal}
    >
      {selectedToolPreCheck && selectedToolPreCheck.inspectionRequired ? (
        <>
          <Field
            title="Next Calibration Test Due Date"
            value={moment(
              selectedToolPreCheck.nextCalibrationTestDueDate
            ).format('MM/DD/YYYY')}
          />
          <Field
            title="Inspection Day Threshold (Days)"
            value={selectedToolPreCheck.inspectionnumofdaysThreshold}
          />
          <Field
            title="Number of Days Between Inspections"
            value={selectedToolPreCheck.daysbetweenInspections}
          />
          <Field
            title="Number of Checkouts"
            value={selectedToolPreCheck.numofCheckouts}
          />
          <Field
            title="Number of Checkouts Until Inspection"
            value={selectedToolPreCheck.numofcheckoutstillnextinspection}
          />
        </>
      ) : (
        <>
          <Field
            title="Reservation Date"
            value={
              moment(
                selectedToolPreCheck &&
                  selectedToolPreCheck.reservationStartDate
              ).format('MM/DD/YYYY') +
              ' - ' +
              moment(
                selectedToolPreCheck && selectedToolPreCheck.reservationEndDate
              ).format('MM/DD/YYYY')
            }
          />
          <Field
            title="Reservation Notes"
            value={
              selectedToolPreCheck && selectedToolPreCheck.reservationNotes
            }
          />
          <Field
            title="Reservation Message"
            value={selectedToolPreCheck && selectedToolPreCheck.reservedMessage}
          />
          <Field
            title="Reserved By"
            value={selectedToolPreCheck && selectedToolPreCheck.reservedBy}
          />
        </>
      )}
      <Gap />
      {selectedToolPreCheck &&
        selectedToolPreCheck.inspectionRequired == 'Y' && (
          <Button
            fullWidth
            onPress={() => {
              getInspectionQuestionsThunk(
                searchResults.find((tool) => tool.id == selectedToolPreCheck.id)
                  .inspectionTypes
              );
              setPreCheckToolModalAction(false);
              setToolCheckOutModalAction(false);
              setShowInspectionQuestionsModalAction(true);
            }}
          >
            Complete Inspection
          </Button>
        )}
    </CustomModal>
  );
}
