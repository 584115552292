import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

export default function InspectionSearchBar() {
  const { searchInput } = useStoreState((state) => state.inspectionTypes);
  const { setInspectionSearchInputAction } = useStoreActions(
    (actions) => actions
  );

  function clearSearchInput() {
    return () => setInspectionSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setInspectionSearchInputAction(newVal)}
      placeholder="Search inspections by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}
