import { useStoreActions, useStoreState } from 'easy-peasy';
import List from '../../presentational/List';
import { FontAwesome5 } from '@expo/vector-icons';
import { View } from 'react-native';
import { CheckBox } from '@rneui/themed';
import { remToDp } from '../../../utilities/general';
import { useState } from 'react';

export default function ToolTransfersList() {
  const hasSearchText = (value, searchInput) =>
    value?.toString().toLowerCase().trim().includes(searchInput.toLowerCase());

  const {
    location: { locationMap },
  } = useStoreState((state) => state);

  const { toolTransfers, selectedTransfer, transferIsSelected } = useStoreState(
    (state) => ({
      ...state.tools,
      toolTransfers: (state.tools.toolTransfers || []).filter(
        (tool) =>
          hasSearchText(tool?.partNumber, state.tools.transferSearchInput) ||
          hasSearchText(tool?.serialNumber, state.tools.transferSearchInput) ||
          hasSearchText(tool?.status, state.tools.transferSearchInput) ||
          hasSearchText(
            tool?.trackingnumber,
            state.tools.transferSearchInput
          ) ||
          hasSearchText(tool?.amount, state.tools.transferSearchInput) ||
          hasSearchText(
            locationMap[tool.fromtoollocationid]?.name,
            state.tools.transferSearchInput
          ) ||
          hasSearchText(
            locationMap[tool.totoollocationid]?.name,
            state.tools.transferSearchInput
          )
      ),
    })
  );

  const { setSelectedTransferAction, setIsTransferSelectedAction } =
    useStoreActions((actions) => actions);

  return (
    <List
      tableHead={[
        'Part Number',
        'From',
        'To',
        'Status',
        'Tracking Number',
        'Value',
        '',
      ]}
      tableRows={toolTransfers
        .sort((a, b) => b.status.localeCompare(a.status))
        .map((tool) => [
          `${tool.partNumber} (${tool.serialNumber})`,
          locationMap[tool.fromtoollocationid]?.name,
          locationMap[tool.totoollocationid]?.name,
          tool.status,
          tool.trackingnumber,
          tool.amount,
          <View style={{ flexDirection: 'row' }}>
            <CheckBox
              checked={transferIsSelected && tool.id === selectedTransfer.id}
              disabled={transferIsSelected && tool.id !== selectedTransfer.id}
              checkedColor='#007AFF'
              onPress={() => {
                if (transferIsSelected) {
                  setIsTransferSelectedAction(false);
                  setSelectedTransferAction({});
                } else {
                  setIsTransferSelectedAction(true);
                  setSelectedTransferAction(tool);
                }
              }}
              checkedIcon={
                <FontAwesome5
                  name='check-square'
                  size={remToDp(1.5)}
                  color='#007AFF'
                />
              }
              uncheckedIcon={
                <FontAwesome5
                  name='square'
                  size={remToDp(1.5)}
                  color={
                    transferIsSelected && tool.id !== selectedTransfer.id
                      ? '#e3e6e8'
                      : '#007AFF'
                  }
                />
              }
              containerStyle={{ backgroundColor: 'none' }}
            />
          </View>,
          ,
        ])}
    />
  );
}
