import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function EditToolCategoryButton({ children = 'Edit', ...props }) {
  const { toolCategoryIsEditable } = useStoreState((state) => state.tools);

  const { setToolCategoryIsEditableAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={() => setToolCategoryIsEditableAction(true)}
      disabled={toolCategoryIsEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
