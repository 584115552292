const defaultInputState = {
  id: null,
  statusCode: null,
  statusDescription: '',
  defaultValue: '',
};

const state = {
  toolStatus: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
  },
};

export { state as default, defaultInputState };
