import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Col from '../presentational/Col';
import Button from '../presentational/Button';
import { vhToDp, vwToDp } from '../../utilities/general';
import CustomModal from '../presentational/Modal';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function ToolTransferPage({ navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    getToolTransfersThunk,
    setApproveTransferModalAction,
    setToolTransferInputAction,
    upsertToolTransferThunk,
    getToolThunk,
    upsertToolThunk,
    setToolsInputAction,
    setIsTransferSelectedAction,
    setSelectedTransferAction,
    setReceiveTransferModalAction,
    getStorageLocationsThunk,
    getToolStatusThunk,
    removeToolTransferThunk,
  } = useStoreActions((actions) => actions);

  const { input, transferIsSelected, selectedTransfer } = useStoreState(
    (state) => state.tools
  );

  useEffect(() => {
    if (isFocused) {
      getToolTransfersThunk();
      getStorageLocationsThunk();
      getToolStatusThunk();
    }
    return () => {};
  }, [isFocused]);

  const ApproveTransfer = async () => {
    await upsertToolTransferThunk();
    setToolsInputAction({
      toolStatusCode: 'IT',
    });
    await upsertToolThunk(input);
    await getToolTransfersThunk();
    setToolTransferInputAction({
      id: null,
      fromtoollocationid: null,
      totoollocationid: null,
      trackingnumber: null,
      amount: null,
    });
    setIsTransferSelectedAction(false);
    setSelectedTransferAction({});
    setApproveTransferModalAction(false);
  };

  const ReceiveTransfer = async () => {
    await removeToolTransferThunk();
    await upsertToolThunk(input);
    await getToolTransfersThunk();
    setIsTransferSelectedAction(false);
    setSelectedTransferAction({});
    setReceiveTransferModalAction(false);
  };

  const RejectTransfer = async () => {
    await removeToolTransferThunk();
    await getToolTransfersThunk();
    setIsTransferSelectedAction(false);
    setSelectedTransferAction({});
    setApproveTransferModalAction(false);
  };

  return (
    <Page.Protected {...props}>
      <Container>
        <CustomModal.ApproveTransfer
          onReject={RejectTransfer}
          onSubmit={ApproveTransfer}
        />
        <CustomModal.ReceiveTransfer onSubmit={ReceiveTransfer} />
        <SRow>
          <Col xs={12} sm={2}>
            <Button
              color='#00205B'
              fullWidth
              disabled={
                !(transferIsSelected && selectedTransfer.status === 'Pending')
              }
              onPress={async () => {
                await getToolThunk(selectedTransfer.toolid);
                setToolTransferInputAction({ ...selectedTransfer });
                setApproveTransferModalAction(true);
              }}
            >
              Review
            </Button>
          </Col>
          <Col xs={12} sm={2}>
            <Button
              color='#00205B'
              fullWidth
              disabled={
                !(
                  transferIsSelected && selectedTransfer.status === 'In Transit'
                )
              }
              onPress={async () => {
                await getToolThunk(selectedTransfer.toolid);
                setToolsInputAction({
                  tenantLocation: selectedTransfer.totoollocationid,
                  toolStatusCode: '',
                  statusDescription: '',
                  storageLocation: '',
                });
                setReceiveTransferModalAction(true);
              }}
            >
              Receive
            </Button>
          </Col>
          <Col xs={12} sm={8}>
            <SearchBar.Transfers />
          </Col>
        </SRow>

        <Gap />

        <Card>
          <List.Transfers />
        </Card>
      </Container>
    </Page.Protected>
  );
}

export default ToolTransferPage;
