const defaultInputState = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  userName: '',
  password: '',
  role: '',
  locationId: '',
  repositoryId: '',

  active: true,
};

const state = {
  user: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
    confirmPassword: '',
  },
};

export { state as default, defaultInputState };
