import { action } from 'easy-peasy';
import moment from 'moment/moment';

const actions = {
  setScorecardLoading: action((state, loading) => {
    state.readiness.loading = loading;
  }),
  setScorecardType: action((state, selectedType) => {
    state.readiness.selectedType = selectedType;
  }),
  updateScorecard: action((state, { type, value, index }) => {
    state.readiness.scoreCardInputs[type].categories[index].value = value;
    state.readiness.scoreCardInputs[type].totalscore =
      state.readiness.scoreCardInputs[type].categories.reduce(
        (sum, item) => sum + item.value,
        0
      );
  }),
  resetScorecard: action((state, selectedType) => {
    state.readiness.scoreCardInputs[selectedType].totalscore = 0;
    state.readiness.scoreCardInputs[selectedType].categories =
      state.readiness.scoreCardInputs[selectedType].categories.map((input) => {
        return { ...input, value: 0 };
      });
  }),
  initializeScorecardData: action((state, data) => {
    state.readiness.loading = true;

    const sortedData = data.sort(
      (a, b) => new Date(b.datecreated) - new Date(a.datecreated)
    );
    const currentMap = sortedData.reduce((map, item) => {
      if (!map[item.type]) {
        const categories = JSON.parse(item.categories);
        map[item.type] = {
          datasets: categories.reduce(
            (data, item) => {
              data.push([item.label, item.value]);
              return data;
            },
            [['Category', 'Score']]
          ),
          categories,
        };
      }

      return map;
    }, {});

    const historyMap = sortedData.reduce((map, item) => {
      const categoryList = JSON.parse(item.categories);
      if (!map[item.type]) {
        const catMap = {};
        categoryList.forEach((cat) => {
          catMap[cat.label] = [cat.label, cat.value];
        });
        map[item.type] = {
          labels: ['Category'],
          categoryMap: { ...catMap },
          dataset: [],
        };
      } else {
        categoryList.forEach((cat) => {
          map[item.type].categoryMap[cat.label].push(cat.value);
        });
      }

      map[item.type].labels.push(
        moment.utc(item.datecreated).format('MM/DD/YYYY')
      );

      return map;
    }, {});

    Object.entries(historyMap).forEach(([key, value]) => {
      let labels = value.labels;
      if (labels.length >= 3) {
        labels = value.labels.slice(0, 3);
      }
      const values = Object.values(value.categoryMap);
      historyMap[key].dataset.push(labels);
      values.forEach((item, index) => {
        historyMap[key].dataset.push(item.slice(0, 3));
      });
    });

    state.readiness.currentScorecards = currentMap;
    state.readiness.scorecardHistory = historyMap;
    state.readiness.loading = false;
  }),
  setScoreCardModalAction: action((state, isOpen) => {
    state.readiness.showScoreCardModal = isOpen;
  }),
  setScorecardModalTypeAction: action((state, cardType) => {
    state.readiness.scorecardModalType = cardType;
  }),
};

export default actions;
