import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function EditOEMStatusButton({ children = 'Edit', ...props }) {
  const { oemIsEditable } = useStoreState((state) => state.tools);

  const { setOEMIsEditableAction } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() => setOEMIsEditableAction(true)}
      disabled={oemIsEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
