import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function SaveOEMStatusButton({
  navigation,
  children = 'Save',
  message,
  ...props
}) {
  const {
    tools: { oemInput, isEditable },
    document,
  } = useStoreState((state) => state);

  const { upsertOEMStatusListThunk, upsertDocumentThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={async () => {
        await upsertOEMStatusListThunk(
          navigation
          // input: oemInput,
          // message,
        );
      }}
      disabled={!isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
