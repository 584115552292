import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Entypo } from '@expo/vector-icons';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import Icon from '../../presentational/Icon';
import Gap from '../../presentational/Gap';
import Row from '../../presentational/Row';
import { Platform } from 'react-native';
import { useEffect, useState } from 'react';

export default function ApproveTransferModal({ onSubmit, onReject }) {
  const [toOptions, setToOptions] = useState([]);
  const {
    tools: { toolTransferInput, showApproveTransferModal },
    location: { searchResults, locationMap },
  } = useStoreState((state) => state);
  const { setApproveTransferModalAction, setToolTransferInputAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (toolTransferInput.fromtoollocationid) {
      setToOptions(
        searchResults
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((x) => x.id !== toolTransferInput.fromtoollocationid)
      );
    } else {
      setToOptions(searchResults);
    }
  }, [searchResults, toolTransferInput.fromtoollocationid, setToOptions]);

  return (
    <CustomModal
      onClose={() => {
        setToolTransferInputAction({
          id: null,
          fromtoollocationid: null,
          totoollocationid: null,
          trackingnumber: null,
          amount: null,
        });
        setApproveTransferModalAction(false);
      }}
      isVisible={showApproveTransferModal}
      title='Approve Transfer'
      style={{ width: 800 }}
      width='80%'
      height={Platform.OS == 'web' ? '50%' : '64%'}
    >
      <Row>
        <Col>
          <Input
            required
            placeholder='From Location'
            label='From'
            value={
              locationMap[toolTransferInput.fromtoollocationid]?.name || ''
            }
            onSelect={(location) => {
              setToolTransferInputAction({
                ...toolTransferInput,
                fromtoollocationid: location.id,
              });
            }}
            editable={false}
            selectedItem={(item) => item.name}
            rowTextForSelection={(item) => item.name}
            dropdown
            dropdownChoices={searchResults}
            width={Platform.OS == 'web' ? '95%' : '98%'}
            search={true}
            searchInputStyle={{
              width: '100%',
              alignSelf: 'center',
              backgroundColor: '#F7F7F7',
              border: 0,

              boxShadow: '0px 0px 10px grey',
              borderColor: '#F7F7F7',
            }}
            searchInputTxtStyle={{
              fontFamily: 'Barlow_400Regular',
              fontSize: 16,
            }}
            renderSearchInputRightIcon={() => (
              <Icon Component={Entypo} name='magnifying-glass' />
            )}
            searchInputPlaceholder='Search for Location'
          />
        </Col>
        <Col>
          <Input
            required
            placeholder='To Location'
            label='To'
            value={locationMap[toolTransferInput.totoollocationid]?.name || ''}
            onSelect={(location) => {
              setToolTransferInputAction({
                ...toolTransferInput,
                totoollocationid: location.id,
              });
            }}
            editable={false}
            selectedItem={(item) => item.name}
            rowTextForSelection={(item) => item.name}
            dropdown
            dropdownChoices={toOptions}
            width={Platform.OS == 'web' ? '95%' : '98%'}
            search={true}
            searchInputStyle={{
              width: '100%',
              alignSelf: 'center',
              backgroundColor: '#F7F7F7',
              border: 0,

              boxShadow: '0px 0px 10px grey',
              borderColor: '#F7F7F7',
            }}
            searchInputTxtStyle={{
              fontFamily: 'Barlow_400Regular',
              fontSize: 16,
            }}
            renderSearchInputRightIcon={() => (
              <Icon Component={Entypo} name='magnifying-glass' />
            )}
            searchInputPlaceholder='Search for Location'
          />
        </Col>
        <Col>
          <Input
            placeholder='Tracking Number'
            label='Tracking Number'
            value={toolTransferInput.trackingnumber}
            onChangeText={(trackingnumber) =>
              setToolTransferInputAction({
                ...toolTransferInput,
                trackingnumber,
              })
            }
          />
        </Col>
        <Col>
          <Input
            placeholder='Value'
            label='Value'
            value={toolTransferInput.amount}
            onChangeText={(amount) =>
              setToolTransferInputAction({
                ...toolTransferInput,
                amount: parseFloat(amount),
              })
            }
            number
          />
        </Col>
      </Row>
      <Gap />
      <Row>
        <Col xs={6} sm={6}>
          <Button
            color={'#ab2328'}
            fullWidth
            onPress={async () => {
              onReject();
            }}
          >
            Reject
          </Button>
        </Col>
        <Col xs={6} sm={6}>
          <Button
            fullWidth
            disabled={
              !(toolTransferInput.trackingnumber && toolTransferInput.amount)
            }
            onPress={async () => {
              setToolTransferInputAction({
                ...toolTransferInput,
                status: 'In Transit',
              });
              onSubmit();
            }}
          >
            Approve
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}
