import { Tooltip } from '@rneui/themed';
import styled from 'styled-components/native';
import { Ionicons } from '@expo/vector-icons';
import { TouchableOpacity, View, Linking } from 'react-native';
import { useState } from 'react';
import Text from './Text';

const Card1 = styled.View`
  background-color: white;
  width: 100%;
  padding: 24px;
  box-shadow: 0px 0px 10px grey;
`;

const handlePress = async (url) => {
  const supported = await Linking.canOpenURL(url);

  if (supported) {
    await Linking.openURL(url);
  } else {
    console.error(`Don't know how to open this URL: ${url}`);
  }
};

const Card = ({ children, ...props }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Card1 {...props}>
      {props.toolTipText && (
        <TouchableOpacity
          style={{ position: 'absolute', top: 0, right: 0 }}
          onPress={() => {
            setVisible(!visible);
          }}
        >
          <Ionicons
            name="information-circle-outline"
            size={20}
            color="#76787a"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          />
          <View style={{ position: 'absolute', top: 23, right: 11 }}>
            <Tooltip
              width={280}
              backgroundColor={'#e4e4e4'}
              visible={visible}
              onBackdropPress={() => setVisible(false)}
              onClose={() => setVisible(false)}
              onOpen={() => setVisible(true)}
              popover={
                <TouchableOpacity
                  onPress={() => {
                    handlePress(props.toolUrl);
                    setVisible(!visible);
                  }}
                >
                  <Text>{props.toolTipText}</Text>
                </TouchableOpacity>
              }
              style={{ position: 'absolute', top: 30, right: 20 }}
            />
          </View>
        </TouchableOpacity>
      )}

      {children}
    </Card1>
  );
};
export default Card;
