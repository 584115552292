import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { vhToDp, vwToDp } from '../../utilities/general';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditToolStatusPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const { input } = useStoreState((state) => state.toolStatus);

  const { setIsEditableToolStatusAction, upsertAllToolStatusThunk } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      setIsEditableToolStatusAction(false);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <Form.ToolStatus
          navigation={navigation}
          buttons={[
            <Button.GoToToolStatus navigation={navigation}>
              Cancel
            </Button.GoToToolStatus>,
            <Button
              fullWidth
              onPress={async () => {
                await upsertAllToolStatusThunk({
                  toolStatusCode: input.statusCode.trim(),
                  statusDescription: input.statusDescription,
                });
              }}
            >
              Add To All Tools
            </Button>,
            <Button.EditToolStatus
              navigation={navigation}
              message='Added Dropdown'
            />,
            <Button.SaveToolStatus
              navigation={navigation}
              message='Added Dropdown'
            />,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
