// import { useStoreActions, useStoreState } from 'easy-peasy';
// import Row from '../../presentational/Row';
// import Col from '../../presentational/Col';
// import Card from '../../presentational/Card';
// import EditableJsonTree from '../EditableJSONTree';

// export default function EditLabelForm({ buttons, ...props }) {
//   const {
//     labels: { input },
//   } = useStoreState((state) => state);

//   const { setLabelInputAction } = useStoreActions((actions) => actions);

//   return (
//     <Card>
//       <Row>
//         <Col>
//           <EditableJsonTree
//             jsonData={input}
//             onChange={(newData) => setLabelInputAction({ newData })}
//           />
//         </Col>
//       </Row>

//       <Row>
//         {buttons.map((button, index) => (
//           <Col xs={12} md={4} key={index}>
//             {button}
//           </Col>
//         ))}
//       </Row>
//     </Card>
//   );
// }

import { useStoreActions, useStoreState } from "easy-peasy";
import Input from "../../presentational/Input";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Card from "../../presentational/Card";
import { useEffect, useState } from "react";

import JSONTree from "react-native-json-tree";

export default function EditLabelForm({ buttons, ...props }) {
	const {
		labels: { input, isEditable },
	} = useStoreState((state) => state);

	const { setLabelInputAction } = useStoreActions((actions) => actions);

	const [filteredData, setFilteredData] = useState({});

	useEffect(() => {
		setFilteredData({ customerConfig: input.customerConfig });
	}, [input]);

	return (
		<Card
			toolTipText={"How to use the Configuration Screen"}
			toolUrl={"https://www.screencast.com/t/sbhSg78pShV"}
		>
			<Row>
				<Col xs={12} lg={6}>
					{/* {Object.keys(input).map((key) => (
            <Input
              key={key}
              placeholder={`Type in ${key}`}
              label={key}
              value={input[key]}
              onChangeText={(value) => setLabelInputAction({ [key]: value })}
              editable={isEditable}
            />
          ))} */}
					{/* only include customer config */}
					<Input
						placeholder={`Type in Customer Config`}
						label={`Customer Config`}
						value={input.customerConfig}
						onChangeText={(value) =>
							setLabelInputAction({ customerConfig: value })
						}
						editable={isEditable}
						numberOfLines={10}
						multiline={true}
						style={{ height: 300 }}
					/>
				</Col>
				<Col xs={12} lg={6}>
					<JSONTree
						data={filteredData}
						hideRoot={true}
						shouldExpandNode={() => true}
					/>
				</Col>
			</Row>

			<Row>
				{buttons.map((button, index) => (
					<Col xs={12} md={4} key={index}>
						{button}
					</Col>
				))}
			</Row>
		</Card>
	);
}
