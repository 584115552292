import { axios } from '.';

const commentAxios = axios.create({
  // baseURL: `https://tmpbackenduat.azurewebsites.net/comment`,
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/comment`,
});

function getCommentsAPICall({ messageId }) {
  return commentAxios({
    method: 'get',
    url: `/get?messageId=${messageId}`,
  });
}

function upsertCommentAPICall(data) {
  return commentAxios({ method: 'put', url: `/upsert`, data });
}

function removeCommentsAPICall({ commentId, messageId }) {
  return commentAxios({
    method: 'delete',
    url: `/remove?commentId=${commentId}&messageId=${messageId}`,
  });
}

export {
  commentAxios,
  getCommentsAPICall,
  upsertCommentAPICall,
  removeCommentsAPICall,
};
