import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formatStringForSearch, remToDp } from '../../../utilities/general';
import { CheckBox } from '@rneui/themed';
import { FontAwesome5 } from '@expo/vector-icons';

export default function MyWorkPackagesList({ navigation }) {
  const { searchResults, checkoutPackageList, checkinPackageList } =
    useStoreState((state) => ({
      ...state.workPackage,
      searchResults: state.workPackage.searchResults.filter((user) =>
        formatStringForSearch(user.name || user.description).includes(
          formatStringForSearch(state.workPackage.searchInput)
        )
      ),
      filter: state.workPackage.filter,
      checkoutPackageList: state.workPackage.checkoutPackageList,
    }));

  const {
    addToCheckoutWorkPackageListAction,
    addToCheckInWorkPackageListAction,
  } = useStoreActions((actions) => actions);

  const isToolIdInList = (tool) => {
    if (
      typeof checkoutPackageList === 'string' &&
      checkoutPackageList.length > 0
    ) {
      const listArray = checkoutPackageList.split(',');
      return listArray.filter((item) => item == tool.id).length > 0;
    } else if (
      typeof checkinPackageList === 'string' &&
      checkinPackageList.length > 0
    ) {
      const listArray = checkinPackageList.split(',');
      return listArray.filter((item) => item == tool.id).length > 0;
    } else {
      return false;
    }
  };

  return (
    <List
      tableHead={[
        'Project',
        'Description',
        'Task',
        'Status',
        'Engine Manual Ref',
        'Add',
      ]}
      tableRows={searchResults.map((user) => [
        user.name,
        user.description,
        user.engine,
        user.statusDescription,
        user.engineManualRef,
        <CheckBox
          checked={isToolIdInList(user)}
          checkedColor='#007AFF'
          onPress={() => {
            if (user.status == 'Checked-Out' || user.status == 'CO') {
              addToCheckInWorkPackageListAction(user);
            } else {
              addToCheckoutWorkPackageListAction(user);
            }
          }}
          checkedIcon={
            <FontAwesome5
              name='check-square'
              size={remToDp(1.5)}
              color='#007AFF'
            />
          }
          uncheckedIcon={
            <FontAwesome5 name='square' size={remToDp(1.5)} color='#007AFF' />
          }
          containerStyle={{ backgroundColor: 'none' }}
        />,
      ])}
    />
  );
}
