import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';

function useOnPress(navigation) {
  const { setInspectionItemAreYouSureModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  return () => {
    setInspectionItemAreYouSureModalVisibleAction(true);
  };
}

export default function DeleteInspectionItemButton({
  navigation,
  children = 'Delete Inspection Item',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props} color={'#ab2328'}>
      {children}
    </Button>
  );
}
