import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

export default function WorkPackageToolsSearchBar() {
  const { searchInput } = useStoreState((state) => state.workPackageTools);
  const { setWorkPackageToolsSearchInputAction } = useStoreActions(
    (actions) => actions
  );

  function clearSearchInput() {
    return () => setWorkPackageToolsSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setWorkPackageToolsSearchInputAction(newVal)}
      placeholder="Search package tools by name"
      onClear={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}
