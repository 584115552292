import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import Row from '../../presentational/Row';
import { TouchableOpacity, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

export default function ToolInformationModal({ ...props }) {
  const {
    tools: { input, toolInformationModalVisible },

    toolStatus: { searchResults },
  } = useStoreState((state) => state);

  const {
    setToolInformationModalVisibleAction,
    getInspectionQuestionsThunk,
    setShowInspectionQuestionsModalAction,
  } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      onClose={() => {
        setToolInformationModalVisibleAction(false);
      }}
      isVisible={toolInformationModalVisible}
      title='Tool Information'
      style={{ width: 1200 }}
      width='50%'
      height='50%'
    >
      <Row>
        <Col xs={12} md={4}>
          <Col>
            <Input
              placeholder='Type in Part No.'
              label='Part No.'
              value={input.partNumber}
              onChangeText={async (partNumber) =>
                setToolsInputAction({ partNumber })
              }
              editable={false}
              required
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Nomenclature'
              label='Nomenclature'
              value={input.nomenclature}
              onChangeText={(nomenclature) =>
                setToolsInputAction({ nomenclature })
              }
              editable={false}
              required
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Serial No.'
              label='Serial No.'
              value={input.serialNumber}
              onChangeText={(serialNumber) =>
                setToolsInputAction({ serialNumber })
              }
              editable={false}
              required
            />
          </Col>

          <Col>
            <Input
              placeholder='Type in Phase'
              label='Phase'
              value={input.phase}
              onChangeText={(phase) => setToolsInputAction({ phase })}
              editable={false}
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in MFG Revision'
              label='MFG Revision'
              value={input.currentRevision}
              onChangeText={(currentRevision) =>
                setToolsInputAction({ currentRevision })
              }
              editable={false}
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in F and F Code'
              label='F and F Code'
              value={input.fandFCode}
              onChangeText={(fandFCode) => setToolsInputAction({ fandFCode })}
              editable={false}
            />
          </Col>
        </Col>

        <Col xs={12} md={4}>
          <Col>
            <Input
              placeholder='Type in Batch Numbers'
              label='Batch Numbers'
              value={input.CFMId}
              onChangeText={(CFMId) => setToolsInputAction({ CFMId })}
              editable={false}
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Barcode Scan ID'
              label='Barcode Scan ID'
              value={input.barcodescanid}
              onChangeText={(barcodescanid) =>
                setToolsInputAction({ barcodescanid })
              }
              editable={false}
              rightIcon={
                <>
                  {Platform.OS == 'ios' ? (
                    <TouchableOpacity
                      onPress={() => {
                        openScanner();
                      }}
                    >
                      <Icon Component={AntDesign} name='barcode' size={20} />
                    </TouchableOpacity>
                  ) : null}
                </>
              }
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Manufacturing Cage Code'
              label='Manufacturing Case Code'
              value={input.manufacturerCageCode}
              onChangeText={(manufacturerCageCode) =>
                setToolsInputAction({ manufacturerCageCode })
              }
              editable={false}
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Qty Required'
              label='Qty Required'
              value={input.qtyRequired}
              onChangeText={(qtyRequired) =>
                setToolsInputAction({ qtyRequired: parseInt(qtyRequired) })
              }
              editable={false}
              number
            />
          </Col>

          <Col>
            <Input
              placeholder='Type in Next Calibration'
              label='Next Calibration'
              value={
                input.nextCalibrationTestDueDate == '1900-01-01T00:00:00.000Z'
                  ? ''
                  : input.nextCalibrationTestDueDate
              }
              onChangeText={(nextCalibrationTestDueDate) =>
                setToolsInputAction({ nextCalibrationTestDueDate })
              }
              rightIcon={<Ionicons name='calendar' size={20} color='#00205b' />}
              editable={false}
            />
          </Col>
          <Col>
            <Input
              placeholder='Type in Next PMI'
              label='Next PMI'
              value={
                input.nextPMIDate == '1900-01-01T00:00:00.000Z'
                  ? ''
                  : input.nextPMIDate
              }
              onChangeText={(nextPMIDate) =>
                setToolsInputAction({ nextPMIDate })
              }
              rightIcon={<Ionicons name='calendar' size={20} color='#00205b' />}
              editable={false}
            />
          </Col>
        </Col>

        <Col xs={12} md={4}>
          <Col>
            <Input
              selectedItem={(item) => item.label}
              rowTextForSelection={(item) => item.label}
              dropdown
              dropdownChoices={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              placeholder='Type in Calibration Required'
              label='Calibration Required'
              value={input.calibrationRequired == false ? 'No' : 'Yes'}
              onSelect={(item) =>
                setToolsInputAction({ calibrationRequired: item.value })
              }
              editable={false}
            />
          </Col>
          <Col>
            <Input
              selectedItem={(item) => item.label}
              rowTextForSelection={(item) => item.label}
              dropdown
              dropdownChoices={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              placeholder='Type in Load Test Required'
              label='Load Test Required'
              value={input.loadTestRequired == false ? 'No' : 'Yes'}
              onSelect={(item) =>
                setToolsInputAction({ loadTestRequired: item.value })
              }
              editable={false}
            />
          </Col>
          <Col>
            <Input
              editable={false}
              placeholder='Type in Field Tool Status'
              label='Field Tool Status'
              value={
                input.statusDescription
                // input.toolStatusCode}
              }
              onSelect={(toolStatus) =>
                setToolsInputAction({
                  toolStatus: toolStatus.statusCode,
                  toolStatusCode: toolStatus.statusCode,
                })
              }
              selectedItem={(item) => item.statusDescription}
              rowTextForSelection={(item) => item.statusDescription}
              dropdown
              dropdownChoices={searchResults}
            />
          </Col>
          <Col>
            <Input
              editable={false}
              placeholder='Type in Checkouts'
              label='Checkouts'
              value={input.checkOuts}
              onChangeText={(checkOuts) => setToolsInputAction({ checkOuts })}
              number
            />
          </Col>
          <Col>
            <Input
              editable={false}
              placeholder='Type in OEM Status'
              label='OEM Status'
              value={input.status}
              onChangeText={(status) =>
                setToolsInputAction({
                  toolStatus: status.statusCode,
                })
              }
            />
          </Col>
          <Col>
            <Input
              editable={false}
              placeholder='Type in Rhinestahl Status'
              label='Rhinestahl Status'
              value={input.toolStatus}
              onChangeText={(toolStatus) => setToolsInputAction({ toolStatus })}
            />
          </Col>
        </Col>
      </Row>
      <Row
        style={{
          justifyContent: 'flex-end',
          borderTopWidth: 1,
          borderColor: '#ccc',
        }}
      >
        <Col xs={12} sm={3}>
          <Button
            title='Perform Inspection'
            fullWidth
            disabled={!input.inspectionTypes}
            onPress={() => {
              getInspectionQuestionsThunk(input.inspectionTypes);
              setShowInspectionQuestionsModalAction(true);
            }}
            color={'#00205b'}
          />
        </Col>
      </Row>
    </CustomModal>
  );
}
