const defaultInputState = {
	id: null,
	name: "",
	description: "",
	module: "",
	engineManualRef: "",
	status: "",
	statusDescription: "",
	engine: "",
	engineModel: "",
	activeFlag: "Y",

	// workOrder: '',
	// engineSerialNumber: '',
};

const duplicateInputState = {
	currentWPid: null,
	name: "",
	description: "",
};

const workPackageInputState = {
	updateflag: false,
	WPid: null,
	WPreservationstartDate: null,
	WPreservationendDate: null,
	WPreservationworkOrder: "",
	WPreservationengineNumber: "",
	WPreservationNotes: "",
	WPcancelreservation: false,
};

const state = {
	workPackage: {
		searchResults: [],
		searchInput: "",
		isEditable: false,
		input: defaultInputState,
		duplicateInput: duplicateInputState,
		filter: false,
		checkoutPackageList: "",
		checkinPackageList: "",
		workOrderSerialModal: false,
		checkoutInput: {
			WPid: "",
			WPStatusCode: "CO",
			WPStatusDescription: "Checked Out",
			workOrder: "",
			engineSerialNumber: "",
		},
		workPackageReservationInput: workPackageInputState,
		wpAreYouSureModalVisible: false,

		precheckWorkPackageTools: [],
		precheckWPToolModal: false,
		reservationModal: false,
		preCheckInfoWPModal: false,
		selectedWPPreCheck: {},
		copyWorkPackageToTenantModalVisible: false,

		copyWPToTenantInput: {
			WPid: null,
			fromtenantId: null,
			totenantId: null,
			name: "",
			description: "",
		},

		cancelWorkPackageReservationInput: {
			WPId: null,
			tenantId: null,
			reservationstartdate: "",
			reservationenddate: "",
		},
		copyWPToTenantToolListModalVisible: false,
		copyWPToTenantToolList: [],
		workPackageReservationList: [],
		cancelWorkPackageReservationModalVisible: false,
	},
};

export {
	state as default,
	defaultInputState,
	duplicateInputState,
	workPackageInputState,
};
