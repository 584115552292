import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setLocationSearchResultsAction: action((state, searchResults) => {
    const locationMap = searchResults.reduce((map, item) => {
      if (!map[item.id]) {
        map[item.id] = item;
      }
      return map;
    }, {});
    state.location.locationMap = locationMap;
    state.location.searchResults = searchResults;
  }),
  setLocationSearchInputAction: action((state, searchInput) => {
    state.location.searchInput = searchInput;
  }),
  setIsEditableLocationAction: action((state, isEditable) => {
    state.location.isEditable = isEditable;
  }),
  setLocationInputAction: action((state, input) => {
    state.location.input = {
      ...state.location.input,
      ...input,
    };
  }),
  resetLocationInputAction: action((state, input) => {
    state.location.input = defaultInputState;
  }),
};

export default actions;
