import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { vhToDp, vwToDp } from '../../utilities/general';
import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;
export default function ViewBrokenToolReportPage({
  route,
  navigation,
  ...props
}) {
  const {
    setIsEditableBrokenToolsAction,
    resetDocumentAction,
    getBrokenToolReportThunk,
  } = useStoreActions((actions) => actions);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      resetDocumentAction();
      getBrokenToolReportThunk();
      setIsEditableBrokenToolsAction(false);
    }
  }, [isFocused]);
  return (
    <Page.Protected {...props}>
      <Container>
        <Form.BrokenTool
          uploadButton={false}
          navigation={navigation}
          buttons={[
            <Button.CancelBrokenTool navigation={navigation}>
              Cancel
            </Button.CancelBrokenTool>,
          ]}
        />
      </Container>
    </Page.Protected>
  );
}
