import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import { Camera } from 'expo-camera';
import { vhToDp } from '../../../utilities/general';

export default function BarcodeScannerModal() {
  const { scannerOpen } = useStoreState((state) => state.tools);
  const { setScannerOpenAction, setToolsInputAction, setLoadingAction } =
    useStoreActions((actions) => actions);

  const handleBarCodeScanned = ({ type, data }) => {
    setLoadingAction(true);
    setToolsInputAction({ barcodescanid: data });
    setScannerOpenAction(false);
    setLoadingAction(false);
  };

  return (
    <CustomModal
      style={{ width: 800, height: vhToDp(40) }}
      width="50%"
      height="50%"
      title="Scan Barcode"
      isVisible={scannerOpen}
      onClose={() => setScannerOpenAction(false)}
    >
      <Camera
        style={{ width: 800, height: vhToDp(40) }}
        type={Camera.Constants.Type.back}
        onBarCodeScanned={scannerOpen ? handleBarCodeScanned : undefined}
      ></Camera>
    </CustomModal>
  );
}
