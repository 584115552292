import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function EditDropdownButton({ children = 'Edit', ...props }) {
  const { isEditable } = useStoreState((state) => state.dropdown);

  const { setIsEditableDropdownAction } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() => setIsEditableDropdownAction(true)}
      disabled={isEditable}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
