const defaultInputState = {
  id: null,
  type: 1,
  name: '',
  description: '',
  tenantlocationid: null,
};

const state = {
  storageLocation: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
  },
};

export { state as default, defaultInputState };
