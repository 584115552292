import { thunk } from 'easy-peasy';
import uuid from 'react-native-uuid';
import { delay } from '../../utilities/general';

const thunks = {
  setAlertThunk: thunk(async (actions, alert) => {
    const id = uuid.v4();
    actions.addAlertAction({ ...alert, id });
    actions.saveAlertAction({ ...alert, id });
    await delay(5000);
    actions.removeAlertAction(id);
  }),
};

export default thunks;
