import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import { Platform, ScrollView } from 'react-native';
import CollapsibleList from '../../presentational/CollapsableList';

export default function InspectionHistoryModal() {
  const {
    tools: { showInspectionHistoryModal, inspectionHistory },
    inspectionItems: { searchResults },
  } = useStoreState((state) => state);

  const { setShowInspectionHistoryModal } = useStoreActions(
    (actions) => actions
  );
  return (
    <CustomModal
      isVisible={showInspectionHistoryModal}
      title="Inspection History"
      onClose={() => {
        setShowInspectionHistoryModal(false);
      }}
      style={{
        height: Platform.OS == 'web' ? '50vh' : '50%',
        width: Platform.OS == 'web' ? '70vh' : '70%',
      }}
    >
      <ScrollView
        style={{
          height: Platform.OS == 'web' ? '40vh' : '40%',
        }}
      >
        <CollapsibleList
          inspectionHistory={inspectionHistory}
          searchResults={searchResults}
        />
      </ScrollView>
    </CustomModal>
  );
}
