import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import Card from '../../presentational/Card';

export default function AddDuplicateWorkPackageForm({ buttons, ...props }) {
  const {
    workPackage: { duplicateInput },
  } = useStoreState((state) => state);

  const { setDuplicateWorkPackageInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Card>
      <Row {...props}>
        <Col>
          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in Name"
                label="Name"
                value={duplicateInput.name}
                onChangeText={(name) =>
                  setDuplicateWorkPackageInputAction({ name })
                }
                required
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in Description"
                label="Description"
                value={duplicateInput.description}
                onChangeText={(description) =>
                  setDuplicateWorkPackageInputAction({ description })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={6} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
