import Button from '../../presentational/Button';

function useOnPress(navigation) {
  return () => {
    navigation.navigate('Add Duplicate Work Package');
  };
}

export default function DuplicateWorkPackageButton({
  navigation,
  children = 'Duplicate Work Package',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props} color={'#00205B'}>
      {children}
    </Button>
  );
}
