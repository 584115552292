import { useStoreActions, useStoreState } from "easy-peasy";
import Page from "../containers/Page";
import { useEffect } from "react";
import SearchBar from "../containers/SearchBar";
import List from "../containers/List";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import Card from "../presentational/Card";
import Gap from "../presentational/Gap";
import Row from "../presentational/Row";
import Col from "../presentational/Col";

import { vhToDp, vwToDp } from "../../utilities/general";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
	align-items: center;
`;

export default function RolesPage({ navigation, ...props }) {
	const isFocused = useIsFocused();

	const { getRolesThunk } = useStoreActions((actions) => actions);

	useEffect(() => {
		isFocused && getRolesThunk();
	}, [isFocused]);

	return (
		<Page.Protected {...props}>
			<Container>
				<SRow>
					<Col>
						<SearchBar.Roles />
					</Col>
				</SRow>

				<Gap />

				<Card
					toolTipText={"How to use the Roles Screen"}
					toolUrl={"https://www.screencast.com/t/fCzTSqNkKZ"}
				>
					<List.Roles navigation={navigation} />
				</Card>
			</Container>
		</Page.Protected>
	);
}
