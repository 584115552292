import { action } from 'easy-peasy';
import { MessageStatus } from './state';

const actions = {
  setMessagesSearchResultsAction: action((state, searchResults) => {
    const map = searchResults.reduce((map, item) => {
      if (!map[item.messageID]) {
        map[item.messageID] = item;
      }
      return map;
    }, {});
    state.messages.messageMap = map;
    state.messages.searchResults = Object.values(map);
  }),

  updateSearchResultsAction: action((state, message) => {
    const item = message[0][0];
    state.messages.messageMap[item.messageID] = item;
    state.messages.searchResults = Object.values(state.messages.messageMap);
  }),

  setMessagesSearchInputAction: action((state, searchInput) => {
    state.messages.searchInput = searchInput;
  }),

  setShowArchivedAction: action((state, archive) => {
    state.messages.showArchived = !state.messages.showArchived;
  }),

  setCommentsAction: action((state, comments) => {
    const map = comments.reduce((map, item) => {
      if (!map[item.commentID]) {
        map[item.commentID] = { ...item, isDisabled: true };
      }
      return map;
    }, {});
    state.messages.commentMap = map;
    state.messages.comments = Object.values(map);
  }),

  updateCommentAction: action((state, comment) => {
    state.messages.commentMap[comment.commentID] = { ...comment };
    state.messages.comments = Object.values(state.messages.commentMap);
  }),

  setMessageLoadingAction: action((state, isLoading) => {
    state.messages.loading = isLoading;
  }),

  setSelectedMessageAction: action((state, message) => {
    if (message) {
      state.messages.selectedId = message.messageID;
      state.messages.selectedMessage = { ...message };
    } else {
      state.messages.selectedId = null;
      state.messages.selectedMessage = null;
    }
  }),

  setUnreadMessageCount: action((state, messages) => {
    state.messages.unreadCount = messages.filter(
      (message) => message.messagestatusID === MessageStatus.Unread
    ).length;
  }),
};

export default actions;
