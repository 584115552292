import Text from '../Text';
import styled from 'styled-components/native';
import { Platform } from 'react-native';

const T = styled.View`
  display: table-cell;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: ${Platform.OS === 'web' ? '1.25rem' : '20px'};
  padding: ${Platform.OS === 'web' ? '1rem' : '10px'};

  border: ${(props) => (props.border ? '1px solid #dee2e6' : '0px')};
  width: 20%;
`;

const TouchableOpacityWrapper = styled.View`
  align-items: center;
  justify-content: center;
`;

const CellText = styled(Text)`
  font-size: ${Platform.OS === 'web' ? '1.25rem' : '20px'};
  align-self: center;
`;

const Td = ({ onPress, children, ...props }) => {
  return (
    <T border={props.border} {...props} style={{ width: props.width || '20%' }}>
      <TouchableOpacityWrapper>
        <CellText
          style={{
            color: props.color || '#00205b',
            fontFamily: props.fontFamily || 'Barlow_400Regular',
          }}
        >
          {children}
        </CellText>
      </TouchableOpacityWrapper>
    </T>
  );
};

export default Td;
