import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function SaveEngineFamilyButton({
  navigation,
  children = 'Save',
  message,
  ...props
}) {
  const { upsertEnginesThunk } = useStoreActions((actions) => actions);

  return (
    <Button onPress={() => upsertEnginesThunk()} fullWidth {...props}>
      {children}
    </Button>
  );
}
