import List from '../../presentational/List';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FontAwesome5 } from '@expo/vector-icons';
import TouchableIcon from '../../presentational/TouchableIcon';
import { useEffect, useState } from 'react';

export default function InspectionsList({ onRemove, disabled }) {
  const [listItems, setListItems] = useState([]);
  const {
    inspections: { list },
    inspectionTypes: { dropdownOptions },
  } = useStoreState((state) => state);
  const {
    setInspectionsShowModalAction,
    setInspectionsInputAction,
    setInspectionsEditAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    let data = list.map((item) => {
      const typeValue = dropdownOptions.find(
        (option) => option.id === item.inspectionTypeId
      );
      return {
        ...item,
        id: item.id,
        inspectionName: typeValue.item,
        inspectionRequiredName: item.inspectionRequired === 'Y' ? 'Yes' : 'No',
      };
    });

    setListItems(data);
  }, [list, dropdownOptions]);

  return (
    <List
      tableHead={[
        'Type',
        'Checkouts',
        'Days',
        'Required',
        'Last Inspection',
        'Edit',
        'Remove',
      ]}
      tableRows={listItems.map((inspection) => [
        inspection.inspectionName,
        inspection.numofcheckoutsThreshold,
        inspection.numofdaysThreshold,
        inspection.inspectionRequiredName,
        inspection.lastinspectionDate,
        <TouchableIcon
          Component={FontAwesome5}
          name='edit'
          size={20}
          disabled={disabled}
          onPress={() => {
            setInspectionsInputAction({ ...inspection });
            setInspectionsEditAction(true);
            setInspectionsShowModalAction(true);
          }}
        />,
        <TouchableIcon
          Component={FontAwesome5}
          name='trash'
          size={20}
          disabled={disabled}
          onPress={() => {
            setInspectionsInputAction({ ...inspection });
            onRemove(inspection.inspectionTypeId);
          }}
        />,
      ])}
    />
  );
}
