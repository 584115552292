import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import Button from '../Button';
import Text from '../../presentational/Text';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';

export default function AreYouSureModal({ confirmationPhrase, onSubmit }) {
  const { status, document } = useStoreState((state) => state);

  const { setAreYouSureModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  const handleOnSubmit = () => {
    onSubmit();
    setAreYouSureModalVisibleAction(false);
  };

  return (
    <CustomModal
      style={{ width: 500 }}
      width="50%"
      height="50%"
      title="Confirmation"
      onClose={() => setAreYouSureModalVisibleAction(false)}
      isVisible={status.areYouSureModalVisible}
    >
      <Text style={{ fontSize: 20, fontWeight: 'bold', alignSelf: 'center' }}>
        {confirmationPhrase}
      </Text>
      <Row>
        <Col xs={12} sm={6}>
          <Button
            fullWidth
            style={{ marginTop: 20 }}
            onPress={() => {
              setAreYouSureModalVisibleAction(false);
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            color="#ab2328"
            fullWidth
            style={{ marginTop: 20 }}
            onPress={handleOnSubmit}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}
