import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Entypo } from '@expo/vector-icons';
import Input from '../../presentational/Input';
import Col from '../../presentational/Col';
import Icon from '../../presentational/Icon';
import Gap from '../../presentational/Gap';

import Row from '../../presentational/Row';
import { Platform } from 'react-native';

export default function AddInspectionModal({ onSubmit }) {
  const {
    inspections: { input, showModal, isEdit },
    inspectionTypes: { dropdownOptions, map },
  } = useStoreState((state) => state);
  const { setInspectionsShowModalAction, setInspectionsInputAction } =
    useStoreActions((actions) => actions);

  return (
    <CustomModal
      onClose={() => {
        setInspectionsShowModalAction(false);
      }}
      isVisible={showModal}
      title={isEdit ? 'Edit Inspection' : 'Add Inspection'}
      style={{ width: 800 }}
      width='80%'
      height={Platform.OS == 'web' ? '50%' : '64%'}
    >
      <Row>
        <Col>
          <Input
            placeholder='Type in Inspection'
            label='Inspection Type'
            value={input.inspectionName}
            onSelect={(inspection) => {
              setInspectionsInputAction({
                ...input,
                inspectionTypeId: inspection.id,
                inspectionName: inspection.item,
                numofcheckoutsThreshold:
                  map[inspection.id].numofcheckoutsThreshold || '0',
                numofdaysThreshold:
                  map[inspection.id].numofdaysThreshold || '0',
                inspectionRequired:
                  map[inspection.id].inspectionRequired || 'N',
                inspectionRequiredName:
                  (map[inspection.id].inspectionRequired || 'N') === 'Y'
                    ? 'Yes'
                    : 'No',
              });
            }}
            editable
            selectedItem={(item) => item.item}
            rowTextForSelection={(item) => item.item}
            dropdown
            dropdownChoices={dropdownOptions}
            width={Platform.OS == 'web' ? '95%' : '98%'}
            search={true}
            searchInputStyle={{
              width: '100%',
              alignSelf: 'center',
              backgroundColor: '#F7F7F7',
              border: 0,

              boxShadow: '0px 0px 10px grey',
              borderColor: '#F7F7F7',
            }}
            searchInputTxtStyle={{
              fontFamily: 'Barlow_400Regular',
              fontSize: 16,
            }}
            renderSearchInputRightIcon={() => (
              <Icon Component={Entypo} name='magnifying-glass' />
            )}
            searchInputPlaceholder='Search for Inspection'
          />
        </Col>
        <Col>
          <Input
            placeholder='Number of Checkouts'
            label='Number of Checkouts'
            value={input.numofcheckoutsThreshold}
            onChangeText={(numofcheckoutsThreshold) =>
              setInspectionsInputAction({ ...input, numofcheckoutsThreshold })
            }
            number
          />
        </Col>
        <Col>
          <Input
            placeholder='Number of Days'
            label='Number of Days'
            value={input.numofdaysThreshold}
            onChangeText={(numofdaysThreshold) =>
              setInspectionsInputAction({ ...input, numofdaysThreshold })
            }
            number
          />
        </Col>
        <Col>
          <Input
            placeholder='Inspection Required'
            label='Inspection Required'
            value={input.inspectionRequiredName}
            onSelect={(inspectionRequired) =>
              setInspectionsInputAction({
                ...input,
                inspectionRequired: inspectionRequired.value,
                inspectionRequiredName: inspectionRequired.label,
              })
            }
            editable
            selectedItem={(item) => item.label}
            rowTextForSelection={(item) => item.label}
            dropdown
            dropdownChoices={[
              { label: 'Yes', value: 'Y' },
              { label: 'No', value: 'N' },
            ]}
          />
        </Col>
      </Row>
      <Gap />
      <Button
        fullWidth
        disabled={
          !(
            input.inspectionRequiredName &&
            input.numofdaysThreshold &&
            input.numofcheckoutsThreshold &&
            input.inspectionTypeId
          )
        }
        onPress={async () => {
          onSubmit();
        }}
      >
        {isEdit ? 'Save Inspection' : 'Add Inspection'}
      </Button>
    </CustomModal>
  );
}
