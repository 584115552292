import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import List from '../List';

export default function ScoreCardModal({ ...props }) {
  const {
    readiness: { showScoreCardModal, scorecardModalType, currentScorecards },
  } = useStoreState((state) => state);

  const { setScoreCardModalAction, setScorecardModalTypeAction } =
    useStoreActions((actions) => actions);
  return (
    <CustomModal
      onClose={() => {
        setScorecardModalTypeAction('');
        setScoreCardModalAction(false);
      }}
      isVisible={showScoreCardModal}
      title='Score Card'
      style={{ width: 1200 }}
      width='50%'
      height='50%'
    >
      <List.ScoreCard
        data={currentScorecards[scorecardModalType]?.categories}
      ></List.ScoreCard>
    </CustomModal>
  );
}
