import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function useOnPress(navigation) {
  const { setIsEditableUserAction } = useStoreActions((actions) => actions);

  return () => {
    setIsEditableUserAction(false);
    navigation.navigate("Users");
  };
}

export default function GoToUsersButton({
  navigation,
  children = "Users",
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}
