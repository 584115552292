import { useStoreActions, useStoreState } from 'easy-peasy';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import Card from '../../presentational/Card';

export default function OEMStatusForm({ buttons, ...props }) {
  const {
    tools: { oemInput, oemIsEditable },
  } = useStoreState((state) => state);

  const { setOEMInputAction } = useStoreActions((actions) => actions);

  return (
    <Card>
      <Row {...props}>
        <Col>
          <Row>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in OEM Status Code"
                label="OEM Status Code"
                value={oemInput.OEMstatusCode}
                onChangeText={(OEMstatusCode) =>
                  setOEMInputAction({ OEMstatusCode })
                }
                editable={oemIsEditable} //}
              />
            </Col>
            <Col xs={12} md={6}>
              <Input
                placeholder="Type in OEM Status Description"
                label="OEM Status Description"
                value={oemInput.OEMstatusDescription}
                onChangeText={(OEMstatusDescription) =>
                  setOEMInputAction({ OEMstatusDescription })
                }
                editable={oemIsEditable}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Card>
  );
}
