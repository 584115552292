import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Text from './Text';
import Switch from './Switch';
import { remToDp } from '../../utilities/general';

const DoubleSidedSwitch = ({ ...props }) => {
  const [switchValue, setSwitchValue] = useState(false);

  const toggleSwitch = () => {
    setSwitchValue((prevValue) => !prevValue);
  };

  return (
    <View style={styles.container}>
      <Text style={{ marginRight: '10px', fontSize: `${remToDp(1.1)}px` }}>
        {props.leftLabel}
      </Text>
      <Switch {...props} />
      <Text style={{ marginLeft: '10px', fontSize: `${remToDp(1.1)}px` }}>
        {props.rightLabel}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  labelLeft: {
    marginRight: 10,
  },
  labelRight: {
    marginLeft: 10,
  },
  switch: {},
});

export default DoubleSidedSwitch;
