import Page from "../containers/Page";
import Form from "../containers/Form";
import styled from "styled-components/native";
import Button from "../containers/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import { useEffect } from "react";

import { vhToDp, vwToDp } from "../../utilities/general";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditLocationsPage({ route, navigation, ...props }) {
	const isFocused = useIsFocused();

	const { isEditable } = useStoreState((state) => state.location);
	const { setIsEditableLocationAction } = useStoreActions((actions) => actions);

	useEffect(() => {
		if (isFocused) {
			setIsEditableLocationAction(false);
		}
	}, [isFocused]);

	return (
		<Page.Protected {...props}>
			<Container>
				<Form.Location
					navigation={navigation}
					buttons={[
						<Button.EditLocation navigation={navigation} />,
						<Button.GoToLocations navigation={navigation}>
							Cancel
						</Button.GoToLocations>,
						<Button.SaveLocation
							navigation={navigation}
							message="Added Location"
							disabled={!isEditable}
						/>,
					]}
				/>
			</Container>
		</Page.Protected>
	);
}
