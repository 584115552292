import { useStoreActions, useStoreState } from 'easy-peasy';
import Button from '../../presentational/Button';
import CustomModal from '../../presentational/Modal';
import { Ionicons } from '@expo/vector-icons';
import { Platform, TouchableOpacity, View } from 'react-native';
import { FlatList } from 'react-native';
import Text from '../../presentational/Text';
import { Divider } from '@rneui/themed';
import Gap from '../../presentational/Gap';
import { vhToDp } from '../../../utilities/general';
import Input from '../../presentational/Input';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import { useState } from 'react';

export default function ToolCheckinModal({ navigation }) {
  const {
    auth: { user },
    tools: {
      searchResults,
      myToolFilter,
      toolCheckInModal,
      checkinToolList,
      validCheckInToolList,
      toolPreCheck,
      checkInComment,
      checkoutMap,
    },
    workPackageTools,
  } = useStoreState((state) => state);

  const {
    getWorkPackageThunk,
    setToolCheckInModalAction,
    checkinWorkPackageThunk,
    checkinToolThunk,
    setValidCheckInToolListAction,
    setPreCheckToolModalAction,
    setSelectedPrecheckAction,
    setCheckInCommentAction,
    upsertToolUsageThunk,
    setCheckoutMapAction,
    filterToolsThunk,
  } = useStoreActions((actions) => actions);

  const [invalid, setInvalid] = useState(false);

  return (
    <CustomModal
      style={{ width: 800 }}
      width='50%'
      height='50%'
      isVisible={toolCheckInModal}
      title={!myToolFilter ? 'Check-In Tool' : 'Check-In Work Package'}
      onClose={() => {
        setCheckoutMapAction({});
        setToolCheckInModalAction(false);
      }}
    >
      <FlatList
        contentContainerStyle={{
          maxHeight: vhToDp(50),
        }}
        ListHeaderComponent={
          <Row>
            <Col xs={5} sm={5}>
              <Text style={{ fontSize: 17 }}>Part Number</Text>
            </Col>
            <Col xs={3} sm={3}>
              <Text style={{ fontSize: 17 }}>Location</Text>
            </Col>
            <Col xs={3} sm={3}>
              <Text style={{ fontSize: 17 }}>Bar Code</Text>
            </Col>
            <Col xs={1} sm={1}></Col>
          </Row>
        }
        data={
          !myToolFilter
            ? searchResults.filter(
                (obj) =>
                  checkinToolList &&
                  checkinToolList.length &&
                  checkinToolList.split(',').some((itemId) => itemId === obj.id)
              )
            : workPackageTools.searchResults.map((obj) => ({
                ...obj,
                partNumber: obj.toolpartNumber,
              }))
        }
        renderItem={({ item }) => {
          if (
            item.NotAllToolsAvailableFlag == true ||
            item.inspectionRequired == 'Y'
          ) {
            setInvalid(true);
          }
          return (
            <TouchableOpacity>
              <Row style={{ alignItems: 'center' }}>
                <Col xs={5} sm={5}>
                  <Text style={{ fontSize: 17, color: '#ab2328' }}>
                    {!myToolFilter ? item.partNumber : item.toolpartNumber} (
                    {item.serialNumber})
                  </Text>
                </Col>
                <Col xs={3} sm={3}>
                  <Text style={{ fontSize: 17 }}>{item.storageLocation}</Text>
                </Col>
                <Col xs={3} sm={3}>
                  <Text style={{ fontSize: 17 }}>{item.barcodescanid}</Text>
                </Col>
                <Col xs={1} sm={1}>
                  {toolPreCheck &&
                  toolPreCheck[0] &&
                  toolPreCheck[0].inspectionJSON &&
                  toolPreCheck[0].inspectionJSON.some(
                    (i) => i.id == item.id
                  ) ? (
                    <TouchableOpacity
                      style={{ zIndex: 1000 }}
                      onPress={() => {
                        setSelectedPrecheckAction(
                          toolPreCheck[0] &&
                            toolPreCheck[0].inspectionJSON &&
                            toolPreCheck[0].inspectionJSON.filter(
                              (i) => i.id == item.id
                            )[0]
                        );
                        setPreCheckToolModalAction(true);
                      }}
                    >
                      <Ionicons
                        name='warning'
                        size={24}
                        color='#F6BE00'
                        style={{ padding: 5 }}
                      />
                    </TouchableOpacity>
                  ) : (
                    <Ionicons
                      name='checkmark'
                      size={24}
                      color='green'
                      style={{ padding: 5 }}
                    />
                  )}
                </Col>
              </Row>
              <Divider />
            </TouchableOpacity>
          );
        }}
      />
      <Gap />
      <Row>
        <Col xs={12} sm={12}>
          <Input
            label='Comments'
            value={checkInComment}
            onChangeText={(comment) => setCheckInCommentAction(comment)}
            multiline
            style={{ height: Platform.OS == 'web' ? 150 : 25 }}
          />
        </Col>
      </Row>
      <Text>
        I acknowledge that I am checking in these tools in good working order. I
        understand that it is my responsibility to use them safely and
        correctly. I am aware of the potential hazards associated with their
        operation, and I will take appropriate precautions to prevent any
        accidents or injuries. If there are any issues or malfunctions, I will
        immediately notify the relevant personnel. I agree to return the tools
        in the same condition as I received them and accept full responsibility
        for any damages or loss that may occur during my use. I understand that
        any consequences resulting from my negligence or misuse of the tools are
        my sole liability.
      </Text>
      <Gap />

      <Button
        disabled={invalid}
        fullWidth
        onPress={async () => {
          if (!myToolFilter) {
            await checkinToolThunk({
              navigation,
            });
            await upsertToolUsageThunk({
              isCheckIn: true,
              tools: Object.values(checkoutMap),
              isWP: false,
            });
            setCheckoutMapAction({});
            filterToolsThunk('');
            setToolCheckInModalAction(false);
          } else {
            checkinWorkPackageThunk({
              navigation,
            });
            await upsertToolUsageThunk({
              isCheckIn: true,
              tools: workPackageTools.searchResults,
              isWP: true,
            });
            getWorkPackageThunk({ id: null, tenantId: user.tenantId });
            setToolCheckInModalAction(false);
          }
        }}
      >
        {!myToolFilter ? 'Check-In Tool (s)' : 'Check-In Work Package(s)'}
      </Button>
    </CustomModal>
  );
}
