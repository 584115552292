import Page from '../containers/Page';
import Form from '../containers/Form';
import styled from 'styled-components/native';
import Button from '../containers/Button';
import { useStoreActions } from 'easy-peasy';
import { useIsFocused } from '@react-navigation/native';
import { useEffect } from 'react';

import { vhToDp, vwToDp } from '../../utilities/general';
import CustomModal from '../presentational/Modal';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function UpsertEngineFamilyPage({
  route,
  navigation,
  ...props
}) {
  const isFocused = useIsFocused();

  const { setIsEditableStorageLocationAction, resetLocationInputAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      setIsEditableStorageLocationAction(false);
      resetLocationInputAction();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <CustomModal.UpsertVariant />
        <Form.EngineFamily navigation={navigation} />
      </Container>
    </Page.Protected>
  );
}
