import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';

function useOnPress(navigation) {
  const { downloadDocumentThunk } = useStoreActions((actions) => actions);

  return () => {
    downloadDocumentThunk({ id: 11, tenantId: 10000 });
  };
}

export default function GetToolSampleButton({
  navigation,
  children = 'Download Sample',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}
