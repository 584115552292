import { axios } from ".";

const authAxios = axios.create({
	// baseURL: `https://tmpbackenduat.azurewebsites.net/auth`,
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/auth`,
});

function getAuthUserAPICall() {
	return authAxios({ method: "get", url: "/user/get" });
}

function loginAPICall(data) {
	return authAxios({ method: "post", url: "/login", data });
}

function refreshTokenAPICall() {
	return authAxios({ method: "post", url: "/token/refresh" });
}

export { authAxios, loginAPICall, getAuthUserAPICall, refreshTokenAPICall };
